import React, {useCallback, useEffect, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import ApiService from "../services/ApiService";
import '../assets/css/mediaPrint/print.css';
import {
    Box,
    Button,
    ButtonGroup,
    CircularProgress,
    ClickAwayListener,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Grid,
    Grow,
    ListItemIcon,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Stack,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableFooter,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {AgGridReact} from "ag-grid-react";
import {agGridItalianTranslation} from "../configuration/agGridItalianTranslation";
import 'ag-grid-enterprise';
import {styled} from "@mui/material/styles";
import BasicDialog from "../components/BasicDialog";
import SnackBarAlert from "../components/SnackBarAlert";
import {label, originalStatus, routes, warningText} from "../utils/ReusableText";
import {handleClose} from "../utils/ReusableFunction";
import {dataOrderDetails, dataReturn} from "../utils/agGridDefs";

const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        color: 'rgba(0,0,0,.54)',
        fontSize: '10px',
        paddingBottom: '8px'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: '12px',
        fontWeight: '400',
        paddingTop: '5px',
        paddingBottom: '5px',
    },
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: 'rgba(0,0,0,.01)',
    },
}));

export default function OrderDetails() {
    const navigate = useNavigate();
    const path = useLocation();
    const id = path.pathname.split('/')[2];
    const [order, setOrder] = useState([]);
    const [commentReturn, setCommentReturn] = useState('');
    const [inProgress, setInProgress] = useState(true);
    const [loading, setLoading] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [openDialogAbortOrder, setOpenDialogAbortOrder] = useState(false);
    const [openCommentDialog, setOpenCommentDialog] = useState(false);
    const [emailLoading, setEmailLoading] = useState(false);
    const [errorAlert, setErrorAlert] = useState(false);
    const gridRef = useRef();
    const [openButtonsGroup, setOpenButtonsGroup] = useState(false);
    const anchorRef = useRef(null);


    useEffect(() => {
        setLoading(true);

        ApiService.getOrderById(id).then(response => {
            setLoading(false);
            if (response.status !== originalStatus.inProgress) {
                setInProgress(false);
            }
            dataOrderDetails.rowData = response.items;
            if (response.status === originalStatus.processed) {
                dataReturn.rowData = response.items;
            }
            setOrder(response);
        })
    }, [id]);

    const confirmDelete = () => {
        setOpenDialog(true);
    };

    const confirmAbort = () => {
        setOpenDialogAbortOrder(true);
    };

    const openCommentReturn = () => {
        setOpenCommentDialog(true);
    };

    const onBtExport = useCallback(() => {
        gridRef.current.api.exportDataAsExcel({
            processCellCallback: (params) => {
                const colDef = params.column.getColDef()
                if (colDef.valueFormatter) {
                    const valueFormatterParams: ValueFormatterParams = {
                        ...params,
                        data: params.node.data,
                        node: true,
                        colDef: params.column.getColDef()
                    };
                    return colDef.valueFormatter(valueFormatterParams);
                }
                return params.value;
            },
        });
    }, []);

    function deleteOrder() {
        ApiService.deleteOrderByOrderId(id).then(response => {
            navigate('/');
        }).catch((e) => {
            if (e.body?.code === 2) {
                setOpenDialog(false);
                setOpenSnackBar(true);
            } else {
                setErrorAlert(true);
            }
        });
    }

    function processOrder() {
        ApiService.processOrder(id).then(response => {
            if (response) {
                setOrder(response);
                dataReturn.rowData = response.items;
            } else {
                setOpenSnackBar(true);
            }
        }).catch((e) => setErrorAlert(true));
    }

    function abortOrder() {
        ApiService.abortOrder(id).then(response => {
            if (response) {
                navigate('/');
            } else {
                setOpenSnackBar(true);
            }
        }).catch((e) => setErrorAlert(true));
    }

    function cancelOrder() {
        ApiService.cancelOrder(id).then(response => {
            if (response) {
                setOrder(response);
            } else {
                setOpenSnackBar(true);
            }
        }).catch((e) => setErrorAlert(true));
    }

    function returnOrder() {
        setEmailLoading(true);
        const body = {
            orderId: id,
            comment: commentReturn
        }
        ApiService.returnOrder(body).then(response => {
            if (response) {
                setEmailLoading(false);
                setOpenCommentDialog(false);
                setOrder(response);
                dataOrderDetails.rowData = response.items;
                navigate('/');
            } else {
                setOpenSnackBar(true);
            }
        }).catch((e) => setErrorAlert(true));
    }

    function approveOrder() {
        ApiService.approveOrder(id).then(response => {
            if (response) {
                setOrder(response);
            } else {
                setOpenSnackBar(true);
            }
        }).catch((e) => setErrorAlert(true));
    }

    //dropdown button
    const handleToggle = () => {
        setOpenButtonsGroup((prevOpen) => !prevOpen);
    };
    const handleCloseButtons = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpenButtonsGroup(false);
    };

    return (<>

        <Grid container sx={{mb: 4}}>
            <Grid item xs={12}>
                <Button variant="flip" onClick={() => {
                    navigate('/')
                }}>
                    Indietro
                </Button>
            </Grid>
        </Grid>

        <Grid container sx={{marginTop: 2}} justifyContent={'space-between'}>
            <Grid item xs={12} md={6}>
                <Typography variant="h5" component="h2" sx={{fontWeight: '500'}}>Dettaglio Ordine</Typography>
            </Grid>

            <Grid item xs={12} md={6} justifyContent={'flex-end'} className={'print-none'}>
                {order.status !== originalStatus.aborted ?
                    <>
                        <Box component={'div'} sx={{'display': 'flex', 'justifyContent': 'flex-end'}}>
                            <ButtonGroup variant="flip" ref={anchorRef} aria-label="split button"
                                         sx={{mt: {xs: 2, md: 0}}}>
                                {order.status === originalStatus.submitted ?
                                    <Button variant="outlined" sx={{px: {xs: 2, md: 5}}}
                                            onClick={() => approveOrder()}>
                                        Approva
                                    </Button>
                                    :
                                    ''
                                }
                                {inProgress ?
                                    <Button variant="contained" sx={{px: {xs: 2, md: 5}}}
                                            onClick={() => navigate('/' + routes.orderInfo + '/' + id)}>
                                        Modifica
                                    </Button>
                                    :
                                    ''
                                }
                                {order.status === originalStatus.submitted ?
                                    <Button variant="contained" sx={{px: {xs: 2, md: 5}}}
                                            onClick={() => navigate('/' + routes.orderReturn + '/' + id)}>
                                        Modifica
                                    </Button>
                                    :
                                    ''
                                }
                                {
                                    order.status === originalStatus.approved ?
                                        <Button variant="contained" sx={{px: {xs: 2, md: 5}}}
                                                onClick={() => processOrder()}>
                                            Evadi
                                        </Button>
                                        :
                                        ''
                                }
                                {
                                    order.status === originalStatus.approved ?
                                        <Button startIcon={<LocalPrintshopOutlinedIcon/>} onClick={() => window.print()}
                                                variant="contained" sx={{px: {xs: 2, md: 5}}}>
                                            Stampa
                                        </Button>
                                        :
                                        ''
                                }
                                {
                                    order.status === originalStatus.processed ?
                                        <Button variant="contained" sx={{px: {xs: 2, md: 5}}}
                                                disabled={order.items?.length === 0}
                                                onClick={() => navigate('/' + routes.orderReturn + '/' + id)}>
                                            Reso
                                        </Button>
                                        :
                                        ''
                                }
                                <Button
                                    size="small"
                                    aria-controls={openButtonsGroup ? 'split-button-menu' : undefined}
                                    aria-expanded={openButtonsGroup ? 'true' : undefined}
                                    aria-label="select merge strategy"
                                    aria-haspopup="menu"
                                    onClick={handleToggle}
                                    variant="contained"
                                >
                                    <MoreVertIcon/>
                                </Button>
                            </ButtonGroup>
                            <Popper
                                sx={{
                                    zIndex: 1,
                                }}
                                open={openButtonsGroup}
                                anchorEl={anchorRef.current}
                                role={undefined}
                                transition
                                disablePortal
                                placement={"bottom-end"}
                            >
                                {({TransitionProps}) => (
                                    <Grow
                                        {...TransitionProps}
                                    >
                                        <Paper>
                                            <ClickAwayListener onClickAway={handleCloseButtons}>
                                                <MenuList id="split-button-menu" autoFocusItem
                                                          sx={{'minWidth': '100px'}}>
                                                    {order.status === originalStatus.submitted ?
                                                        <MenuItem sx={{'fontSize': '0.875rem', 'minHeight': '38px'}}
                                                                  onClick={() => openCommentReturn()}>
                                                            Rifiuta
                                                        </MenuItem>
                                                        :
                                                        ''
                                                    }
                                                    {inProgress ?
                                                        <MenuItem sx={{'fontSize': '0.875rem', 'minHeight': '38px'}}
                                                                  onClick={() => confirmDelete()}>
                                                            Elimina
                                                        </MenuItem>
                                                        :
                                                        ''
                                                    }
                                                    {
                                                        order.status === originalStatus.approved ?
                                                            <MenuItem sx={{'fontSize': '0.875rem', 'minHeight': '38px'}}
                                                                      onClick={() => cancelOrder()}>
                                                                Storno
                                                            </MenuItem>
                                                            :
                                                            ''
                                                    }
                                                    {order.status === originalStatus.aborted || order.status === originalStatus.inProgress || order.status === originalStatus.processed ?
                                                        ''
                                                        :
                                                        <MenuItem sx={{'fontSize': '0.875rem', 'minHeight': '38px'}}
                                                                  onClick={() => confirmAbort()}>
                                                            Cancella
                                                        </MenuItem>
                                                    }
                                                    {
                                                        order.status === originalStatus.processed ?
                                                            ''
                                                            :
                                                            <Divider/>
                                                    }
                                                    <MenuItem onClick={onBtExport}
                                                              sx={{'fontSize': '0.875rem', 'minHeight': '38px'}}>
                                                        <ListItemIcon>
                                                            <FileDownloadOutlinedIcon fontSize="small"/>
                                                        </ListItemIcon>
                                                        Excel
                                                    </MenuItem>
                                                    <MenuItem onClick={() => window.print()}
                                                              sx={{'fontSize': '0.875rem', 'minHeight': '38px'}}>
                                                        <ListItemIcon>
                                                            <LocalPrintshopOutlinedIcon fontSize="small"/>
                                                        </ListItemIcon>
                                                        Stampa
                                                    </MenuItem>
                                                </MenuList>

                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </Box>
                    </>
                    :
                    <>
                        <Box component={'div'} sx={{'display': 'flex', 'justifyContent': {md: 'flex-end'}}}>
                            <ButtonGroup variant="contained" color={'secondary'} sx={{mt: {xs: 2, md: 0}}}>
                                <Button variant="flip" sx={{px: {xs: 2, md: 5}}} startIcon={<FileDownloadOutlinedIcon/>}
                                        onClick={onBtExport}>
                                    Excel
                                </Button>
                                <Button variant="flip" sx={{px: {xs: 2, md: 5}}}
                                        startIcon={<LocalPrintshopOutlinedIcon/>}
                                        onClick={() => window.print()}>
                                    Stampa
                                </Button>
                            </ButtonGroup>
                        </Box>
                    </>
                }
            </Grid>
        </Grid>

        <Grid container sx={{mt: 3}} columnSpacing={3}>
            <Grid item xs={12} md={7} lg={6} className={'width-detail-print'}>
                <Paper
                    component={'div'}
                    elevation={0}
                    sx={{
                        borderRadius: 0,
                        borderTop: '2px solid #c23052',
                        p: 2,
                        mb: 4
                    }}
                >
                    <Grid container spacing={2} sx={{width: '100%'}} className={'container-detail-print'}>
                        <Grid item xs={12} md={6} className={'column-detail-print'}>
                            <Stack spacing={1.25}>
                                <Typography><Typography component={'span'}
                                                        sx={{color: 'rgba(0,0,0,.7)'}}>Utente: </Typography> {order?.user?.fullName}
                                </Typography>
                                <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Creato
                                    da:</Typography> {order?.createdBy}</Typography>
                                <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Data
                                    creazione:</Typography> {order?.createdAtFormatted}</Typography>
                                <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Data
                                    modifica:</Typography> {order?.updatededAtFormatted}</Typography>
                                <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>ID
                                    Ordine:</Typography> {order?.id}</Typography>
                                <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Stato
                                    ordine:</Typography> {order?.statusLabel}</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6} className={'column-detail-print'}>
                            <Stack spacing={1.25}>
                                <Typography><Typography component={'span'}
                                                        sx={{color: 'rgba(0,0,0,.7)'}}>Funzione:</Typography> {order?.budget?.name}
                                </Typography>
                                <Typography>
                                    <Typography component={'span'}
                                                sx={{color: 'rgba(0,0,0,.7)'}}>Periodo:</Typography> {order?.budget?.schoolYear?.name}
                                </Typography>
                                <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Totale
                                    (CHF):</Typography> {order?.totalFormatted}</Typography>
                                <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Sede
                                    fornitura:</Typography> {order?.deliveryLocation?.name}</Typography>
                                <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Budget attuale
                                    (CHF):</Typography> {!order?.budget?.availableAmountFormatted ? label.noLimitBudget : order?.budget?.availableAmountFormatted}
                                </Typography>
                                <Typography><Typography component={'span'}
                                                        sx={{color: 'rgba(0,0,0,.7)'}}>Note:</Typography> {order?.notes}
                                </Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>

        {loading ?
            ''
            :
            <Grid container sx={{mt: 3}} id={"print-none-grid"}>
                <div style={{width: '100%', height: '100%'}} className="ag-theme-material ag-custom">
                    <div style={{width: '100%', height: '100%'}}>
                        <AgGridReact
                            ref={gridRef}
                            defaultColDef={order.status !== originalStatus.processed ? dataOrderDetails.defaultColDef : dataReturn.defaultColDef}
                            columnDefs={order.status !== originalStatus.processed ? dataOrderDetails.columnDefs : dataReturn.columnDefs}
                            rowData={order.status !== originalStatus.processed ? dataOrderDetails.rowData : dataReturn.rowData}
                            pagination={true}
                            paginationPageSize={25}
                            domLayout={'autoHeight'}
                            localeText={agGridItalianTranslation}
                            animateRows={true}
                        >
                        </AgGridReact>
                    </div>
                </div>
            </Grid>}

        <Grid container sx={{mt: 3}} id={'print-grid'}>
            <Table sx={{minWidth: 700}} aria-label="customized table" size="small">
                <TableHead>
                    <TableRow>
                        {order?.status === originalStatus.processed ?
                            <>
                                <StyledTableCell width='15%'>Codice prodotto</StyledTableCell>
                                <StyledTableCell>Nome prodotto</StyledTableCell>
                                <StyledTableCell
                                    width='20%'>{'Quantità'}</StyledTableCell>
                                <StyledTableCell width='20%'>Quantità iniziale</StyledTableCell>
                                <StyledTableCell width='20%'>Quantità resa</StyledTableCell>
                                <StyledTableCell width='20%'>Costo unitario (CHF)</StyledTableCell>
                            </>
                            :
                            <>
                                <StyledTableCell width='15%'>Codice prodotto</StyledTableCell>
                                <StyledTableCell>Nome prodotto</StyledTableCell>
                                <StyledTableCell
                                    width='20%'>{'Quantità'}</StyledTableCell>
                                <StyledTableCell width='20%'>Costo unitario (CHF)</StyledTableCell>
                            </>
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {order && order.items && order.items.length > 0 ?
                        order.items.map((item) => (
                            <StyledTableRow>
                                {order?.status === originalStatus.processed ?
                                    <>
                                        <StyledTableCell>{item.product.code}</StyledTableCell>
                                        <StyledTableCell>{item.product.name}</StyledTableCell>
                                        <StyledTableCell>{dataReturn.rowData.length > 0 ? item.quantity : item.quantity}</StyledTableCell>
                                        <StyledTableCell>{item.originalQuantity}</StyledTableCell>
                                        <StyledTableCell>{item.returnedQuantity}</StyledTableCell>
                                        <StyledTableCell>{item.priceFormatted}</StyledTableCell>
                                    </>
                                    :
                                    <>
                                        <StyledTableCell>{item.product.code}</StyledTableCell>
                                        <StyledTableCell>{item.product.name}</StyledTableCell>
                                        <StyledTableCell>{dataReturn.rowData.length > 0 ? item.quantity : item.quantity}</StyledTableCell>
                                        <StyledTableCell>{item.priceFormatted}</StyledTableCell>
                                    </>
                                }

                            </StyledTableRow>
                        ))
                        :
                        ''}
                </TableBody>
                <TableFooter>
                    {order?.status === originalStatus.processed ?
                        <>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell sx={{textAlign: 'right', color: '#000000de'}}>Totale:</StyledTableCell>
                            <StyledTableCell sx={{color: '#000000de'}}> {order.totalFormatted} (CHF)</StyledTableCell>
                        </>
                        :
                        <>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell sx={{textAlign: 'right', color: '#000000de'}}>Totale:</StyledTableCell>
                            <StyledTableCell sx={{color: '#000000de'}}> {order.totalFormatted} (CHF)</StyledTableCell>
                        </>
                    }
                </TableFooter>
            </Table>
        </Grid>


        <BasicDialog isOpen={openDialog} handleClose={() => handleClose(setOpenDialog)} deleteFunction={deleteOrder}
                     title={warningText.titleAttention}
                     body={warningText.confirmDelete} closeLabel={warningText.noButton}
                     successLabel={warningText.deleteButton}/>

        <BasicDialog isOpen={openDialogAbortOrder} handleClose={() => handleClose(setOpenDialogAbortOrder)}
                     deleteFunction={abortOrder}
                     title={warningText.titleAttention}
                     body={'Sei sicuro di voler cancellare quest\'ordine? L\'azione non è reversibile.'}
                     closeLabel={warningText.noButton} successLabel={'Sì, cancella'}/>

        <SnackBarAlert isOpen={openSnackBar} handleClose={() => handleClose(setOpenSnackBar)} type={'error'}
                       title={warningText.titleError}
                       duration={10000}
                       body={warningText.somethingWentWrong}/>

        <BasicDialog isOpen={errorAlert} handleClose={() => handleClose(setErrorAlert)} title={warningText.titleError}
                     body={warningText.somethingWentWrong} closeLabel={warningText.okButton} successLabel={undefined}/>

        <Dialog
            open={openCommentDialog}
            onClose={() => handleClose(setOpenCommentDialog)}
            aria-labelledby="openSnackBar-dialog-title"
            aria-describedby="openSnackBar-dialog-description"
            maxWidth='lg'
        >
            <DialogTitle id="openSnackBar-dialog-title">
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <HelpOutlineIcon sx={{mr: 1}}/> Messaggio
                </Box>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>Inserisci il messaggio per l'Utente</DialogContentText>
                <TextField
                    id="comment"
                    label="Messaggio"
                    variant="standard"
                    fullWidth
                    sx={{
                        minWidth: {
                            lg: '640px'
                        }
                    }}
                    onChange={(e) => setCommentReturn(e.target.value)}
                    multiline
                    rows={5}
                    autoFocus
                    margin="dense"
                    required
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose(setOpenCommentDialog)} variant={'outlined'}
                        color={'secondary'}>Annulla</Button>
                <Button disabled={!commentReturn || emailLoading} onClick={() => returnOrder()} variant={'contained'}
                        color={'primary'}
                        endIcon={emailLoading && <CircularProgress size={20}/>}>
                    {emailLoading ? 'Invio in corso...' : 'Invia'}
                </Button>
            </DialogActions>
        </Dialog>
    </>);
}