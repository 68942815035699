import testImage from "../assets/img/placeholder.png";
import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    IconButton,
    Modal,
    Stack,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import ApiService from "../services/ApiService";
import CircleIcon from '@mui/icons-material/Circle';
import SnackBarAlert from "./SnackBarAlert";
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import CloseIcon from '@mui/icons-material/Close';
import {roundCircleStock, warningText} from "../utils/ReusableText";
import {handleClose} from "../utils/ReusableFunction";

export default function ProductCard({product, orderInitial, changeOrderInitial}) {
    const [quantitySelected, setQuantitySelected] = useState(1);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [order, setOrder] = useState(orderInitial);
    const [open, setOpen] = useState(false);
    const [image, setImage] = useState(undefined);
    const handleOpen = () => setOpen(true);
    const handleCloseOpen = () => setOpen(false);

    function handleChange(event) {
        if (Number(event) === 0 || Number(event) === 0) {
            setQuantitySelected(1);
        } else {
            setQuantitySelected(event);
        }
    }

    useEffect(() => {
        if (product.image) {
            const imageSplitted = product.image.split('/');
            const productImage = imageSplitted[imageSplitted.length - 1];

            getImage(productImage).then();
        }
        // eslint-disable-next-line
    }, [])

    async function getImage(productImage) {
        const res = await ApiService.getImage(productImage);
        const imageBlob = await res.blob();
        const imageObjectURL = URL.createObjectURL(imageBlob);
        setImage(imageObjectURL);
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 'calc(100% - 48px)',
        maxWidth: 600,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 2,
    };

    const addToCart = (id) => {
        if (Number(quantitySelected) > 0) {
            if (order?.items?.length > 0) {
                const itemToAdd = order.items.find((item) => item.product.id === id);

                if (itemToAdd) {
                    const body = {
                        id: itemToAdd.id,
                        quantity: itemToAdd.quantity + Number(quantitySelected),
                        orderId: order.id
                    }
                    ApiService.updateOrderItem(body).then(response => {
                        setOrder(response);
                        changeOrderInitial(response);
                        setOpenSnackBar(true);
                        setQuantitySelected(1);
                    });
                } else {
                    const body = {
                        quantity: Number(quantitySelected),
                        productId: id,
                        orderId: order.id
                    }
                    ApiService.addOrderItem(body).then(response => {
                        setOrder(response);
                        changeOrderInitial(response);
                        setOpenSnackBar(true);
                        setQuantitySelected(1);
                    });
                }
            } else {
                const body = {
                    quantity: Number(quantitySelected),
                    productId: id,
                    orderId: order.id
                }
                ApiService.addOrderItem(body).then(response => {
                    setOrder(response);
                    changeOrderInitial(response);
                    setOpenSnackBar(true);
                    setQuantitySelected(1)
                });
            }
        }
    }

    function getDescriptionRoundCircle() {
        let circleColor = 'orange';
        let setTitle = roundCircleStock.orange;

        if (product.stock > product.minimumStockQuantity) {
            setTitle = roundCircleStock.green;
            circleColor = 'green';
        } else if ((product.stock <= 0 && product.minimumStockQuantity >= 0)) {
            setTitle = roundCircleStock.red;
            circleColor = 'red';
        } else {
        }

        return <Tooltip title={setTitle} placement="top">
            {circleColor === 'green' ?
                <CircleIcon sx={{
                    color: (theme) => theme.palette.success.main,
                    width: '16px',
                    height: '16px'
                }}/>
                :
                <CircleIcon sx={{
                    color: (theme) => circleColor === 'red' ? theme.palette.error.main : theme.palette.warning.main,
                    width: '16px',
                    height: '16px'
                }}/>}
        </Tooltip>;
    }

    return (
        <Grid item xs={12} md={4} lg={3}>
            <Card variant="outlined"
                  sx={{
                      borderRadius: 0,
                      display: 'flex',
                      height: '100%',
                      background: '#fff'
                  }}
            >
                <CardContent
                    sx={{
                        p: 1.5,
                        pb: '10px!important',
                        borderColor: 'rgba(0,0,0,.25)',
                        flexGrow: '1',
                        display: 'flex'
                    }}
                >
                    <Grid container columns={6} sx={{width: '100%', ml: 0}}>
                        <Grid item xs={6}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '100%'
                                }}
                            >
                                <Box
                                    component={'div'}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        width: '100%'
                                    }}
                                >
                                    <Typography color="textSecondary"
                                                sx={{fontSize: '0.875rem'}}> Cod. {product.code}</Typography>
                                    <Box component={'div'}>
                                        {getDescriptionRoundCircle()}
                                    </Box>
                                </Box>

                                <Typography sx={{mb: 1.5}}>
                                    {product.name}
                                </Typography>

                                <Box sx={{mt: 'auto'}}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        <Stack direction="row" alignItems="center" spacing={1.5}>
                                            {product.image !== null ?
                                                <Tooltip title="Mostra immagine" placement="top">
                                                    <IconButton color="primary" onClick={handleOpen}>
                                                        <PhotoCamera size={'small'}/>
                                                    </IconButton>
                                                </Tooltip>
                                                :
                                                ''}
                                            <Box>
                                                <Typography color="textSecondary" sx={{fontSize: '0.75rem'}}>
                                                    Descrizione unità
                                                </Typography>
                                                <Typography>
                                                    {product.priceFormatted} (CHF) {product.unitDescription ? '/ ' + product.unitDescription : ''}
                                                </Typography>
                                            </Box>
                                        </Stack>

                                        <Box>
                                            <TextField
                                                type="number"
                                                value={quantitySelected}
                                                id="quantity"
                                                onChange={(e) => handleChange(e.target.value)}
                                                variant={'outlined'}
                                                size={'small'}
                                                sx={{
                                                    width: '70px'
                                                }}
                                                InputProps={{
                                                    inputProps: {min: 1}
                                                }}
                                            >
                                            </TextField>
                                        </Box>
                                    </Box>

                                    <Button variant="outlined" fullWidth sx={{mt: 1.5}}
                                            onClick={() => addToCart(product.id)}>Aggiungi al
                                        carrello</Button>
                                </Box>

                            </Box>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <SnackBarAlert isOpen={openSnackBar} handleClose={() => handleClose(setOpenSnackBar)} type={'success'}
                           title={warningText.titleSuccess}
                           duration={5000}
                           body={' Questo articolo è stato aggiunto correttamente al tuo ordine. Per gestire i tuoi articoli vai al carrello.'}/>

            <Modal
                open={open}
                onClose={handleCloseOpen}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={style}>
                    <Stack direction="row" alignItems="start" justifyContent='space-between'>
                        <Box>
                            <Typography color="textSecondary"
                                        sx={{fontSize: '0.875rem'}}> Cod. {product.code}
                            </Typography>
                            <Typography id="modal-title" variant="p">
                                {product.name}
                            </Typography>
                        </Box>

                        <IconButton color="secondary" onClick={handleCloseOpen}>
                            <CloseIcon size={'small'}/>
                        </IconButton>
                    </Stack>


                    <Box
                        sx={{
                            mt: 1,
                            "& img": {
                                width: "100%",
                                height: '100%',
                                maxHeight: '240px',
                                objectFit: 'contain'
                            }
                        }}
                    >
                        <img
                            src={product.image ? image : testImage}
                            alt={''}/>
                    </Box>
                </Box>
            </Modal>
        </Grid>
    )
}