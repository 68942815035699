import React, {useEffect, useState} from 'react';
import {Button, Checkbox, FormControlLabel, Grid, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import ApiService from "../services/ApiService";
import AgGrid from "./AgGrid";
import {routes} from "../utils/ReusableText";
import {dataLocations} from "../utils/agGridDefs";

export default function Locations() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [enabled, setEnabled] = useState(false);

    useEffect(() => {
        setLoading(true);

        getLocations();
        // eslint-disable-next-line
    }, [enabled])

    function getLocations() {
        ApiService.getLocations(enabled).then(response => {
            setLoading(false);

            dataLocations.rowData = response;
        });
    }


    return (<>
        <Grid container sx={{marginTop: 2}} justifyContent={'space-between'}>
            <Grid item xs='auto'>
                <Typography variant="h5" component="h2" sx={{fontWeight: '500'}}>Sedi</Typography>
            </Grid>
            <Grid item xs='auto'>
                <Button variant="contained" color="primary"
                        fullWidth
                        onClick={() => {
                            navigate('/' + routes.editLocation)
                        }}>
                    Crea sede
                </Button>
            </Grid>

            <Grid container>
                <Grid item xs="auto">
                    <FormControlLabel control={<Checkbox checked={enabled} onChange={() => {
                        setEnabled(!enabled);
                    }}/>} label="Archiviati"/>
                </Grid>
            </Grid>
        </Grid>

        <AgGrid children={loading ? [] : dataLocations} redirect={"/" + routes.locationDetails}/>
    </>);
}