import React, {useEffect, useState, useRef} from "react";
import {
    Box,
    Button, ButtonGroup, ClickAwayListener,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid, Grow, MenuItem, MenuList,
    Paper, Popper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import ApiService from "../services/ApiService";
import BasicDialog from "../components/BasicDialog";
import SnackBarAlert from "../components/SnackBarAlert";
import {label, routes, warningText} from "../utils/ReusableText";
import {handleClose} from "../utils/ReusableFunction";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";

export default function PeriodDetails() {
    const navigate = useNavigate();
    const path = useLocation();
    const id = path.pathname.split('/')[2];
    const [period, setPeriod] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [errorAlert, setErrorAlert] = useState(false);
    const [archiveConfirmModal, setArchiveConfirmModal] = useState(false);
    const [budgetsFromPeriod, setBudgetsFromPeriod] = useState([]);
    const [openButtonsGroup, setOpenButtonsGroup] = useState(false);
    const anchorRef = useRef(null);

    useEffect(() => {
        setLoading(true);

        if (id) {
            ApiService.getPeriodById(id).then(response => {
                setLoading(false);
                setPeriod(response);
            })
        }
    }, [id]);

    const confirmOpen = () => {
        setOpenDialog(true);
    };

    function archivePeriod() {
        ApiService.archiveBudgetsByPeriodId(period.id, period.enabled === true).then(response => {
            navigate('/' + routes.periodDetails + '/' + id)
        }).catch((e) => {
            setErrorAlert(true);
        });
    }

    function deletePeriod() {
        ApiService.deletePeriodById(id).then(response => {
            navigate('/' + routes.periods);
        }).catch((e) => {
            if (e.body?.code === 2) {
                setOpenDialog(false);
                setOpenSnackBar(true);
            } else {
                setErrorAlert(true);
            }
        });
    }

    function getBudgetsFromPeriodAlert() {
        if (period.enabled) {
            ApiService.getBudgetsByPeriodId(id).then(response => {
                setLoading(false);
                if (response.budgets.length > 0) {
                    setArchiveConfirmModal(true);
                    setBudgetsFromPeriod(response.budgets);
                } else {
                    archivePeriod();
                }
            })
        } else {
            archivePeriod();
        }
    }


    const handleCloseBackdrop = (event, reason) => {
        if (reason && reason === "backdropClick") {
            return;
        }
        setArchiveConfirmModal();
    };

    //dropdown button
    const handleToggle = () => {
        setOpenButtonsGroup((prevOpen) => !prevOpen);
    };
    const handleCloseButtons = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpenButtonsGroup(false);
    };

    return (<>

        <Grid container sx={{mb: 4}}>
            <Grid item xs={12}>
                <Button variant="flip"
                        onClick={() => {
                            navigate('/' + routes.periods)
                        }}>
                    Indietro
                </Button>
            </Grid>
        </Grid>

        <Grid container sx={{marginTop: 2}}>
            <Grid item xs={12}  md={6}>
                <Typography variant="h5" component="h2" sx={{fontWeight: '500'}}>Dettaglio Periodo</Typography>
            </Grid>

            <Grid item xs={12} md={6} justifyContent={'flex-end'}>
                <Box component={'div'} sx={{'display': 'flex', 'justifyContent':'flex-end'}}>
                    <ButtonGroup variant="outlined" color={'primary'} ref={anchorRef} aria-label="split button" sx={{mt:{xs:2, md:0}}}>
                        <Button variant="flip" sx={{px: {xs:2, md:5}}}
                                onClick={() => navigate(('/' + routes.editPeriod + '/' + id))}
                                disabled={!period?.enabled}>
                            Modifica
                        </Button>

                        <Button
                            size="small"
                            aria-controls={openButtonsGroup ? 'split-button-menu' : undefined}
                            aria-expanded={openButtonsGroup ? 'true' : undefined}
                            aria-label="select merge strategy"
                            aria-haspopup="menu"
                            onClick={handleToggle}
                            variant="flip"
                        >
                            <MoreVertIcon />
                        </Button>
                    </ButtonGroup>
                    <Popper
                        sx={{
                            zIndex: 1,
                        }}
                        open={openButtonsGroup}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                        placement={"bottom-end"}
                    >
                        {({ TransitionProps }) => (
                            <Grow
                                {...TransitionProps}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleCloseButtons}>
                                        <MenuList id="split-button-menu" autoFocusItem sx={{'minWidth': '100px'}}>
                                            <MenuItem onClick={() => getBudgetsFromPeriodAlert()} sx={{'fontSize': '0.875rem', 'minHeight': '38px'}}>
                                                {period?.enabled ? label.archive : label.restore}
                                            </MenuItem>
                                            <MenuItem onClick={() => confirmOpen()} sx={{'fontSize': '0.875rem', 'minHeight': '38px'}}
                                                    disabled={!period?.enabled}>
                                                Elimina
                                            </MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </Box>
            </Grid>
        </Grid>

        <Grid container sx={{mt: 3}} columnSpacing={3}>
            <Grid item xs={12} md={6} lg={4}>
                <Paper
                    component={'div'}
                    elevation={0}
                    sx={{
                        borderRadius: 0,
                        borderTop: '2px solid #c23052',
                        p: 2,
                        mb: 4
                    }}
                >
                    <Stack spacing={1.25}>
                        {!loading ?
                            <>
                                <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Nome
                                    periodo: </Typography> {period?.name}</Typography>
                                <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Data
                                    d'inizio:</Typography> {period?.startFormatted}</Typography>
                                <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Data di
                                    fine: </Typography> {period?.endFormatted}</Typography>
                                <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Budget pro
                                    capite
                                    SI (CHF): </Typography> {period?.primarySchoolStudentBudgetFormatted}</Typography>
                                <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Budget pro
                                    capite SE
                                    1°
                                    ciclo
                                    (CHF): </Typography> {period?.elementarySchoolFirstCycleStudentBudgetFormatted}
                                </Typography>
                                <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Budget pro
                                    capite SE
                                    2°
                                    ciclo
                                    (CHF): </Typography> {period?.elementarySchoolSecondCycleStudentBudgetFormatted}
                                </Typography>
                            </>
                            :
                            ''
                        }

                    </Stack>
                </Paper>
            </Grid>
        </Grid>

        <BasicDialog isOpen={openDialog} handleClose={() => handleClose(setOpenDialog)} deleteFunction={deletePeriod}
                     title={warningText.titleAttention}
                     body={warningText.confirmDelete} closeLabel={warningText.noButton}
                     successLabel={warningText.deleteButton}/>

        <SnackBarAlert isOpen={openSnackBar} handleClose={() => handleClose(setOpenSnackBar)} type={'warning'}
                       title={warningText.titleAttention}
                       duration={10000}
                       body={warningText.errorDelete}/>

        <BasicDialog isOpen={errorAlert} handleClose={() => handleClose(setErrorAlert)} title={warningText.titleError}
                     body={warningText.somethingWentWrong} closeLabel={warningText.okButton} successLabel={undefined}/>

        {archiveConfirmModal ?
            <Dialog
                open={archiveConfirmModal}
                onClose={handleCloseBackdrop}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth='lg'
            >
                <DialogTitle id="alert-dialog-title">
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <ReportProblemOutlinedIcon sx={{mr: 1}}/> Attenzione
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Typography>Il periodo selezionato è associato alle seguenti funzioni.</Typography>
                        <Typography align={'center'} sx={{fontWeight: '600'}}>Sei sicuro di voler procedere
                            nell'archiviazione? L'azione rimane reversibile</Typography>
                        <TableContainer sx={{my: 2}}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell size='small'>Nome funzione</TableCell>
                                        <TableCell size='small'>Archiviata</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {budgetsFromPeriod.length > 0 ?
                                        budgetsFromPeriod.map((budget) => (
                                            <TableRow>
                                                <TableCell>{budget.name}</TableCell>
                                                <TableCell component="th" scope="row" sx={{verticalAlign: 'top'}}>
                                                    {budget.enabled ? 'No' : 'Si'}
                                                </TableCell>

                                            </TableRow>
                                        ))
                                        :
                                        ''
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setArchiveConfirmModal(false)} variant={'outlined'} color={'secondary'}>
                        Annulla
                    </Button>
                    <Button onClick={() => archivePeriod()} variant={'contained'} color={'primary'}>
                        Si, archivia
                    </Button>
                </DialogActions>
            </Dialog>
            :
            ''}
    </>);
}