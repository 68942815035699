import {Route, Routes, useLocation} from "react-router-dom";
import Orders from "../components/Orders"
import NotFound from "../pages/NotFound"
import OrderInfo from "../pages/OrderInfo";
import OrderProducts from "../pages/OrderProducts";
import OrderDetails from "../pages/OrderDetails";
import OrderCart from "../pages/OrderCart";
import Users from "../components/Users"
import SpendLimitReport from "../pages/SpendLimitReport"
import Locations from "../components/Locations"
import UserDetails from "../pages/UserDetails"
import EditLocation from "../pages/EditLocation";
import Functions from "../components/Functions"
import Coupons from "../components/Coupons";
import Catalogs from "../components/Catalogs";
import Categories from "../components/Categories";
import Logs from "../components/Logs";
import Periods from "../components/Periods";
import EditProduct from "../pages/EditProduct";
import EditCatalog from "../pages/EditCatalog";
import Products from "../components/Products";
import EditFunction from "../pages/EditFunction";
import EditPeriod from "../pages/EditPeriod";
import EditCategory from "../pages/EditCategory";
import CatalogDetails from "../pages/CatalogDetails";
import ProductDetails from "../pages/ProductDetails";
import PeriodDetails from "../pages/PeriodDetails";
import LocationDetails from "../pages/LocationDetails";
import CategoryDetails from "../pages/CategoryDetails";
import FunctionDetails from "../pages/FunctionDetails";
import EditStock from "../pages/EditStock";
import HomeUser from "../pages/HomeUser";
import HomeSecretary from "../pages/HomeSecretary";
import CouponDetails from "../pages/CouponDetails";
import EditCoupon from "../pages/EditCoupon";
import OrderUserDetails from "../pages/OrderUserDetails";
import OrderReturn from "../pages/OrderReturn";
import Manuals from "../pages/Manuals";
import EditUser from "../pages/EditUser";
import LoginError from "../pages/LoginError";
import Home from "../pages/Home";
import {getUser} from "../services/AuthService";
import {roles, routes, storage} from "../utils/ReusableText";
import ItemsOrderedByUserReport from "../pages/ItemsOrderedByUserReport";
import ItemsOrderedReport from "../pages/ItemsOrderedReport";
import StockProductsReport from "../pages/StockProductsReport";
import BudgetStatusLocationReport from "../pages/BudgetStatusLocationReport";
import CouponsTotalSpendReport from "../pages/CouponsTotalSpendReport";
import SeasonalityReport from "../pages/SeasonalityReport";
import CreateFunctionUser from "../pages/CreateFunctionUser";
import CreateCouponUser from "../pages/CreateCouponUser";

export default function Routing() {
    const path = useLocation();

    const RoleAccess = ({roles = [], children}) => {
        sessionStorage.setItem(storage.navPath, path.pathname);

        const currentUser = getUser();
        return roles.includes(currentUser?.role)
            ? children
            : <Home/>
    };

    function checkHomepage() {
        const currentUser = getUser();
        if (currentUser?.role === roles.manager) {
            return <Orders/>
        } else if (currentUser?.role === roles.user) {
            return <HomeUser/>
        } else if (currentUser?.role === roles.secretary) {
            return <HomeSecretary/>
        }
    }

    return (
        <Routes>
            <Route path={routes.loginError} element={<LoginError/>}/>
            <Route path="*" element={<NotFound/>}/>

            <Route path="/" element={<Home/>}>
                <Route path="/"
                       element={<RoleAccess children={checkHomepage()}
                                            roles={[roles.manager, roles.user, roles.secretary]}/>}/>
                <Route path={routes.homeManager}
                       element={<RoleAccess children={<Orders/>} roles={[roles.manager]}/>}/>
                <Route path={routes.homeUser}
                       element={<RoleAccess children={<HomeUser/>} roles={[roles.user]}/>}/>
                <Route path={routes.homeSecretary}
                       element={<RoleAccess children={<HomeSecretary/>} roles={[roles.secretary]}/>}/>


                <Route path={routes.orderInfo}
                       element={<RoleAccess children={<OrderInfo/>}
                                            roles={[roles.manager, roles.user, roles.secretary]}/>}/>
                <Route path={routes.orderInfo + '/:id'}
                       element={<RoleAccess children={<OrderInfo/>}
                                            roles={[roles.manager, roles.user, roles.secretary]}/>}/>
                <Route path={routes.orderProducts + '/:id'}
                       element={<RoleAccess children={<OrderProducts/>}
                                            roles={[roles.manager, roles.user, roles.secretary]}/>}/>
                <Route path={routes.orderProducts + '/:id/edit'}
                       element={<RoleAccess children={<OrderProducts/>}
                                            roles={[roles.manager, roles.user, roles.secretary]}/>}/>
                <Route path={routes.orderCart + '/:id'}
                       element={<RoleAccess children={<OrderCart/>}
                                            roles={[roles.manager, roles.user, roles.secretary]}/>}/>
                <Route path={routes.orderCart + '/:id/edit'}
                       element={<RoleAccess children={<OrderCart/>}
                                            roles={[roles.manager, roles.user, roles.secretary]}/>}/>
                <Route path={routes.orderReturn + '/:id'}
                       element={<RoleAccess children={<OrderReturn/>} roles={[roles.manager]}/>}/>
                <Route path={routes.orderDetails + '/:id'}
                       element={<RoleAccess children={<OrderDetails/>} roles={[roles.manager]}/>}/>
                <Route path={routes.orderUserDetails + '/:id'}
                       element={<RoleAccess children={<OrderUserDetails/>} roles={[roles.user, roles.secretary]}/>}/>


                <Route path={routes.periods} element={<RoleAccess children={<Periods/>} roles={[roles.manager]}/>}/>
                <Route path={routes.editPeriod}
                       element={<RoleAccess children={<EditPeriod/>} roles={[roles.manager]}/>}/>
                <Route path={routes.editPeriod + '/:id'}
                       element={<RoleAccess children={<EditPeriod/>} roles={[roles.manager]}/>}/>
                <Route path={routes.periodDetails + '/:id'}
                       element={<RoleAccess children={<PeriodDetails/>} roles={[roles.manager]}/>}/>


                <Route path={routes.locations} element={<RoleAccess children={<Locations/>} roles={[roles.manager]}/>}/>
                <Route path={routes.editLocation}
                       element={<RoleAccess children={<EditLocation/>} roles={[roles.manager]}/>}/>
                <Route path={routes.editLocation + '/:id'}
                       element={<RoleAccess children={<EditLocation/>} roles={[roles.manager]}/>}/>
                <Route path={routes.locationDetails + '/:id'}
                       element={<RoleAccess children={<LocationDetails/>} roles={[roles.manager]}/>}/>


                <Route path={routes.functions}
                       element={<RoleAccess children={<Functions/>} roles={[roles.manager]}/>}/>
                <Route path={routes.editFunction}
                       element={<RoleAccess children={<EditFunction/>} roles={[roles.manager]}/>}/>
                <Route path={routes.editFunction + '/:id'}
                       element={<RoleAccess children={<EditFunction/>} roles={[roles.manager]}/>}/>
                <Route path={routes.functionDetails + '/:id'}
                       element={<RoleAccess children={<FunctionDetails/>} roles={[roles.manager]}/>}/>


                <Route path={routes.catalogs} element={<RoleAccess children={<Catalogs/>} roles={[roles.manager]}/>}/>
                <Route path={routes.editCatalog}
                       element={<RoleAccess children={<EditCatalog/>} roles={[roles.manager]}/>}/>
                <Route path={routes.editCatalog + '/:id'}
                       element={<RoleAccess children={<EditCatalog/>} roles={[roles.manager]}/>}/>
                <Route path={routes.catalogDetails + '/:id'}
                       element={<RoleAccess children={<CatalogDetails/>} roles={[roles.manager]}/>}/>


                <Route path={routes.categories}
                       element={<RoleAccess children={<Categories/>} roles={[roles.manager]}/>}/>
                <Route path={routes.editCategory}
                       element={<RoleAccess children={<EditCategory/>} roles={[roles.manager]}/>}/>
                <Route path={routes.editCategory + '/:id'}
                       element={<RoleAccess children={<EditCategory/>} roles={[roles.manager]}/>}/>
                <Route path={routes.categoryDetails + '/:id'}
                       element={<RoleAccess children={<CategoryDetails/>} roles={[roles.manager]}/>}/>


                <Route path={routes.products} element={<RoleAccess children={<Products/>} roles={[roles.manager]}/>}/>
                <Route path={routes.editProduct}
                       element={<RoleAccess children={<EditProduct/>} roles={[roles.manager]}/>}/>
                <Route path={routes.editProduct + '/:id'}
                       element={<RoleAccess children={<EditProduct/>} roles={[roles.manager]}/>}/>
                <Route path={routes.productDetails + '/:id'}
                       element={<RoleAccess children={<ProductDetails/>} roles={[roles.manager]}/>}/>
                <Route path={routes.editStock + '/:id/new'}
                       element={<RoleAccess children={<EditStock/>} roles={[roles.manager]}/>}/>
                <Route path={routes.editStock + '/:id/:id'}
                       element={<RoleAccess children={<EditStock/>} roles={[roles.manager]}/>}/>


                <Route path={routes.users} element={<RoleAccess children={<Users/>} roles={[roles.manager]}/>}/>
                <Route path={routes.editUser} element={<RoleAccess children={<EditUser/>} roles={[roles.manager]}/>}/>
                <Route path={routes.editUser + '/:id'}
                       element={<RoleAccess children={<EditUser/>} roles={[roles.manager]}/>}/>
                <Route path={routes.userDetails + '/:id'}
                       element={<RoleAccess children={<UserDetails/>} roles={[roles.manager]}/>}/>
                <Route path={routes.createFunctionUser + '/:id'}
                       element={<RoleAccess children={<CreateFunctionUser/>} roles={[roles.manager]}/>}/>


                <Route path={routes.logs} element={<RoleAccess children={<Logs/>} roles={[roles.manager]}/>}/>


                <Route path={routes.spendLimitReport}
                       element={<RoleAccess children={<SpendLimitReport/>} roles={[roles.manager, roles.secretary]}/>}/>
                <Route path={routes.itemsOrderedByUserReport}
                       element={<RoleAccess children={<ItemsOrderedByUserReport/>}
                                            roles={[roles.manager, roles.secretary]}/>}/>
                <Route path={routes.itemsOrderedReport}
                       element={<RoleAccess children={<ItemsOrderedReport/>}
                                            roles={[roles.manager, roles.secretary]}/>}/>
                <Route path={routes.stockProductsReport}
                       element={<RoleAccess children={<StockProductsReport/>}
                                            roles={[roles.manager, roles.secretary]}/>}/>
                <Route path={routes.budgetStatusLocationReport}
                       element={<RoleAccess children={<BudgetStatusLocationReport/>}
                                            roles={[roles.manager, roles.secretary]}/>}/>
                <Route path={routes.couponsTotalSpendReport}
                       element={<RoleAccess children={<CouponsTotalSpendReport/>}
                                            roles={[roles.manager, roles.secretary]}/>}/>
                <Route path={routes.seasonalityReport}
                       element={<RoleAccess children={<SeasonalityReport/>}
                                            roles={[roles.manager, roles.secretary]}/>}/>


                <Route path={routes.coupons}
                       element={<RoleAccess children={<Coupons/>} roles={[roles.secretary, roles.manager]}/>}/>
                <Route path={routes.editCoupon}
                       element={<RoleAccess children={<EditCoupon/>} roles={[roles.secretary, roles.manager]}/>}/>
                <Route path={routes.editCoupon + '/:id'}
                       element={<RoleAccess children={<EditCoupon/>} roles={[roles.secretary, roles.manager]}/>}/>
                <Route path={routes.couponDetails + '/:id'}
                       element={<RoleAccess children={<CouponDetails/>} roles={[roles.secretary, roles.manager]}/>}/>
                <Route path={routes.createCouponUser + '/:id'}
                       element={<RoleAccess children={<CreateCouponUser/>} roles={[roles.manager]}/>}/>

                <Route path={routes.userManual}
                       element={<RoleAccess children={<Manuals/>}
                                            roles={[roles.manager, roles.user, roles.secretary]}/>}/>
            </Route>

        </Routes>
    )
}