import './App.css';
import Routing from "./routing/Routing";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {ReactKeycloakProvider} from "@react-keycloak/web";
import keycloak from "./Keycloak";
import React from 'react';
import ApiService from "./services/ApiService";
import {setUser} from "./services/AuthService";
import {useNavigate} from "react-router-dom";
import EventObserver from "./utils/EventObserver";
import {roles, routes, storage} from "./utils/ReusableText";

const theme = createTheme({
    palette: {
        type: 'light',
        common: {
            white: '#fff',
            black: '#212121'
        },
        primary: {
            main: '#c23052',
        },
        secondary: {
            main: '#333333',
        },
        background: {
            default: '#ffffff',
            paper: '#fafafa'
        },
        success: {
            main: '#4CAF50'
        },
        warning: {
            main: '#ff9800',
        },
        error: {
            main: '#f44336',
        },
        info: {
            main: '#0dcaf0',
        },
    },
    typography: {
        fontFamily: [
            'Nunito',
            'Helvetica',
            'Roboto',
            'sans-serif',
        ].join(','),
        button: {
            textTransform: 'initial'
        }
    },
    components: {
        MuiButton: {
            variants: [
                {
                    props: {variant: 'flip'},
                    style: {
                        color: '#333333',
                        background: '#ffffff',
                        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
                    },
                },
            ],
        },
        MuiOutlinedInput: {
            variants: [
                {
                    props: {size: 'xsmall'},
                    style: {
                        height: '2rem',
                        fontSize: '0.75rem'
                    },
                }
            ]
        }

    },

});

function App() {
    const navigate = useNavigate();

    const eventLogger = (event: unknown, error: unknown) => {
        console.log('onKeycloakEvent', event, error);

        if (event === 'onReady') {
            ApiService.getCurrentUser().then(response => {
                localStorage.removeItem('selectedRoute');
                if (response) {
                    if (response?.role === roles.manager) {
                        localStorage.setItem('selectedRoute', routes.homeManager);
                    } else if (response?.role === roles.secretary) {
                        localStorage.setItem('selectedRoute', routes.homeSecretary);
                    } else {
                        localStorage.setItem('selectedRoute', routes.homeUser);
                    }

                    setUser(response);
                    EventObserver.dispatch(storage.userEvent, {currentUser: response});

                    const lastPath = sessionStorage.getItem(storage.navPath);
                    if (lastPath) {
                        navigate(lastPath)
                    } else {
                        navigate('/')
                    }
                }
            }).catch(() => {
                    navigate('/' + routes.loginError)
                }
            )
        }
    }

    const tokenLogger = (tokens: unknown) => {
        console.log('onKeycloakTokens', tokens);
    }

    return (
        <ThemeProvider theme={theme}>
            <ReactKeycloakProvider authClient={keycloak} onEvent={eventLogger} onTokens={tokenLogger}
                                   initOptions={{onLoad: 'login-required'}}>
                <Routing/>
            </ReactKeycloakProvider>
        </ThemeProvider>
    )
}

export default App;