import React, {useCallback, useEffect, useState} from 'react';
import {Box, Button, Grid, Typography} from "@mui/material";
import ApiService from "../services/ApiService";
import {AgGridReact} from "ag-grid-react";
import {agGridItalianTranslation} from "../configuration/agGridItalianTranslation";
import BasicDialog from "../components/BasicDialog";
import {handleClose} from "../utils/ReusableFunction";
import {warningText} from "../utils/ReusableText";
import SelectWithSearch from "../components/SelectWithSearch";
import {voidPeriod, voidProduct} from "../utils/ReusableObjects";
import {columnDefsItemsOrdered} from "../utils/agGridDefs";
import CircularProgress from "@mui/material/CircularProgress";

export default function ItemsOrderedReport() {
    const [rowData, setRowData] = useState([]);
    const [columnDefs, setColumnDefs] = useState([]);
    const [defaultColDef] = useState({
        resizable: true,
        flex: 1,
    });
    const [loading, setLoading] = useState(true);
    const [productSelected, setProductSelected] = useState(voidProduct);
    const [periodSelected, setPeriodSelected] = useState(voidPeriod);
    const [listOfProducts, setListOfProducts] = useState([]);
    const [listOfPeriods, setListOfPeriods] = useState([]);
    const [errorAlert, setErrorAlert] = useState(false);
    const [spinner, setSpinner] = useState(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fetchReport = useCallback(() => {
        getItemsOrderedReport().then(response => {
            setColumnDefs(columnDefsItemsOrdered);
            if (response) {
                setRowData(response);
            }
        });
    });

    useEffect(() => {
        ApiService.getPeriods().then(response => {
            setListOfPeriods(response);
            ApiService.getProducts().then(response => {
                setListOfProducts(response);
            });
        });
    }, []);

    const getItemsOrderedReport = async () => {
        setSpinner(true);
        const filter = {
            productId: productSelected.id,
            schoolYearId: periodSelected.id
        }

        return await ApiService.getItemsOrderedReport(filter).then(response => {
            setSpinner(false);
            setLoading(false);
            setColumnDefs(columnDefsItemsOrdered);
            if (response.length > 0) {
                return response;
            } else {
                return [];
            }
        }).catch((e) => setErrorAlert(true));
    }

    return (<>
            <Grid container sx={{mt: 2}} justifyContent={'space-between'}>
                <Grid item xs="auto">
                    <Typography variant="h5" component="h2" sx={{fontWeight: '500'}}>Articoli comandati</Typography>
                </Grid>
            </Grid>

            <Grid container sx={{mt: 3}}>
                <Grid item xs={12} md={12} lg={12}>
                    <Box
                        sx={{
                            background: '#EFEFEF',
                            p: 1,
                            mb: 2
                        }}
                    >
                        <Grid container spacing={1}>
                            <Grid item xs={6} sm>
                                <SelectWithSearch options={listOfProducts} value={productSelected}
                                                  onChange={setProductSelected}
                                                  required={true} multiple={false} label={"Seleziona un prodotto"}
                                                  id={"productSelected"} entity={'codeProduct'}/>
                            </Grid>
                            <Grid item xs={5} sm>
                                <SelectWithSearch options={listOfPeriods} value={periodSelected}
                                                  onChange={setPeriodSelected}
                                                  required={true} multiple={false} label={"Seleziona un periodo"}
                                                  id={"periodSelected"} entity={'period'}/>
                            </Grid>
                            <Grid item xs={1} sm={'auto'}>
                                <Button variant="contained" color="primary" fullWidth sx={{mt: 1}}
                                        disabled={!(periodSelected?.id && productSelected?.id) || spinner}
                                        onClick={() => fetchReport()}>
                                    {spinner ? <CircularProgress size={20} color="inherit"/> : "Genera report"}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>

                    {!loading ? <>
                        <div style={{width: '100%', height: '100%'}} className="ag-theme-material ag-custom">
                            <div style={{width: '100%', height: '100%'}}>
                                <AgGridReact
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    rowData={rowData}
                                    pagination={true}
                                    paginationPageSize={25}
                                    singleClickEdit={true}
                                    domLayout={'autoHeight'}
                                    localeText={agGridItalianTranslation}
                                >
                                </AgGridReact>
                            </div>
                        </div>
                    </> : ''}
                </Grid>
            </Grid>

            <BasicDialog isOpen={errorAlert} handleClose={() => handleClose(setErrorAlert)}
                         title={warningText.titleError}
                         body={warningText.somethingWentWrong} closeLabel={warningText.okButton}
                         successLabel={undefined}/>
        </>
    );
}