import {Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import React from "react";

export default function BasicDialog({isOpen, handleClose, deleteFunction, title, body, closeLabel, successLabel, successFunction}) {
    return (
        <>
            <Dialog
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
            >
                <DialogTitle id="dialog-title">
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <ReportProblemOutlinedIcon sx={{mr: 1}}/> {title}
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="dialog-description">
                        {body}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant={'outlined'} color={'secondary'}>{closeLabel}</Button>
                    {successLabel ?
                        <Button onClick={() => successFunction ? successFunction() : deleteFunction()} variant={'contained'} color={'primary'}>
                            {successLabel}
                        </Button>
                        :
                        ''
                    }
                </DialogActions>
            </Dialog>
        </>
    )
}
