import * as React from 'react';
import {useState} from 'react';
import {IconButton, Menu, MenuItem} from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {useNavigate} from "react-router-dom";
import {routes} from "../utils/ReusableText";

const options = [
    'Modifica',
    'Dettaglio',
    'Registra Merce'
];

export default function DropdownTableButton(params) {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleNavigate = (option) => {
        if (option === 'Modifica') {
            navigate('/' + routes.editProduct + '/' + params.data.id);
        } else if (option === 'Dettaglio') {
            navigate('/' + routes.productDetails + '/' + params.data.id);
        } else if (option === 'Registra Merce') {
            navigate('/' + routes.editStock + '/' + params.data.id + '/new');
        } else {
        }
    };

    return (
        <div>
            <IconButton
                aria-label="action"
                id="long-button"
                aria-controls="long-menu"
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                color='secondary'
            >
                <MoreVertIcon className="dropdown-button" sx={{'pointer-events' : 'none'}} />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {options.map((option) => (
                    <MenuItem disabled={option !== 'Dettaglio' && !params.data.enabled} key={option} selected={option === 'Pyxis'}
                              sx={{'fontSize': '0.875rem'}}
                              onClick={() => {
                                  handleNavigate(option)
                              }}>
                        {option}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}
