import React, {useEffect, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import ApiService from "../services/ApiService";
import {
    Button,
    ButtonGroup,
    ClickAwayListener,
    Grid,
    Grow,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Stack,
    Tab,
    Tabs,
    Typography
} from "@mui/material";
import BasicDialog from "../components/BasicDialog";
import SnackBarAlert from "../components/SnackBarAlert";
import {label, routes, warningText} from "../utils/ReusableText";
import {handleClose} from "../utils/ReusableFunction";
import Box from "@mui/material/Box";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PropTypes from "prop-types";
import {columnCouponsBudgetDetails, columnOrdersBudgetDetails} from "../utils/agGridDefs";
import {AgGridReact} from "ag-grid-react";
import {agGridItalianTranslation} from "../configuration/agGridItalianTranslation";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (<div
        hidden={value !== index}
        id={`wrapped-tab-${index}`}
        {...other}
    >
        {value === index && (
            <Grid container sx={{mt: 2}}>
                <Grid item xs={12}>
                    {children}
                </Grid>
            </Grid>
        )}
    </div>);
}

TabPanel.propTypes = {
    children: PropTypes.node, index: PropTypes.any.isRequired, value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `wrapped-tab-${index}`, 'aria-controls': `wrapped-Orders-${index}`,
    };
}

export default function FunctionDetails() {
    const navigate = useNavigate();
    const path = useLocation();
    const id = path.pathname.split('/')[2];
    const [budget, setBudget] = useState(undefined);
    const [openDialog, setOpenDialog] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [errorAlert, setErrorAlert] = useState(false);
    const [openButtonsGroup, setOpenButtonsGroup] = useState(false);
    const anchorRef = useRef(null);
    const [value, setValue] = useState('one');
    const [rowDataOrders, setRowDataOrders] = useState([]);
    const [rowDataCoupons, setRowDataCoupons] = useState([]);
    const [columnDefsOrders] = useState(columnOrdersBudgetDetails);
    const [columnDefsCoupons] = useState(columnCouponsBudgetDetails);
    const [defaultColDef] = useState({
        resizable: true,
        flex: 1,
    });

    useEffect(() => {
        if (id) {
            ApiService.getBudgetById(id).then(response => {
                setBudget(response);

                fetchUserDataAndOrdersAndCoupons(response);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    async function fetchUserDataAndOrdersAndCoupons(budget) {
        ApiService.getOrdersByBudgetId(budget.id).then(response => {
            setRowDataOrders(response);
            ApiService.getCouponsByBudgetId(budget.id).then(res => {
                setRowDataCoupons(res);
            })
        })
    }

    const confirmOpen = () => {
        setOpenDialog(true);
    };

    function deleteFunction() {
        ApiService.deleteBudgetById(id).then(response => {
            navigate('/' + routes.functions);
        }).catch((e) => {
            if (e.body?.code === 2) {
                setOpenDialog(false);
                setOpenSnackBar(true);
            } else {
                setErrorAlert(true);
            }
        });
    }

    function archiveFunction() {
        const users = budget.users.map(user => user.id);

        const body = {
            id: budget.id,
            name: budget.name,
            schoolYearId: budget.schoolYear?.id,
            catalogId: budget.catalog?.id,
            locationId: budget.location ? budget.location.id : undefined,
            users: users,
            hasLimit: budget.amount !== null,
            primarySchoolStudents: budget.primarySchoolStudents,
            elementarySchoolFirstCycleStudents: budget.elementarySchoolFirstCycleStudents,
            elementarySchoolSecondCycleStudents: budget.elementarySchoolSecondCycleStudents,
            arbitraryAmount: budget.arbitraryAmount,
            enabled: !budget.enabled
        }

        ApiService.updateBudget(body).then(response => {
            setBudget(response);
            navigate('/' + routes.functionDetails + '/' + id);
        }).catch((e) => {
            setErrorAlert(true);
        });
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    //dropdown button
    const handleToggle = () => {
        setOpenButtonsGroup((prevOpen) => !prevOpen);
    };
    const handleCloseButtons = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpenButtonsGroup(false);
    };

    const handleRowClickOrders = (event) => {
        const dropdownButtonClicked = event.event.target.classList.contains('dropdown-button');
        if (!dropdownButtonClicked) {
            const rowId = event.data.id;
            window.location.href = `/` + routes.orderDetails + `/${rowId}`;
        }
    };

    const handleRowClickCoupons = (event) => {
        const dropdownButtonClicked = event.event.target.classList.contains('dropdown-button');
        if (!dropdownButtonClicked) {
            const rowId = event.data.id;
            window.location.href = `/` + routes.couponDetails + `/${rowId}`;
        }
    };

    return (<>

        <Grid container sx={{mb: 4}}>
            <Grid item xs={12}>
                <Button variant="flip"
                        onClick={() => {
                            navigate('/' + routes.functions)
                        }}>
                    Indietro
                </Button>
            </Grid>
        </Grid>

        <Grid container sx={{marginTop: 2}}>
            <Grid item xs={12} md={6}>
                <Typography variant="h5" component="h2" sx={{fontWeight: '500'}}>Dettaglio Funzione</Typography>
            </Grid>


            <Grid item xs={12} md={6} justifyContent={'flex-end'}>
                <Box component={'div'} sx={{'display': 'flex', 'justifyContent': 'flex-end'}}>
                    <ButtonGroup variant="outlined" ref={anchorRef} aria-label="split button"
                                 sx={{mt: {xs: 2, md: 0}}}>
                        <Button variant="flip" sx={{px: {xs: 2, md: 5}}}
                                onClick={() => navigate(('/' + routes.editFunction + '/' + id))}
                                disabled={!budget?.enabled}>
                            Modifica
                        </Button>

                        <Button
                            size="small"
                            aria-controls={openButtonsGroup ? 'split-button-menu' : undefined}
                            aria-expanded={openButtonsGroup ? 'true' : undefined}
                            aria-label="select merge strategy"
                            aria-haspopup="menu"
                            onClick={handleToggle}
                            variant="flip"
                        >
                            <MoreVertIcon/>
                        </Button>
                    </ButtonGroup>
                    <Popper
                        sx={{
                            zIndex: 1,
                        }}
                        open={openButtonsGroup}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                        placement={"bottom-end"}
                    >
                        {({TransitionProps}) => (
                            <Grow
                                {...TransitionProps}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleCloseButtons}>
                                        <MenuList id="split-button-menu" autoFocusItem sx={{'minWidth': '100px'}}>
                                            <MenuItem onClick={() => archiveFunction()}
                                                      sx={{'fontSize': '0.875rem', 'minHeight': '38px'}}>
                                                {budget?.enabled ? label.archive : label.restore}
                                            </MenuItem>
                                            <MenuItem onClick={() => confirmOpen()}
                                                      sx={{'fontSize': '0.875rem', 'minHeight': '38px'}}
                                                      disabled={!budget?.enabled}>
                                                Elimina
                                            </MenuItem>

                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </Box>
            </Grid>
        </Grid>

        <Grid container sx={{mt: 3}} columnSpacing={3}>
            <Grid item xs={12} md={6} lg={4}>
                <Paper
                    component={'div'}
                    elevation={0}
                    sx={{
                        borderRadius: 0,
                        borderTop: '2px solid #c23052',
                        p: 2,
                        mb: 4
                    }}
                >
                    <Stack spacing={1.25}>
                        <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Nome
                            funzione: </Typography>{budget?.name}</Typography>
                        <Typography><Typography component={'span'}
                                                sx={{color: 'rgba(0,0,0,.7)'}}>Periodo: </Typography>{budget?.schoolYear?.name}
                        </Typography>
                        <Typography><Typography component={'span'}
                                                sx={{color: 'rgba(0,0,0,.7)'}}>Catalogo: </Typography>{budget?.catalog?.name}
                        </Typography>
                        <Typography><Typography component={'span'}
                                                sx={{color: 'rgba(0,0,0,.7)'}}>Sede: </Typography>{budget?.location?.name}
                        </Typography>
                        <Typography><Typography component={'span'}
                                                sx={{color: 'rgba(0,0,0,.7)'}}>Utenti: </Typography>{budget?.users && budget?.users.length > 0 ? budget?.users.map(
                            (user) => (
                                <span>{user.fullName + " "}</span>
                            )) : ''}
                        </Typography>
                        <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Budget attuale
                            (CHF): </Typography>{budget?.availableAmountFormatted}</Typography>
                        <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Budget iniziale
                            (CHF): </Typography>{budget?.amountFormatted}</Typography>
                        {budget?.amountFormatted !== label.noLimitBudget ?
                            <> <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Budget
                                aggiuntivo
                                (CHF): </Typography>{budget?.arbitraryAmountFormatted}</Typography>
                                <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>N. studenti
                                    SI: </Typography>{budget?.primarySchoolStudents}</Typography>
                                <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>N. studenti SE
                                    1°
                                    ciclo: </Typography>{budget?.elementarySchoolFirstCycleStudents}</Typography>
                                <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>N. studenti SE
                                    2°
                                    ciclo: </Typography>{budget?.elementarySchoolSecondCycleStudents}</Typography>
                            </>
                            :
                            ''
                        }
                    </Stack>
                </Paper>
            </Grid>
        </Grid>

        <Box sx={{borderBottom: 1, borderColor: 'divider', marginTop: 3}}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab value="one"
                     label="Ordini"
                     {...a11yProps('one')}
                />
                <Tab value="two" label="Buoni" {...a11yProps('two')} />
            </Tabs>
        </Box>

        <TabPanel value={value} index="one">
            <div style={{width: '100%', height: '100%'}} className="ag-theme-material ag-custom">
                <div style={{width: '100%', height: '100%'}}>
                    <AgGridReact
                        columnDefs={columnDefsOrders}
                        defaultColDef={defaultColDef}
                        rowData={rowDataOrders}
                        pagination={true}
                        paginationPageSize={25}
                        singleClickEdit={true}
                        domLayout={'autoHeight'}
                        localeText={agGridItalianTranslation}
                        onRowClicked={handleRowClickOrders}
                    >
                    </AgGridReact>
                </div>
            </div>
        </TabPanel>
        <TabPanel value={value} index="two">
            <div style={{width: '100%', height: '100%'}} className="ag-theme-material ag-custom">
                <div style={{width: '100%', height: '100%'}}>
                    <AgGridReact
                        columnDefs={columnDefsCoupons}
                        defaultColDef={defaultColDef}
                        rowData={rowDataCoupons}
                        pagination={true}
                        paginationPageSize={25}
                        singleClickEdit={true}
                        domLayout={'autoHeight'}
                        localeText={agGridItalianTranslation}
                        onRowClicked={handleRowClickCoupons}
                    >
                    </AgGridReact>
                </div>
            </div>
        </TabPanel>


        <BasicDialog isOpen={openDialog} handleClose={() => handleClose(setOpenDialog)} deleteFunction={deleteFunction}
                     title={warningText.titleAttention}
                     body={warningText.confirmDelete} closeLabel={warningText.noButton}
                     successLabel={warningText.deleteButton}/>

        <SnackBarAlert isOpen={openSnackBar} handleClose={() => handleClose(setOpenSnackBar)} type={'warning'}
                       title={warningText.titleAttention}
                       duration={10000}
                       body={warningText.errorDelete}/>

        <BasicDialog isOpen={errorAlert} handleClose={() => handleClose(setErrorAlert)} title={warningText.titleError}
                     body={warningText.somethingWentWrong} closeLabel={warningText.okButton} successLabel={undefined}/>
    </>);
}