import {getToken} from "./AuthService";

export function authHeader() {
    const jwt = getToken();
    if (jwt) {
        return {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
            "Authorization": "Bearer " + jwt
        };
    } else {
        return {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
        }
    }
}

export function authHeaderImage() {
    const jwt = getToken();
    if (jwt) {
        return {
            "Accept": "image/jpeg, image/png",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
            "Authorization": "Bearer " + jwt
        };
    } else {
        return {
            "Accept": "image/jpeg, image/png",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
        }
    }
}

export function authHeaderPdf() {
    const jwt = getToken();
    if (jwt) {
        return {
            "Accept": "application/pdf",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
            "Authorization": "Bearer " + jwt
        };
    } else {
        return {
            "Accept": "application/pdf",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
        }
    }
}

