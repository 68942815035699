import Keycloak from "keycloak-js";

const { REACT_APP_KC_URL } = process.env;
const { REACT_APP_KC_REALM } = process.env;
const { REACT_APP_KC_CLIENTID } = process.env;

const keycloak = new Keycloak({
    url: `${REACT_APP_KC_URL}`,
    realm: `${REACT_APP_KC_REALM}`,
    clientId: `${REACT_APP_KC_CLIENTID}`
});

export default keycloak;
