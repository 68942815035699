import React, {useEffect, useState, useRef} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import ApiService from "../services/ApiService";
import {
    Button,
    ButtonGroup,
    ClickAwayListener,
    Grid,
    Grow,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Stack
} from "@mui/material";
import AgGrid from "../components/AgGrid";
import Typography from "@mui/material/Typography";
import BasicDialog from "../components/BasicDialog";
import SnackBarAlert from "../components/SnackBarAlert";
import {label, routes, warningText} from "../utils/ReusableText";
import {handleClose} from "../utils/ReusableFunction";
import {dataStocks} from "../utils/agGridDefs";
import Box from "@mui/material/Box";
import MoreVertIcon from "@mui/icons-material/MoreVert";

export function confirmStockDelete(params) {
    if (window.confirm(warningText.confirmDelete)) {
        ApiService.deleteStockByStockId(params.data.id).then(response => {
            window.location.reload();
        })
    }
}

export default function ProductDetails() {
    const navigate = useNavigate();
    const path = useLocation();
    const id = path.pathname.split('/')[2];
    const [product, setProduct] = useState(undefined);
    const [image, setImage] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [errorAlert, setErrorAlert] = useState(false);
    const [openButtonsGroup, setOpenButtonsGroup] = useState(false);
    const anchorRef = useRef(null);

    useEffect(() => {
        if (id) {
            setLoading(true);

            ApiService.getProductById(id).then(async response => {
                setProduct(response);

                if (response.image) {
                    const imageSplitted = response.image.split('/');
                    const productImage = imageSplitted[imageSplitted.length - 1];

                    const res = await ApiService.getImage(productImage);
                    const imageBlob = await res.blob();
                    const imageObjectURL = URL.createObjectURL(imageBlob);
                    setImage(imageObjectURL);
                }

            })

            ApiService.getStocksByProductId(id).then(response => {
                setLoading(false);
                if (response.length > 0) {
                    dataStocks.rowData = response;
                } else {
                    dataStocks.rowData = [];
                }
            })

        }
    }, [id]);

    const confirmOpen = () => {
        setOpenDialog(true);
    };

    function deleteProduct() {
        ApiService.deleteProductById(id).then(response => {
            navigate('/' + routes.products);
        }).catch((e) => {
            if (e.body?.code === 2) {
                setOpenDialog(false);
                setOpenSnackBar(true);
            } else {
                setErrorAlert(true);
            }
        });
    }

    function archiveProduct() {
        const body = {
            id: product.id,
            name: product.name,
            code: product.code,
            price: product.price,
            catalogId: product.catalog.id,
            minimumStockQuantity: product.minimumStockQuantity,
            categoryId: product.category ? product.category.id : undefined,
            unitDescription: product.unitDescription,
            enabled: !product.enabled
        }

        ApiService.updateProducts(body).then(response => {
            setProduct(response);
            navigate('/' + routes.productDetails + '/' + id);
        }).catch((e) => {
            setErrorAlert(true);
        });
    }

    //dropdown button
    const handleToggle = () => {
        setOpenButtonsGroup((prevOpen) => !prevOpen);
    };
    const handleCloseButtons = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpenButtonsGroup(false);
    };

    return (<>
        <Grid container sx={{mb: 4}}>
            <Grid item xs={12}>
                <Button variant="flip"
                        onClick={() => {
                            navigate('/' + routes.products)
                        }}>
                    Indietro
                </Button>
            </Grid>
        </Grid>

        <Grid container sx={{marginTop: 2}}>
            <Grid item xs={12} md={6}>
                <Typography variant="h5" component="h2" sx={{fontWeight: '500'}}>Dettaglio Prodotto</Typography>
            </Grid>

            <Grid item xs={12} md={6} justifyContent={'flex-end'}>
                <Box component={'div'} sx={{'display': 'flex', 'justifyContent':'flex-end'}}>
                    <ButtonGroup variant="outlined" color={'secondary'} ref={anchorRef} aria-label="split button" sx={{mt:{xs:2, md:0}}}>
                        <Button variant="flip" sx={{px: {xs:2, md:5}}}
                                onClick={() => navigate('/' + routes.editStock + '/' + id + '/new')}
                                disabled={!product?.enabled}>
                            Crea Fornitura
                        </Button>
                        <Button variant="flip" sx={{px: {xs:2, md:5}}}
                                onClick={() => navigate(('/' + routes.editProduct + '/' + id))}
                                disabled={!product?.enabled}>
                            Modifica
                        </Button>

                        <Button
                            size="small"
                            aria-controls={openButtonsGroup ? 'split-button-menu' : undefined}
                            aria-expanded={openButtonsGroup ? 'true' : undefined}
                            aria-label="select merge strategy"
                            aria-haspopup="menu"
                            onClick={handleToggle}
                            variant="flip"
                        >
                            <MoreVertIcon />
                        </Button>
                    </ButtonGroup>
                    <Popper
                        sx={{
                            zIndex: 1,
                        }}
                        open={openButtonsGroup}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                        placement={"bottom-end"}
                    >
                        {({ TransitionProps }) => (
                            <Grow
                                {...TransitionProps}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleCloseButtons}>
                                        <MenuList id="split-button-menu" autoFocusItem sx={{'minWidth': '100px'}}>
                                            <MenuItem sx={{'fontSize': '0.875rem', 'minHeight': '38px'}} onClick={() => archiveProduct()}>
                                                {product?.enabled ? label.archive : label.restore}
                                            </MenuItem>
                                            <MenuItem sx={{'fontSize': '0.875rem', 'minHeight': '38px'}} onClick={() => confirmOpen()}
                                                    disabled={!product?.enabled}>
                                                Elimina
                                            </MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </Box>
            </Grid>

        </Grid>

        <Grid container sx={{mt: 3}} columnSpacing={3}>
            <Grid item xs={12} md={6} lg={4}>
                <Paper
                    component={'div'}
                    elevation={0}
                    sx={{
                        borderRadius: 0,
                        borderTop: '2px solid #c23052',
                        p: 2,
                        mb: 4
                    }}
                >
                    <Stack spacing={1.25}>
                        <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Codice
                            prodotto: </Typography>{product?.code}</Typography>
                        <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Nome
                            prodotto: </Typography>{product?.name}</Typography>
                        <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Costo unitario
                            (CHF): </Typography>{product?.priceFormatted}</Typography>
                        <Typography>
                            <Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Descrizione
                                unità: </Typography>{product?.unitDescription}</Typography>
                        <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Punto di
                            riordino: </Typography>{product?.minimumStockQuantity}</Typography>
                        <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Quantità
                            disponibile: </Typography>{product?.stock}</Typography>
                        <Typography><Typography component={'span'}
                                                sx={{color: 'rgba(0,0,0,.7)'}}>Catalogo: </Typography>{product?.catalog?.name}
                        </Typography>
                        <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Categoria
                            prodotto: </Typography>{product?.category?.name}</Typography>
                    </Stack>
                </Paper>
            </Grid>

            {image ?
                <Grid item xs={12} md={6} lg={2}>
                    <Paper
                        elevation={0}
                        sx={{
                            p: 1,
                            borderRadius: 0,
                            "& img": {
                                width: "100%",
                                height: 'auto'
                            }
                        }}
                    >
                        <img src={image} alt={""}/>
                    </Paper>
                </Grid>
                :
                ''
            }
        </Grid>


        {product?.enabled ?
            <><Typography variant="h6" component="h2" sx={{fontWeight: '500'}}>Forniture</Typography>
                <AgGrid children={loading ? [] : dataStocks}/>
            </>
            :
            ''}

        <BasicDialog isOpen={openDialog} handleClose={() => handleClose(setOpenDialog)} deleteFunction={deleteProduct}
                     title={warningText.titleAttention}
                     body={warningText.confirmDelete} closeLabel={warningText.noButton}
                     successLabel={warningText.deleteButton}/>

        <SnackBarAlert isOpen={openSnackBar} handleClose={() => handleClose(setOpenSnackBar)} type={'warning'}
                       title={warningText.titleAttention}
                       duration={10000}
                       body={warningText.errorDelete}/>

        <BasicDialog isOpen={errorAlert} handleClose={() => handleClose(setErrorAlert)} title={warningText.titleError}
                     body={warningText.somethingWentWrong} closeLabel={warningText.okButton} successLabel={undefined}/>
    </>);
}