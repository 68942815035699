import React, {useEffect, useState} from "react";
import {Box, Card, CardContent, IconButton, Modal, Stack, TextField, Tooltip, Typography} from "@mui/material";
import CircleIcon from '@mui/icons-material/Circle';
import ApiService from "../services/ApiService";
import {roundCircleStock, warningText} from "../utils/ReusableText";
import {handleClose} from "../utils/ReusableFunction";
import SnackBarAlert from "../components/SnackBarAlert";
import CloseIcon from "@mui/icons-material/Close";
import testImage from "../assets/img/placeholder.png";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';

export default function TableViewProduct({product, orderInitial, changeOrderInitial}) {
    const [quantitySelected, setQuantitySelected] = useState(1);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [order, setOrder] = useState(orderInitial);
    const [open, setOpen] = useState(false);
    const [image, setImage] = useState(undefined);
    const handleOpen = () => setOpen(true);
    const handleCloseOpen = () => setOpen(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 'calc(100% - 48px)',
        maxWidth: 600,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 2,
    };

    useEffect(() => {
        if (product.image) {
            const imageSplitted = product.image.split('/');
            const productImage = imageSplitted[imageSplitted.length - 1];

            getImage(productImage).then();
        }
    }, [product]);

    function handleChange(event) {
        if (Number(event) === 0 || Number(event) === 0) {
            setQuantitySelected(1);
        } else {
            setQuantitySelected(event);
        }
    }

    async function getImage(productImage) {
        const res = await ApiService.getImage(productImage);
        const imageBlob = await res.blob();
        const imageObjectURL = URL.createObjectURL(imageBlob);
        setImage(imageObjectURL);
    }

    const addToCart = (id) => {
        if (Number(quantitySelected) > 0) {
            if (order?.items?.length > 0) {
                const itemToAdd = order.items.find((item) => item.product.id === id);

                if (itemToAdd) {
                    const body = {
                        id: itemToAdd.id,
                        quantity: itemToAdd.quantity + Number(quantitySelected),
                        orderId: order.id
                    }
                    ApiService.updateOrderItem(body).then(response => {
                        setOrder(response);
                        changeOrderInitial(response);
                        setOpenSnackBar(true);
                        setQuantitySelected(1);
                    });
                } else {
                    const body = {
                        quantity: Number(quantitySelected),
                        productId: id,
                        orderId: order.id
                    }
                    ApiService.addOrderItem(body).then(response => {
                        setOrder(response);
                        changeOrderInitial(response);
                        setOpenSnackBar(true);
                        setQuantitySelected(1);
                    });
                }
            } else {
                const body = {
                    quantity: Number(quantitySelected),
                    productId: id,
                    orderId: order.id
                }
                ApiService.addOrderItem(body).then(response => {
                    setOrder(response);
                    changeOrderInitial(response);
                    setOpenSnackBar(true);
                    setQuantitySelected(1)
                });
            }
        }
    }

    function getDescriptionRoundCircle() {
        let circleColor = 'orange';
        let setTitle = roundCircleStock.orange;

        if (product.stock > product.minimumStockQuantity) {
            setTitle = roundCircleStock.green;
            circleColor = 'green';
        } else if ((product.stock <= 0 && product.minimumStockQuantity >= 0)) {
            setTitle = roundCircleStock.red;
            circleColor = 'red';
        } else {
        }

        return <Tooltip title={setTitle} placement="top">
            {circleColor === 'green' ?
                <CircleIcon sx={{
                    color: (theme) => theme.palette.success.main,
                    width: '16px',
                    height: '16px'
                }}/>
                :
                <CircleIcon sx={{
                    color: (theme) => circleColor === 'red' ? theme.palette.error.main : theme.palette.warning.main,
                    width: '16px',
                    height: '16px'
                }}/>}
        </Tooltip>;
    }

    return (
        <>
            <Card key={product.id}
                  variant="outlined"
                  sx={{
                      borderRadius: 0,
                      border: 'none',
                      borderBottom: '1px solid rgba(0, 0, 0, 0.46)',
                  }}
            >
                <CardContent
                    sx={{
                        p: 1.5,
                        pb: '10px!important'
                    }}
                >
                    <Box
                        sx={{
                            display: 'grid',
                            width: '100%',
                            columnGap: 1,
                            rowGap: 1,
                            gridTemplateColumns: {
                                xs: '1fr 1fr 1fr 1fr 1fr',
                                md: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr'
                            },
                            gridTemplateRows: {
                                xs: 'auto',
                                md: 'auto'
                            },
                            gridTemplateAreas: {
                                xs: `
                            'code code code availability availability'
                            'title title title title title'
                            'price input image image add'
                            `,
                                md: `
                            'code title title title title availability price unit input image image add' 
                            `,
                            }
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                gridArea: 'code',
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <Typography color="textSecondary" sx={{fontSize: '0.875rem'}}> <Typography
                                component={'span'}
                                sx={{display: {md: 'none'}}}>Cod.</Typography> {product.code}</Typography>
                        </Box>

                        <Box
                            sx={{
                                width: '100%',
                                gridArea: 'availability',
                                display: 'flex',
                                justifyContent: {
                                    xs: 'flex-end',
                                    md: 'flex-start'
                                },
                                alignItems: 'center'
                            }}
                        >
                            {getDescriptionRoundCircle()}
                        </Box>

                        <Box
                            sx={{
                                width: '100%',
                                gridArea: 'title',
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <Typography sx={{fontSize: {md: '0.875rem'}}}>
                                {product.name}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                width: '100%',
                                gridArea: 'price',
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <Typography sx={{fontSize: '0.875rem'}}>{product.priceFormatted} CHF</Typography>
                        </Box>
                        <Box
                            sx={{
                                width: '100%',
                                gridArea: 'input',
                            }}
                        >
                            <TextField
                                type="number"
                                value={quantitySelected}
                                id="quantity"
                                onChange={(e) => handleChange(e.target.value)}
                                variant={'outlined'}
                                size={'small'}
                                sx={{
                                    width: '70px'
                                }}
                                InputProps={{
                                    inputProps: {min: 1}
                                }}
                            >
                            </TextField>
                        </Box>
                        <Box
                            sx={{
                                width: '100%',
                                gridArea: 'unit',
                                display: {
                                    xs: 'none',
                                    md: 'inline-flex'
                                },
                                alignItems: 'center'
                            }}
                        >
                            <Typography sx={{fontSize: '0.875rem'}}>{product.unitDescription}</Typography>
                        </Box>
                        <Box
                            sx={{
                                width: '100%',
                                gridArea: 'image',
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center'
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}
                            >
                                {product.image !== null ?
                                    <Tooltip title="Mostra immagine" placement="top">
                                        <IconButton color="primary" onClick={handleOpen}>
                                            <PhotoCamera size={'small'}/>
                                        </IconButton>
                                    </Tooltip>
                                    :
                                    ''}
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                width: '100%',
                                gridArea: 'add',
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center'
                            }}
                        >
                            <Tooltip title="Aggiungi al carrello" placement="top">
                                <IconButton color="primary" onClick={() => addToCart(product.id)}>
                                    <AddShoppingCartIcon size={'small'}/>
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>
                </CardContent>
            </Card>

            <SnackBarAlert isOpen={openSnackBar} handleClose={() => handleClose(setOpenSnackBar)} type={'success'}
                           title={warningText.titleSuccess}
                           duration={5000}
                           body={' Questo articolo è stato aggiunto correttamente al tuo ordine. Per gestire i tuoi articoli vai al carrello.'}/>

            <Modal
                open={open}
                onClose={handleCloseOpen}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={style}>
                    <Stack direction="row" alignItems="start" justifyContent='space-between'>
                        <Box>
                            <Typography color="textSecondary"
                                        sx={{fontSize: '0.875rem'}}> Cod. {product.code}
                            </Typography>
                            <Typography id="modal-title" variant="p">
                                {product.name}
                            </Typography>
                        </Box>

                        <IconButton color="secondary" onClick={handleCloseOpen}>
                            <CloseIcon size={'small'}/>
                        </IconButton>
                    </Stack>


                    <Box
                        sx={{
                            mt: 1,
                            "& img": {
                                width: "100%",
                                height: '100%',
                                maxHeight: '240px',
                                objectFit: 'contain'
                            }
                        }}
                    >
                        <img
                            src={product.image ? image : testImage}
                            alt={''}/>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}