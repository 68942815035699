import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Step,
    Stepper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import CardDetailCart from "../card/CardDetail";
import CustomStepperLabel from "../components/Stepper";
import CardCart from "../card/CardCart";
import ApiService from "../services/ApiService";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import {getUser} from "../services/AuthService";
import SnackBarAlert from "../components/SnackBarAlert";
import {label, roles, routes, warningText} from "../utils/ReusableText";
import {handleClose} from "../utils/ReusableFunction";
import BasicDialog from "../components/BasicDialog";

function getSteps() {
    return ['Informazioni', 'Prodotti', 'Conferma'];
}

export default function OrderCart() {
    const user = getUser();
    const navigate = useNavigate();
    const path = useLocation();
    const id = path.pathname.split('/')[2];
    const edit = path.pathname.split('/')[3];
    const [order, setOrder] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [activeStep, setActiveStep] = React.useState(0);
    const [budgetSelected, setBudgetSelected] = useState(undefined);
    const [openModalStock, setOpenModalStock] = useState(false);
    const [alertBudget, setAlertBudget] = useState(false);
    const [productsNotAvailable, setProductsNotAvailable] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [modalConfirm, setModalConfirm] = useState(false);
    const [lockApiCall, setLockApiCall] = useState(false)
    const currentUser = getUser();
    const [manager] = useState(currentUser.role === roles.manager || currentUser.role === roles.secretary);
    const [confirmBack, setConfirmBack] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [errorAlert, setErrorAlert] = useState(false);

    useEffect(() => {
        setActiveStep(2);
        if (edit === 'edit') {
            setIsEdit(true);
        } else {
            setIsEdit(false);
        }

        ApiService.getOrderById(id).then(async response => {
            if (manager || response.user.id === currentUser.id) {
                setOrder(response);
                if (response.budget?.amount === null) {
                    setBudgetSelected(label.noLimitBudget);
                } else {
                    setBudgetSelected((response.budget?.availableAmount / 100).toFixed(2) + label.chfWithSpace);
                }
            } else {
                navigate('error-page')
            }
        });
        // eslint-disable-next-line
    }, [id, edit])

    const steps = getSteps();

    const handleCloseBackdrop = (event, reason) => {
        if (reason && reason === "backdropClick") {
            return;
        }
        setOpenModalStock();
    };

    function submitOrder() {
        if(!lockApiCall){

            setLockApiCall(true)
            if (order.budget.availableAmount) {
                if (order.total > order.budget.availableAmount) {
                    setAlertBudget(true);
                }
            }

            ApiService.submitOrder(order.id).then(response => {
                if (response) {
                    setOrder(response);
                    setModalConfirm(false);
                    navigateToOrderDetails();
                    setLockApiCall(false)
                }
            }).catch((e) => {
                setProductsNotAvailable([]);
                // eslint-disable-next-line array-callback-return
                e.body?.notAvailableItems.map((item) => {
                    setProductsNotAvailable(productsNotAvailable => [...productsNotAvailable, item.product]);
                })
                setOpenModalStock(true);
                setModalConfirm(false);
                setLockApiCall(false)
            });
        }

    }

    function deleteOrder() {
        ApiService.deleteOrderByOrderId(id).then(response => {
            navigate('/');
        }).catch((e) => {
            if (e.body?.code === 2) {
                setOpenDialog(false);
                setOpenSnackBar(true);
            } else {
                setErrorAlert(true);
            }
        });
    }

    function navigateToOrderDetails() {
        let role = '';
        if (user) {
            role = user.role;
            if (role === roles.manager) {
                navigate('/' + routes.orderDetails + '/' + order.id);
            } else {
                navigate('/' + routes.orderUserDetails + '/' + order.id)
            }
        }
    }

    const alertDeleteItemFromOrder = (isDeleted) => {
        if (isDeleted) {
            setOpenSnackBar(true);
        }
    }

    const changeOrderInitial = (orderFinal) => {
        setOrder(orderFinal);
    }

    return (
        <>
            <Grid container sx={{mb: 4}} justifyContent="space-between">
                <Grid item xs={'auto'}>
                    <Button variant="flip"
                            onClick={() => {
                                navigate('/' + routes.orderProducts + '/' + id);
                            }}>
                        Indietro
                    </Button>
                </Grid>
                <Grid item xs={'auto'}>
                    <Button variant="outlined" color={'secondary'} onClick={() => {
                        if (isEdit) {
                            setConfirmBack(true)
                        } else {
                            navigate('/');
                        }
                    }}>
                        Annulla
                    </Button>
                </Grid>
            </Grid>

            <Grid container sx={{marginTop: 2}}>
                <Grid item xs={6}>
                    <Typography variant="h5" component="h2"
                                sx={{fontWeight: '500'}}>{isEdit ? 'Modifica ordine' : 'Nuovo Ordine'}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Box component={'div'} sx={{'display': 'flex', 'justifyContent': 'flex-end'}}>
                        <Button variant="outlined" color="primary"
                                sx={{px: {xs: 2, md: 5}}}
                                onClick={() => {
                                    navigateToOrderDetails();
                                }}>
                            Salva
                        </Button>
                    </Box>
                </Grid>
            </Grid>

            <Grid container sx={{mt: 3}} justifyContent={'space-between'}>
                <Grid item xs={12} lg={6}>
                    <Stepper activeStep={activeStep}>
                        {steps.map((label) => (
                            <Step key={label}>
                                <CustomStepperLabel label={label}/>
                            </Step>
                        ))}
                    </Stepper>
                </Grid>
                {order && order.budget ?
                    <Grid item xs={12} lg={4}>
                        <Box xs={6}>
                            <Button variant="contained" color="primary" fullWidth size={'large'} sx={{mt: 2}}
                                    disabled={order ? order.items?.length === 0 : false}
                                    onClick={() => {
                                        setModalConfirm(true);
                                    }}>
                                Conferma ordine
                            </Button>
                        </Box>
                        <Box component={'div'} sx={{mb: 3, mt: 2}}>
                            <CardDetailCart show={true} budget={budgetSelected} total={order.totalFormatted}
                                            user={order.user}
                                            period={order.budget.schoolYear.name} location={order.deliveryLocation.name}
                                            budgetName={order.budget.name} notes={order.notes}
                                            alertBudget={alertBudget} modifiedAt={order.updatededAtFormatted}/>
                        </Box>
                    </Grid>
                    :
                    ""
                }
            </Grid>

            <Box sx={{mt: 3}}>
                <Box
                    sx={{
                        display: {
                            xs: 'none',
                            md: 'grid'
                        },
                        width: '100%',
                        columnGap: 1,
                        rowGap: 1,
                        p: 1.5,
                        gridTemplateColumns: {
                            xs: '1fr 1fr 1fr 1fr 1fr',
                            md: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr'
                        },
                        gridTemplateRows: {
                            xs: 'auto',
                            md: 'auto'
                        },
                        gridTemplateAreas: {
                            xs: `
                    'code code code availability availability'
                    'title title title title title'
                    'price input total total delete'
                    `,
                            md: `
                    'code title title title title availability price unit input total total delete' 
                    `,
                        },
                        borderBottom: '1px solid rgba(0, 0, 0, 0.46)'
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            gridArea: 'code',
                        }}
                    >
                        <Typography color="textSecondary" sx={{fontSize: '0.75rem'}}>Codice</Typography>
                    </Box>

                    <Box
                        sx={{
                            width: '100%',
                            gridArea: 'availability',
                        }}
                    >
                        <Typography color="textSecondary" sx={{fontSize: '0.75rem'}}>Disponibilità</Typography>
                    </Box>

                    <Box
                        sx={{
                            width: '100%',
                            gridArea: 'title',
                        }}
                    >
                        <Typography color="textSecondary" sx={{fontSize: '0.75rem'}}>Nome</Typography>
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            gridArea: 'price'
                        }}
                    >
                        <Typography color="textSecondary" sx={{fontSize: '0.75rem'}}>Costo</Typography>
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            gridArea: 'unit'
                        }}
                    >
                        <Typography color="textSecondary" sx={{fontSize: '0.75rem'}}>Des. unità</Typography>
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            gridArea: 'input',
                        }}
                    >
                        <Typography color="textSecondary" sx={{fontSize: '0.75rem'}}>Quantità</Typography>
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            gridArea: 'total',
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <Typography color="textSecondary" sx={{fontSize: '0.75rem'}}>Totale</Typography>
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            gridArea: 'delete'
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        "& > div:nth-child(even)": {
                            background: '#ffffff',
                        }
                    }}
                >
                    {order && order.items?.length > 0 ?
                        order.items.map((item) =>
                            <CardCart item={item} orderInitial={order} changeOrderInitial={changeOrderInitial}
                                      alertDeleteItemFromOrder={alertDeleteItemFromOrder}/>
                        )
                        :
                        ""
                    }
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        p: 1.5,
                        borderBottom: '1px solid rgba(0, 0, 0, 0.46)'
                    }}
                >
                    <Typography fontWeight={'600'}>Totale: {order.totalFormatted} CHF</Typography>
                </Box>
            </Box>

            {openModalStock ?
                <Dialog
                    open={openModalStock}
                    onClose={handleCloseBackdrop}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth='lg'
                >
                    <DialogTitle id="alert-dialog-title">
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <ReportProblemOutlinedIcon sx={{mr: 1}}/> Attenzione
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Typography>I seguenti prodotti non sono più disponibili. Per inoltrare subito l'ordine
                                rimuoverli dal carrello.</Typography>
                            <TableContainer sx={{my: 2}}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell size='small'>Codice</TableCell>
                                            <TableCell size='small'>Nome</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {productsNotAvailable.length > 0 ?
                                            productsNotAvailable.map((product) => (
                                                <TableRow>
                                                    <TableCell component="th" scope="row" sx={{verticalAlign: 'top'}}>
                                                        {product.code}
                                                    </TableCell>
                                                    <TableCell>{product.name}</TableCell>
                                                </TableRow>
                                            ))
                                            :
                                            ''
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <Typography align={'center'} sx={{fontWeight: '600'}}>I prodotti saranno nuovamente
                                disponibili entro due settimane</Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => handleCloseBackdrop()} variant={'contained'} color={'primary'}>
                            Ok, ho capito
                        </Button>
                    </DialogActions>
                </Dialog>
                :
                ''}

            <SnackBarAlert isOpen={openSnackBar} handleClose={() => handleClose(setOpenSnackBar())} type={'success'}
                           title={warningText.titleSuccess}
                           duration={2000}
                           body={'L\'articolo è stato rimosso correttamente dal tuo ordine.'}/>

            <BasicDialog isOpen={modalConfirm} handleClose={() => setModalConfirm(false)}
                         title={warningText.titleAttention}
                         body={warningText.submitConfirm} closeLabel={warningText.noButton}
                         successFunction={submitOrder}

                         successLabel={warningText.submitButton}/>

            <BasicDialog isOpen={confirmBack} handleClose={() => {
                handleClose(setConfirmBack);
                navigate("/")
            }}
                         title={warningText.titleAttention}
                         body={warningText.confirmBack} closeLabel={warningText.exitButton}
                         successLabel={warningText.deleteButton} deleteFunction={deleteOrder}/>

            <BasicDialog isOpen={openDialog} handleClose={() => handleClose(setOpenDialog)} deleteFunction={deleteOrder}
                         title={warningText.titleAttention}
                         body={warningText.confirmDelete} closeLabel={warningText.noButton}
                         successLabel={warningText.deleteButton}/>

            <BasicDialog isOpen={errorAlert} handleClose={() => handleClose(setErrorAlert)} title={warningText.titleError}
                         body={warningText.somethingWentWrong} closeLabel={warningText.okButton} successLabel={undefined}/>

            <SnackBarAlert isOpen={openSnackBar} handleClose={() => handleClose(setOpenSnackBar)} type={'error'}
                           title={warningText.titleError}
                           duration={10000}
                           body={warningText.somethingWentWrong}/>
        </>
    );
}