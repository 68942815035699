import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import {ListItemIcon, Tooltip} from "@mui/material";
import {useNavigate} from "react-router-dom";

export default function ListItemButtonsElm({open, label, icon, route}) {
    const navigate = useNavigate();
    const storedRoute = localStorage.getItem('selectedRoute');
    const isSelected = route === storedRoute;

    function handleNavigate(selected) {
        navigate(`/${selected}`)
        localStorage.setItem('selectedRoute', selected);
    }

    return (
        <ListItemButton onClick={() => {
            handleNavigate(route)
        }} selected={isSelected}
                        sx={{
                            px: open ? 2 : 1.75,
                            py: 0.5,
                            margin: '0.5rem',
                            width: 'calc(100% - 1rem)',
                            height: '45px',
                            "&.Mui-selected": {
                                backgroundColor: "rgba(255,255,255,0.1)",
                                borderRadius: '10px',
                                "&:hover": {
                                    backgroundColor: "rgba(255,255,255,.04)",
                                }
                            },
                        }}
        >
            <ListItemIcon
                sx={{
                    display: open ? 'none' : 'inline-flex',
                    color: 'rgba(255, 255, 255, 0.75)',
                    minWidth: '42px',
                }}
            >
                <Tooltip title={label} placement="right">
                    {icon}
                </Tooltip>
            </ListItemIcon>
            <ListItemText
                primary={label}
                sx={{
                    display: {
                        xs: 'inline-block',
                        md: open ? 'inline-block' : 'none',
                    },
                    color: 'white',
                    whiteSpace: 'pre-wrap'
                }}
            />
        </ListItemButton>
    )
}