import {Box, Button, Grid, MenuItem, Step, Stepper, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import ApiService from "../services/ApiService";
import CustomStepperLabel from "../components/Stepper";
import CardDetailCart from "../card/CardDetail";
import {getUser} from "../services/AuthService";
import BasicDialog from "../components/BasicDialog";
import SelectWithSearch from "../components/SelectWithSearch";
import {label, roles, routes, warningText} from "../utils/ReusableText";
import {handleClose} from "../utils/ReusableFunction";
import {voidBudget, voidUser} from "../utils/ReusableObjects";
import SnackBarAlert from "../components/SnackBarAlert";

function getSteps() {
    return ['Informazioni', 'Prodotti', 'Conferma'];
}

export default function OrderInfo() {
    const navigate = useNavigate();
    const path = useLocation();
    const id = path.pathname.split('/')[2];
    const currentUser = getUser();
    const [manager] = useState(currentUser.role === roles.manager || currentUser.role === roles.secretary);
    const steps = getSteps();
    const [activeStep, setActiveStep] = useState(0);
    const [user, setUser] = useState(voidUser);
    const [budget, setBudget] = useState(voidBudget);
    const [deliveryLocation, setDeliveryLocation] = useState('');
    const [note, setNote] = useState(undefined);
    const [isEdit, setIsEdit] = useState(false);
    const [locationEditable, setLocationEditable] = useState(false);
    const [listOfBudgets, setListOfBudgets] = useState([]);
    const [listOfLocations, setListOfLocations] = useState(undefined);
    const [listOfUsers, setListOfUsers] = useState([]);
    const [budgetSelected, setBudgetSelected] = useState(undefined);
    const [errorAlert, setErrorAlert] = useState(false);
    const [entityAlert, setEntityAlert] = useState(false);
    const [bodyEntityAlert, setBodyEntityAlert] = useState(false);
    const [alreadyClicked, setAlreadyClicked] = useState(false);
    const [confirmBack, setConfirmBack] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState(false);

    useEffect(() => {
        setActiveStep(0);

        if (!manager) {
            setUser(currentUser.id);
            fetchBudgets(currentUser.id);
        } else {
            ApiService.getUsers().then(response => {
                if (response) {
                    setListOfUsers(response);
                }
            });
        }

        if (id) {
            setIsEdit(true);

            ApiService.getOrderById(id).then(response => {
                if (manager || response.user.id === currentUser.id) {
                    fetchLocations(response.deliveryLocation?.id);
                    fetchBudgets(response.user?.id !== currentUser.id ? response.user?.id : currentUser.id, response.budget);
                    checkEntity(response);

                    setNote(response.notes);
                    if (response.budget?.availableAmount === null) {
                        setBudgetSelected(label.noLimitBudget);
                    } else {
                        setBudgetSelected((response.budget?.availableAmount / 100).toFixed(2) + label.chfWithSpace);
                    }
                } else {
                    navigate('error-page')
                }
            })
        } else {
            setIsEdit(false);
        }
        // eslint-disable-next-line
    }, [id, manager]);

    function deleteOrder() {
        ApiService.deleteOrderByOrderId(id).then(response => {
            navigate('/');
        }).catch((e) => {
            if (e.body?.code === 2) {
                setOpenDialog(false);
                setOpenSnackBar(true);
            } else {
                setErrorAlert(true);
            }
        });
    }

    function checkEntity(order) {
        const currDate = new Date()

        if (!order.user?.disabled) {
            setUser(order.user)
        } else {
            setBodyEntityAlert("L'utente selezionato precedentemente per l'ordine è stato disabilitato. Selezionare un altro utente.");
            setEntityAlert(true);
        }

        if (order.budget?.enabled && new Date(order.budget?.schoolYear?.end) >= currDate && order.budget?.schoolYear?.enabled) {
            setBudget(order.budget)
            if (order.deliveryLocation?.enabled) {
                setDeliveryLocation(order.deliveryLocation?.id)
            } else {
                setBodyEntityAlert("La sede selezionata precedentemente per l'ordine è stata archiviata. Selezionare un'altra sede.");
                setEntityAlert(true);
            }
        } else {
            setBodyEntityAlert("La funzione selezionata precedentemente per l'ordine è stata archiviata oppure non ha un periodo valido al suo interno. Selezionare un'altra funzione.");
            setEntityAlert(true);
        }
    }

    function saveAndProceed() {
        setAlreadyClicked(true);

        if (manager) {
            if (id) {
                const body = {
                    id: Number(id),
                    notes: note,
                    budgetId: budget.id,
                    deliveryLocationId: deliveryLocation,
                    userId: user.id
                }
                ApiService.updateOrderThirdParties(body).then(response => {
                    navigate('/' + routes.orderProducts + '/' + id);
                }).catch((e) => {
                    setAlreadyClicked(false);
                    setErrorAlert(true);
                });
            } else {
                const body = {
                    notes: note, budgetId: budget.id, deliveryLocationId: deliveryLocation, userId: user.id
                }
                ApiService.createOrderThirdParties(body).then(response => {
                    navigate('/' + routes.orderProducts + '/' + response.id);

                }).catch((e) => {
                    setAlreadyClicked(false);
                    setErrorAlert(true);
                });
            }
        } else {
            if (id) {
                const body = {
                    id: Number(id), notes: note, budgetId: budget.id, deliveryLocationId: deliveryLocation
                }
                ApiService.updateOrder(body).then(response => {
                    navigate('/' + routes.orderProducts + '/' + id);
                }).catch((e) => {
                    setAlreadyClicked(false);
                    setErrorAlert(true);
                });
            } else {
                const body = {
                    notes: note, budgetId: budget.id, deliveryLocationId: deliveryLocation
                }
                ApiService.createOrder(body).then(response => {
                    navigate('/' + routes.orderProducts + '/' + response.id);
                }).catch((e) => {
                    setAlreadyClicked(false);
                    setErrorAlert(true);
                });
            }
        }
    }

    function selectBudget(value) {
        setBudget(value);
        const [budget] = listOfBudgets.filter(budget => budget.id === value.id);

        fetchLocations(budget.location?.id);

        if (budget.availableAmount === null) {
            setBudgetSelected(label.noLimitBudget);
        } else {
            setBudgetSelected((budget.availableAmount / 100) + label.chfWithSpace);
        }
    }

    function fetchLocations(locationBudget) {
        setLocationEditable(true);

        ApiService.getLocations().then(response => {
            if (response.length > 0) {
                setListOfLocations(response);
                if (locationBudget) {
                    setDeliveryLocation(locationBudget);
                    console.log(deliveryLocation)
                }
            }
        });

    }

    function selectUser(value) {
        setUser(value);
        setBudget(voidBudget);
        setDeliveryLocation('');
        fetchBudgets(value.id);
    }

    function fetchBudgets(userId, budgetSelected?) {
        const currDate = new Date()
        ApiService.getBudgetsByUserIdForOrders(userId).then(response => {
            if (response.budgets.length > 0) {
                setListOfBudgets(response.budgets);
                if (budgetSelected?.enabled && new Date(budgetSelected?.schoolYear?.end) >= currDate && budgetSelected?.schoolYear?.enabled) {
                    setBudget(budgetSelected)
                } else {
                    setBudget(voidBudget);
                }
            } else {
                setListOfBudgets([]);
            }
        });
    }

    function checkMandatoryOrderFields() {
        return manager ? !(user && budget && (deliveryLocation !== '')) : !(budget && (deliveryLocation !== ''));
    }

    return (<div>

        <Grid container sx={{mb: 4}}>
            <Grid item xs={12}>
                <Grid container sx={{mb: 4}} justifyContent="space-between">
                    <Grid item xs={'auto'}>
                        <Button variant="flip"
                                onClick={() => {
                                    navigate('/');
                                }}>
                            Indietro
                        </Button>
                    </Grid>
                    <Grid item xs={'auto'}>
                        <Button variant="outlined" color={'secondary'}
                                onClick={() => {
                                    if (isEdit) {
                                        setConfirmBack(true)
                                    } else {
                                        navigate('/');
                                    }
                                }}>
                            Annulla
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

        <Grid container sx={{marginTop: 2}}>
            <Grid item xs={12}>
                <Typography variant="h5" component="h2"
                            sx={{fontWeight: '500'}}>{isEdit ? 'Modifica ordine' : 'Nuovo Ordine'}</Typography>
            </Grid>
        </Grid>

        <Grid container sx={{marginTop: 3}}>
            <Grid item xs={12} sm={6}>
                <Stepper activeStep={activeStep}>
                    {steps.map((label) => (<Step key={label}>
                        <CustomStepperLabel label={label}/>
                    </Step>))}
                </Stepper>
            </Grid>
        </Grid>


        <Grid container sx={{mt: {xs: 3, md: 4}}} columnSpacing={{xs: 3, md: 5}} direction={{md: "row-reverse"}}
              justifyContent="space-between">
            <Grid item xs={12} md={5} lg={4}>
                <Box component={'div'}
                     sx={{'display': 'flex', 'flexDirection': {xs: 'column', md: 'column-reverse'}, mb: 3}}>
                    <Box component={'div'} sx={{mb: 1}}>
                        <CardDetailCart show={!!(isEdit || budgetSelected)} budget={budgetSelected}/>
                    </Box>
                    <Box sx={{mb: 1}}>
                        <Button variant="contained" color="primary" size={'large'} fullWidth
                                disabled={checkMandatoryOrderFields() || alreadyClicked}
                                onClick={() => {
                                    saveAndProceed()
                                }}>
                            Continua
                        </Button>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} md={7} lg={4}>
                <Box
                    component={'form'}
                    noValidate
                    autoComplete="off"
                    sx={{
                        '& .MuiTextField-root': {mb: 2},
                    }}
                >
                    {manager ?
                        <SelectWithSearch options={listOfUsers} value={user} onChange={selectUser} required={true}
                                          multiple={false} label={"Seleziona utente"} id={"userSelect"}
                                          entity={'user'}/> : <></>}


                    <SelectWithSearch options={listOfBudgets} value={budget} onChange={selectBudget} required={true}
                                      multiple={false} label={"Seleziona funzione"} id={"budgetSelect"}
                                      entity={'budgetWithLocation'}/>

                    <TextField
                        disabled={!locationEditable}
                        select
                        id="locationIdSelect"
                        label={'Seleziona sede fornitura'}
                        required
                        fullWidth
                        variant="standard"
                        value={deliveryLocation || ''}
                        onChange={(e) => {
                            setDeliveryLocation(e.target.value);
                            console.log(e.target.value)
                        }}
                    >
                        {listOfLocations && listOfLocations.length > 0 ? listOfLocations.map((location) => (
                            <MenuItem key={location.id} value={location.id}>{location.name}</MenuItem>)) : <></>}
                    </TextField>

                    <TextField
                        id="note"
                        label="Note"
                        defaultValue={note}
                        value={note}
                        variant="standard"
                        onChange={e => setNote(e.target.value)}
                        InputLabelProps={{shrink: true}}
                        multiline
                        rows={5}
                        fullWidth
                    />
                </Box>
            </Grid>

        </Grid>

        <BasicDialog isOpen={errorAlert} handleClose={() => handleClose(setErrorAlert)} title={warningText.titleError}
                     body={warningText.somethingWentWrong} closeLabel={warningText.okButton} successLabel={undefined}/>

        <BasicDialog isOpen={entityAlert} handleClose={() => handleClose(setEntityAlert)}
                     title={warningText.titleAttention}
                     body={bodyEntityAlert} closeLabel={warningText.okButton} successLabel={undefined}/>

        <BasicDialog isOpen={confirmBack} handleClose={() => {
            handleClose(setConfirmBack);
            navigate("/")
        }}
                     title={warningText.titleAttention}
                     body={warningText.confirmBack} closeLabel={warningText.exitButton}
                     successLabel={warningText.deleteButton} deleteFunction={deleteOrder}/>

        <BasicDialog isOpen={openDialog} handleClose={() => handleClose(setOpenDialog)} deleteFunction={deleteOrder}
                     title={warningText.titleAttention}
                     body={warningText.confirmDelete} closeLabel={warningText.noButton}
                     successLabel={warningText.deleteButton}/>

        <SnackBarAlert isOpen={openSnackBar} handleClose={() => handleClose(setOpenSnackBar)} type={'error'}
                       title={warningText.titleError}
                       duration={10000}
                       body={warningText.somethingWentWrong}/>

    </div>);
}