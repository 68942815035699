import {Box, Button, Grid, MenuItem, Paper, Stack, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import ApiService from "../services/ApiService";
import ClearIcon from '@mui/icons-material/Clear';
import BasicDialog from "../components/BasicDialog";
import SnackBarAlert from "../components/SnackBarAlert";
import {NumericFormat} from "react-number-format";
import {routes, warningText} from "../utils/ReusableText";
import {handleClose} from "../utils/ReusableFunction";

export default function EditProduct() {
    const navigate = useNavigate();
    const path = useLocation();
    const id = path.pathname.split('/')[2];
    const [name, setName] = useState(undefined);
    const [price, setPrice] = useState(undefined);
    const [code, setCode] = useState(undefined);
    const [catalog, setCatalog] = useState(undefined);
    const [category, setCategory] = useState(undefined);
    const [quantity, setQuantity] = useState(undefined);
    const [unitDescription, setUnitDescription] = useState(undefined);
    const [enabled, setEnabled] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const [listOfCategories, setListOfCategories] = useState(undefined);
    const [listOfCatalogs, setListOfCatalogs] = useState(undefined);
    const [openDialog, setOpenDialog] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [errorAlert, setErrorAlert] = useState(false);
    const [successAlert, setSuccessAlert] = useState(false);
    const [alreadyClicked, setAlreadyClicked] = useState(false);
    const [image, setImage] = useState(undefined);
    const [imageParsed, setImageParsed] = useState(undefined)

    useEffect(() => {
        ApiService.getCatalogs().then(response => {
            setListOfCatalogs(response);
        })

        ApiService.getCategories().then(response => {
            setListOfCategories(response);
        })

        if (id) {
            setIsEdit(true);

            fetchProductById(id);
        } else {
            setIsEdit(false);
        }
        // eslint-disable-next-line
    }, [id]);

    function checkMandatoryProductFields() {
        return !(name && code && price && catalog && quantity);
    }

    function saveProduct() {
        setAlreadyClicked(true);

        const id = path.pathname.split('/')[2];
        if (id) {
            const body = {
                id: id,
                name: name,
                code: code,
                price: (Number(price) * 100),
                catalogId: catalog,
                minimumStockQuantity: Number(quantity),
                categoryId: category !== '' ? category : undefined,
                unitDescription: unitDescription,
                enabled: enabled
            }
            ApiService.updateProducts(body).then(response => {
                navigate('/' + routes.products)
            }).catch((e) => {
                setAlreadyClicked(false);
                setErrorAlert(true);
            });
        } else {
            const body = {
                name: name,
                price: (Number(price) * 100),
                code: code,
                catalogId: catalog,
                categoryId: category !== '' ? category : undefined,
                minimumStockQuantity: Number(quantity),
                unitDescription: unitDescription
            }
            ApiService.createProduct(body).then(response => {
                if (image) {
                    saveImage(image, response.id).then(res => {
                        navigate('/' + routes.products);
                    });
                } else {
                    navigate('/' + routes.products);
                }
            }).catch((e) => {
                setAlreadyClicked(false);
                setErrorAlert(true);
            });
        }
    }

    async function saveImage(value, idCreate?) {
        const body = new FormData();
        body.append('file', value);

        await ApiService.uploadImage(idCreate ? idCreate : id, body).then(response => {
            fetchProductById(id);
            setSuccessAlert(true);
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    function fetchProductById(id) {
        ApiService.getProductById(id).then(async response => {
            setName(response.name);
            setPrice(Number(response.price) / 100);
            setCode(response.code);
            setCatalog(response.catalog?.id);
            if (response.category) {
                setCategory(response.category.id);
            }
            setQuantity(response.minimumStockQuantity);
            if (response.unitDescription) {
                setUnitDescription(response.unitDescription);
            }
            setEnabled(response.enabled)

            if (response.image) {
                const imageSplitted = response.image.split('/');
                const productImage = imageSplitted[imageSplitted.length - 1];

                const res = await ApiService.getImage(productImage);
                const imageBlob = await res.blob();
                parsingImage(imageBlob);
            }
        })
    }

    const confirmDelete = () => {
        setOpenDialog(true);
    };

    function deleteImage() {
        ApiService.deleteImage(id).then(response => {
            setImage(undefined);
            setImageParsed(undefined);
            setOpenDialog(false);
        }).catch((e) => {
            setAlreadyClicked(false);
            setErrorAlert(true);
        });
    }

    function parsingImage(blob) {
        setImage(blob);
        const imageObjectURL = URL.createObjectURL(blob);
        setImageParsed(imageObjectURL);
    }

    function clearImage() {
        if (imageParsed) {
            URL.revokeObjectURL(imageParsed);
        }
        setImageParsed(undefined);
        setImage(undefined);
    }

    return (<>
        <Grid container sx={{marginTop: 2}}>
            <Grid item xs={12}>
                <Typography variant="h5" component="h2"
                            sx={{fontWeight: '500'}}>{isEdit ? 'Modifica Prodotto' : 'Nuovo Prodotto'}</Typography>
            </Grid>
        </Grid>

        <Box component={'form'}>
            <Grid container sx={{mt: 4}} columnSpacing={2.5}>
                <Grid item xs={12} md={8} lg={4}>
                    <Stack spacing={1.5} sx={{mb: {xs: 3, md: 0}}}>
                        <TextField id="code" label="Codice prodotto" value={code} defaultValue={code}
                                   variant="standard"
                                   onChange={e => setCode(e.target.value)}
                                   InputLabelProps={isEdit ? {shrink: true} : ''} required fullWidth/>
                        <TextField id="name" label="Nome prodotto" value={name} defaultValue={name}
                                   variant="standard"
                                   onChange={e => setName(e.target.value)}
                                   InputLabelProps={isEdit ? {shrink: true} : ''} required fullWidth/>
                        <NumericFormat decimalSeparator="."
                                       fixedDecimalScale={true}
                                       allowNegative={false}
                                       decimalScale={2}
                                       customInput={TextField} id="price"
                                       label="Costo unitario (CHF)"
                                       required
                                       defaultValue={price}
                                       value={price}
                                       variant="standard"
                                       onChange={e => setPrice(e.target.value)}
                                       fullWidth/>
                        <TextField
                            select
                            id="catalogIdSelect"
                            label={'Seleziona catalogo'}
                            required
                            fullWidth
                            variant="standard"
                            value={catalog || ''}
                            onChange={(e) => setCatalog(e.target.value)}
                        >
                            {listOfCatalogs && listOfCatalogs.length > 0 ? listOfCatalogs.map((catalog) => (
                                <MenuItem key={catalog.id} value={catalog.id}>{catalog.name}</MenuItem>)) : ''}
                        </TextField>

                        <TextField
                            select
                            id="categoryIdSelect"
                            label={'Seleziona categoria'}
                            fullWidth
                            variant="standard"
                            value={category || ''}
                            onChange={(e) => setCategory(e.target.value)}
                        >
                            <MenuItem value={''}>Nessuna selezione</MenuItem>
                            {listOfCategories && listOfCategories.length > 0 ? listOfCategories.map((category) => (
                                <MenuItem key={category.id} value={category.id}>{category.name}</MenuItem>)) : ''}
                        </TextField>

                        <TextField id="unitDescription" label="Descrizione unità" value={unitDescription}
                                   defaultValue={unitDescription}
                                   variant="standard" onChange={e => setUnitDescription(e.target.value)}
                                   InputLabelProps={isEdit ? {shrink: true} : ''}
                        />
                        <NumericFormat
                            allowNegative={false}
                            decimalScale={0}
                            customInput={TextField} id="quantity"
                            label="Punto di riordino"
                            defaultValue={quantity}
                            value={quantity}
                            variant="standard"
                            onChange={e => setQuantity(e.target.value)}
                            fullWidth required/>
                    </Stack>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                    <input
                        accept="image/*"
                        style={{display: 'none'}}
                        id="raised-button-file"
                        type="file"
                        onChange={(e) => isEdit ? saveImage(e.target.files[0]) : parsingImage(e.target.files[0])}
                    />
                    <label htmlFor="raised-button-file">
                        <Button component="span" variant="contained" color="primary" sx={{mb: 1.5}} fullWidth>
                            Seleziona foto
                        </Button>
                    </label>
                    {image ?
                        <>
                            <Paper
                                sx={{
                                    p: 1,
                                    "& img": {
                                        width: "100%",
                                        height: 'auto'
                                    }
                                }}
                            >
                                <img src={imageParsed} alt={""}/>
                            </Paper>
                            <Box
                                sx={{
                                    mt: 1.5,
                                }}
                            >
                                <Button variant="outlined" color='secondary'
                                        onClick={() => isEdit ? confirmDelete() : clearImage()}
                                        startIcon={<ClearIcon/>}>
                                    Elimina foto
                                </Button>
                            </Box>
                        </>

                        :
                        ''}
                </Grid>

            </Grid>
        </Box>

        <Grid container sx={{mt: 3}} columnSpacing={2.5}>
            <Grid item xs={12} md={8} lg={4}>
                <Grid container justifyContent={'space-between'}>
                    <Grid item>
                        <Button variant="flip" onClick={() => {
                            navigate('/' + routes.products)
                        }}>
                            Cancella
                        </Button>

                    </Grid>
                    <Grid item>
                        {isEdit ?
                            <Button variant="contained" color="primary"
                                    disabled={checkMandatoryProductFields() || alreadyClicked}
                                    onClick={() => {
                                        saveProduct()
                                    }}>Salva</Button>
                            :
                            <Button variant="contained" color="primary"
                                    disabled={checkMandatoryProductFields() || alreadyClicked}
                                    onClick={() => {
                                        saveProduct()
                                    }}>Crea</Button>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

        <BasicDialog isOpen={errorAlert} handleClose={() => handleClose(setErrorAlert)} title={warningText.titleError}
                     body={warningText.somethingWentWrong} closeLabel={warningText.okButton} successLabel={undefined}/>

        <BasicDialog isOpen={openDialog} handleClose={() => handleClose(setOpenDialog)} deleteFunction={deleteImage}
                     title={warningText.titleAttention}
                     body={warningText.confirmDelete} closeLabel={warningText.noButton}
                     successLabel={warningText.deleteButton}/>

        <SnackBarAlert isOpen={openSnackBar} handleClose={() => handleClose(setOpenSnackBar)} type={'warning'}
                       title={warningText.titleAttention}
                       duration={10000}
                       body={warningText.errorDelete}/>

        <SnackBarAlert isOpen={successAlert} handleClose={() => handleClose(setSuccessAlert)} type={'success'}
                       title={warningText.titleSuccess}
                       duration={10000}
                       body={"L'immagine inserita è stata caricata con successo."}/>

    </>);
}