import React, {useEffect, useState} from 'react';
import {Button, Checkbox, FormControlLabel, Grid, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import AgGrid from "./AgGrid";
import ApiService from "../services/ApiService";
import {routes} from "../utils/ReusableText";
import {dataCoupons} from "../utils/agGridDefs";

export default function Coupons() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [enabled, setEnabled] = useState(false);

    useEffect(() => {
        setLoading(true);

        getCoupons();
        // eslint-disable-next-line
    }, [enabled]);

    function getCoupons() {
        ApiService.getCoupons(enabled).then(response => {
            setLoading(false);

            dataCoupons.rowData = response;
        });
    }

    return (<>
        <Grid container sx={{marginTop: 2}} justifyContent={'space-between'}>
            <Grid item xs="auto">
                <Typography variant="h5" component="h2" sx={{fontWeight: '500'}}>Buoni acquisto</Typography>
            </Grid>
            <Grid item xs="auto">
                <Button variant="contained" color="primary" onClick={() => navigate('/' + routes.editCoupon)}>
                    Crea buono aquisto
                </Button>
            </Grid>
        </Grid>
        <Grid container>
            <Grid item xs="auto">
                <FormControlLabel control={<Checkbox checked={enabled} onChange={() => {
                    setEnabled(!enabled);
                }}/>} label="Archiviati"/>
            </Grid>
        </Grid>

        <AgGrid children={loading ? [] : dataCoupons} redirect={"/" + routes.couponDetails}/>
    </>);
}