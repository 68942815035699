import React from "react";
import {italianEnabled, italianStatus} from "./ReusableText";

export function statusFormatter(params) {
    const status = params.value;
    let statusFormatted;
    switch (status) {
        case italianStatus.inProgress:
            statusFormatted = <span>{status}</span>;
            break;
        case italianStatus.submitted:
            statusFormatted = <span style={{color: '#FF9800'}}>{status}</span>;
            break;
        case italianStatus.approved:
            statusFormatted = <span style={{color: '#4CAF50'}}>{status}</span>;
            break;
        case italianStatus.processed:
            statusFormatted = <span style={{color: '#4CAF50'}}>{status}</span>;
            break;
        case italianStatus.aborted:
            statusFormatted = <span style={{color: '#f44336'}}>{status}</span>;
            break;
        default:
            console.log('Errore nel caricamento dello status ordine')
    }
    return statusFormatted;
}

export function colorFormatter(params) {
    return <span style={{color: '#f44336'}}>{params.value}</span>;
}

export function enabledFormatter(params) {
    const enabled = params.value;

    let enabledFormatted = italianEnabled.false;
    if (!enabled) {
        enabledFormatted = italianEnabled.true;
    }
    return enabledFormatted;
}

export function userDisabledFormatter(params) {
    const enabled = params.value;

    let enabledFormatted = italianEnabled.true;
    if (!enabled) {
        enabledFormatted = italianEnabled.false;
    }
    return enabledFormatted;
}

export function periodFormatter(params) {
    const period = params.value;

    return period.startFormatted + ' - ' + period.endFormatted;
}


