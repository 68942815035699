import {Autocomplete, TextField} from "@mui/material";
import React from "react";

export default function SelectWithSearch({options, value, onChange, required, multiple, label, id, entity}) {
    function setEntity(option) {
        if (entity === 'user') {
            return option.firstName && option.lastName ? option.lastName + ' ' + option.firstName + ' - ' + option.email : option.email
        } else if (entity === 'budget') {
            return option.name
        } else if (entity === 'budgetWithLocation') {
            return option.location && option.location.name !== "" ? option.name + ' - ' + option.location.name : option.name
        } else if (entity === 'codeProduct') {
            return option.code && option.name !== "" ? option.code + ' - ' + option.name : option.name
        }else {
            return option.name
        }
    }

    return (
        <Autocomplete
            multiple={multiple}
            id={id}
            options={options}
            getOptionLabel={(option) => setEntity(option)}
            value={value}
            onChange={(e, selectedUser) => onChange(selectedUser)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="standard"
                    required={required}
                    label={label}
                />
            )}
        />
    )
}
