import {authHeader, authHeaderImage, authHeaderPdf} from "./AuthHeader";

let curLocation = window.location.origin;
const filterEnabledFalse = "?enabled=false";

class ApiService {

    handleFetchResponse(res) {
        if (res.ok) {
            return res.json();
        } else {
            return res.json()
                .then(function (json) {
                    console.log(json);
                    return Promise.reject({
                        status: res.status,
                        ok: false,
                        message: json.message,
                        body: json
                    });
                })
        }
    }

    getCurrentUser() {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/users/current-user`, {
            method: "GET",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    // PRODUCTS
    getProductsAll() {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/products/all`, {
            method: "GET",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    getProducts(enabled) {
        const filter = !enabled === true ? "" : filterEnabledFalse;
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/products${filter}`, {
            method: "GET",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    createProduct(body) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/products/create`, {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(body),
        }).then(response => this.handleFetchResponse(response));
    }

    updateProducts(body) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/products/update`, {
            method: "PUT",
            headers: authHeader(),
            body: JSON.stringify(body),
        }).then(response => this.handleFetchResponse(response));
    }

    getProductById(id) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/products/${id}`, {
            method: "GET",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    deleteProductById(id) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/products/delete/${id}`, {
            method: "DELETE",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    getProductsBySearch(page, pageSize, catalogId, query, categoryId, all) {
        let queryApi = '';
        if (query !== undefined) {
            queryApi = `&query=${query}`;
        }

        let categoryIdApi = '';
        if (categoryId !== '') {
            categoryIdApi = `&categoryId=${categoryId}`;
        }

        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/products/search?page=${page}&pageSize=${pageSize}&sort=asc&catalogId=${catalogId}${queryApi}${categoryIdApi}&all=${all}`, {
            method: "GET",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    // LOCATIONS (Sedi)
    getLocationsAll() {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/locations/all`, {
            method: "GET",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    getLocations(enabled) {
        const filter = !enabled === true ? "" : filterEnabledFalse;
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/locations${filter}`, {
            method: "GET",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    createLocation(body) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/locations/create`, {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(body),
        }).then(response => this.handleFetchResponse(response));
    }

    updateLocation(body) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/locations/update`, {
            method: "PUT",
            headers: authHeader(),
            body: JSON.stringify(body),
        }).then(response => this.handleFetchResponse(response));
    }

    deleteLocationById(id) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/locations/delete/${id}`, {
            method: "DELETE",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    getLocationById(id) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/locations/${id}`, {
            method: "GET",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    // BUDGETS (Funzioni)
    getBudgetsAll() {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/budgets/all`, {
            method: "GET",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    getBudgets(enabled, period = 'all') {
        const filter = !enabled === true ? "" : filterEnabledFalse;
        const filterPeriod = period === 'all' ? "" : !enabled === true ? '?schoolYearId=' + period : '&schoolYearId=' + period
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/budgets${filter}${filterPeriod}`, {
            method: "GET",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    createBudgets(body) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/budgets/create`, {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(body),
        }).then(response => this.handleFetchResponse(response));
    }

    updateBudget(body) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/budgets/update`, {
            method: "PUT",
            headers: authHeader(),
            body: JSON.stringify(body),
        }).then(response => this.handleFetchResponse(response));
    }

    getBudgetById(id) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/budgets/${id}`, {
            method: "GET",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    getBudgetsByPeriodId(id) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/budgets/schoolyear/${id}`, {
            method: "GET",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    getBudgetsByUserId(userId) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/budgets/user/${userId}`, {
            method: "GET",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    getBudgetsToAdd(userId) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/budgets/available/user/${userId}`, {
            method: "GET",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    getBudgetsByUserIdForOrders(userId) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/budgets/user/${userId}/for-orders`, {
            method: "GET",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    deleteBudgetById(id) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/budgets/delete/${id}`, {
            method: "DELETE",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    // ORDERS
    getOrders() {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/orders`, {
            method: "GET",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    getOrderById(id) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/orders/${id}`, {
            method: "GET",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    getOrdersByBudgetId(budgetId) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/orders/budget/${budgetId}`, {
            method: "GET",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    createOrder(body) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/orders/create`, {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(body),
        }).then(response => this.handleFetchResponse(response));
    }

    updateOrder(body) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/orders/update`, {
            method: "PUT",
            headers: authHeader(),
            body: JSON.stringify(body),
        }).then(response => this.handleFetchResponse(response));
    }

    createOrderThirdParties(body) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/orders/third-parties/create`, {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(body),
        }).then(response => this.handleFetchResponse(response));
    }

    updateOrderThirdParties(body) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/orders/third-parties/update`, {
            method: "PUT",
            headers: authHeader(),
            body: JSON.stringify(body),
        }).then(response => this.handleFetchResponse(response));
    }

    addOrderItem(body) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/orders/create-order-item`, {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(body),
        }).then(response => this.handleFetchResponse(response));
    }

    updateOrderItem(body) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/orders/update-order-item`, {
            method: "PUT",
            headers: authHeader(),
            body: JSON.stringify(body),
        }).then(response => this.handleFetchResponse(response));
    }

    getOrdersByUserId(userId) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/orders/user/${userId}`, {
            method: "GET",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    getOrdersByStatus(status, catalogId, periodId = 'all') {
      const filterPeriod = periodId === 'all' ? '' : '&schoolYearId=' + periodId
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/orders/status/${status}?catalogId=${catalogId}${filterPeriod}`, {
            method: "GET",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    deleteOrderByOrderId(id) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/orders/delete/${id}`, {
            method: "DELETE",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    deleteOrderItemByIds(orderId, itemId) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/orders/delete-order-item/${orderId}/${itemId}`, {
            method: "DELETE",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    submitOrder(orderId) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/orders/submit/${orderId}`, {
            method: "POST",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    returnOrder(body) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/orders/return`, {
            method: "POST",
            body: JSON.stringify(body),
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    cancelOrder(orderId) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/orders/cancel/${orderId}`, {
            method: "POST",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    abortOrder(orderId) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/orders/abort/${orderId}`, {
            method: "POST",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    approveOrder(orderId) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/orders/approve/${orderId}`, {
            method: "POST",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    processOrder(orderId) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/orders/process/${orderId}`, {
            method: "POST",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    partialReturnSingleItemOrder(body) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/orders/partial-return`, {
            method: "POST",
            body: JSON.stringify(body),
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    partialReturnSubmittedSingleItemOrder(body) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/orders/partial-return-submitted`, {
            method: "POST",
            body: JSON.stringify(body),
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    // SCHOOL YEARS
    getPeriodsAll() {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/school-years/all`, {
            method: "GET",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    getPeriods(enabled) {
        const filter = !enabled === true ? "" : filterEnabledFalse;
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/school-years${filter}`, {
            method: "GET",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    createPeriod(body) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/school-years/create`, {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(body),
        }).then(response => this.handleFetchResponse(response));
    }

    updatePeriod(body) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/school-years/update`, {
            method: "PUT",
            headers: authHeader(),
            body: JSON.stringify(body),
        }).then(response => this.handleFetchResponse(response));
    }

    getPeriodById(id) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/school-years/${id}`, {
            method: "GET",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    deletePeriodById(id) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/school-years/delete/${id}`, {
            method: "DELETE",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    archiveBudgetsByPeriodId(periodId, archive) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/school-years/archive/${periodId}?archive=${archive}`, {
            method: "POST",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    // CATALOGS
    getCatalogsAll() {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/catalogs/all`, {
            method: "GET",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    getCatalogs(enabled) {
        const filter = !enabled === true ? "" : filterEnabledFalse;
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/catalogs${filter}`, {
            method: "GET",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    createCatalog(body) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/catalogs/create`, {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(body),
        }).then(response => this.handleFetchResponse(response));
    }

    updateCatalog(body) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/catalogs/update`, {
            method: "PUT",
            headers: authHeader(),
            body: JSON.stringify(body),
        }).then(response => this.handleFetchResponse(response));
    }

    getCatalogById(id) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/catalogs/${id}`, {
            method: "GET",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    deleteCatalogById(id) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/catalogs/delete/${id}`, {
            method: "DELETE",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    // CATEGORIES
    getCategoriesAll() {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/product-categories/all`, {
            method: "GET",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    getCategories(enabled) {
        const filter = !enabled === true ? "" : filterEnabledFalse;
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/product-categories${filter}`, {
            method: "GET",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    createCategory(body) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/product-categories/create`, {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(body),
        }).then(response => this.handleFetchResponse(response));
    }

    updateCategory(body) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/product-categories/update`, {
            method: "PUT",
            headers: authHeader(),
            body: JSON.stringify(body),
        }).then(response => this.handleFetchResponse(response));
    }

    getCategoryById(id) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/product-categories/${id}`, {
            method: "GET",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    getCategoriesByCatalogId(catalogId) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/catalogs/product-categories/${catalogId}`, {
            method: "GET",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    deleteCategoryById(id) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/product-categories/delete/${id}`, {
            method: "DELETE",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    // STOCK
    createStock(body) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/stocks/create`, {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(body),
        }).then(response => this.handleFetchResponse(response));
    }

    updateStock(body) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/stocks/update`, {
            method: "PUT",
            headers: authHeader(),
            body: JSON.stringify(body),
        }).then(response => this.handleFetchResponse(response));
    }

    getStockQuantityByProductId(productId) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/stocks/stock-quantity/${productId}`, {
            method: "GET",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    getStocksByProductId(productId) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/stocks/product/${productId}`, {
            method: "GET",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    getStockByStockId(stockId) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/stocks/${stockId}`, {
            method: "GET",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    deleteStockByStockId(stockId) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/stocks/delete/${stockId}`, {
            method: "DELETE",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    //COUPONS
    createCoupon(body) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/coupons/create`, {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(body),
        }).then(response => this.handleFetchResponse(response));
    }

    updateCoupon(body) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/coupons/update`, {
            method: "PUT",
            headers: authHeader(),
            body: JSON.stringify(body),
        }).then(response => this.handleFetchResponse(response));
    }

    getCouponById(id) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/coupons/${id}`, {
            method: "GET",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    deleteCouponById(id) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/coupons/delete/${id}`, {
            method: "DELETE",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    getCoupons(enabled) {
        const filter = !enabled === true ? "" : filterEnabledFalse;
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/coupons${filter}`, {
            method: "GET",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    getCouponsAll() {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/coupons/all`, {
            method: "GET",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    getCouponsByUserId(userId) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/coupons/owner/${userId}`, {
            method: "GET",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    getCouponsByBudgetId(budgetId) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/coupons/budget/${budgetId}`, {
            method: "GET",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    // IMAGE PRODUCT
    uploadImage(productId, body) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/storage/upload/product/${productId}`, {
            method: "POST",
            headers: authHeaderImage(),
            body: body,
        })
    }

    getImage(imageName) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/storage/product-image/${imageName}`, {
            method: "GET",
            headers: authHeaderImage()
        })
    }

    deleteImage(productId) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/storage/delete/product/${productId}`, {
            method: "DELETE",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    //USERS
    getUsers(enabled) {
        const filter = !enabled === true ? "" : "?disabled=true";
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/users${filter}`, {
            method: "GET",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    getUsersAll() {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/users/all`, {
            method: "GET",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    createUser(body) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/users/create`, {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(body),
        }).then(response => this.handleFetchResponse(response));
    }

    updateUser(body) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/users/update`, {
            method: "PUT",
            headers: authHeader(),
            body: JSON.stringify(body),
        }).then(response => this.handleFetchResponse(response));
    }

    getUserById(id) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/users/${id}`, {
            method: "GET",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    deleteUserById(id) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/users/delete/${id}`, {
            method: "DELETE",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    //LOGS
    getLogsSearch(logType, dateFrom, dateTo, userId) {
        let userParam = '';
        if (userId) {
            userParam = `&userId=${userId}`
        }
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/events/search?eventType=${logType}&from=${encodeURIComponent(dateFrom)}&to=${encodeURIComponent(dateTo)}${userParam}`, {
            method: "GET",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    // REPORTS
    getSpendLimitReport(filter) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/reports/budget-limit`, {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(filter)
        }).then(response => this.handleFetchResponse(response));
    }

    getItemsOrderedReport(filter) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/reports/ordered-product`, {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(filter)
        }).then(response => this.handleFetchResponse(response));
    }

    getItemsOrderedByUserReport(filter) {
        let path = `-by-budget?budgetId=${filter.budgetId}`;
        if (filter.to) {
            path = `-by-user-datarange?from=${encodeURIComponent(filter.from)}&to=${encodeURIComponent(filter.to)}&userId=${filter.userId}`;
        }
        if (filter.userId && filter.budgetId) {
            path = `-by-user-budget?budgetId=${filter.budgetId}&userId=${filter.userId}`;
        }
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/reports/ordered-product${path}`, {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(filter)
        }).then(response => this.handleFetchResponse(response));
    }

    getCouponTotalSpendReport(filter) {
        let path = `?schoolYearId=${filter.schoolYearId}&threshold=${filter.threshold}`;
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/reports/coupon-threshold${path}`, {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(filter)
        }).then(response => this.handleFetchResponse(response));
    }

    getStockProductsReport(from, to, catalogId) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/reports/products-stock?from=${from}&to=${to}&catalogId=${catalogId}`, {
            method: "POST",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    getSeasonalityReport(from, to, catalogId) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/reports/seasonality?from=${from}&to=${to}&catalogId=${catalogId}`, {
            method: "POST",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    getBudgetStatusLocationReport(locationId, schoolYearId) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/reports/budget-for-location?locationId=${locationId}&schoolYearId=${schoolYearId}`, {
            method: "POST",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    // MANUAL
    getManualByUser(type) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/storage/docs/user/${type}`, {
            method: "GET",
            headers: authHeaderPdf()
        })
    }

    getManualBySecretary(type) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/storage/docs/secretary/${type}`, {
            method: "GET",
            headers: authHeaderPdf()
        })
    }

    getManualByManager(type) {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/storage/docs/manager/${type}`, {
            method: "GET",
            headers: authHeaderPdf()
        })
    }

    getAllManualsUser() {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/storage/docs/user`, {
            method: "GET",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    getAllManualsSecretary() {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/storage/docs/secretary`, {
            method: "GET",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }

    getAllManualsManager() {
        return fetch(`${curLocation}${process.env.REACT_APP_SERVER_URL}/storage/docs/manager`, {
            method: "GET",
            headers: authHeader()
        }).then(response => this.handleFetchResponse(response));
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ApiService();
