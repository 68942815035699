import React, {useEffect, useState} from 'react';
import {Box, Button, Checkbox, FormControlLabel, Grid, MenuItem, TextField, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import ApiService from "../services/ApiService";
import AgGrid from "./AgGrid";
import {routes} from "../utils/ReusableText";
import {dataFunctions} from "../utils/agGridDefs";

export default function Functions() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [enabled, setEnabled] = useState(false);
    const [periods, setPeriods] = useState(undefined);
    const [selectedPeriod, setSelectedPeriod] = useState('all');
    const [gridData, setGridData] = useState([]);

    useEffect(() => {
        setLoading(true);
        ApiService.getPeriodsAll().then(res => {
            if (res.length > 0) {
                const periodAll = {
                    id: 'all',
                    name: 'Tutti'
                }
                res.unshift(periodAll);
                setPeriods(res);
            }

            getFunctions(enabled, selectedPeriod);
        })

        // eslint-disable-next-line
    }, [enabled, selectedPeriod]);

    function getFunctions(enabledSelected, periodSelected) {
        setSelectedPeriod(periodSelected);
        ApiService.getBudgets(enabledSelected, periodSelected).then(response => {
            setLoading(false);

            if (response.length > 0) {
                setGridData(response);
            } else {
                setGridData([]);
            }
        });
    }

    return (<>
        <Grid container sx={{marginTop: 2}} justifyContent={'space-between'}>
            <Grid item xs='auto'>
                <Typography variant="h5" component="h2" sx={{fontWeight: '500'}}>Funzioni</Typography>
            </Grid>
            <Grid item xs='auto'>
                <Button variant="contained" color="primary"
                        fullWidth
                        onClick={() => {
                            navigate('/' + routes.editFunction)
                        }}>
                    Crea funzione
                </Button>
            </Grid>
        </Grid>

        <Grid container sx={{mt: 3}}>
            <Grid item xs={12} md={8} lg={4}>
                <Box
                    sx={{
                        background: '#EFEFEF',
                        p: 1,
                        mb: 2
                    }}
                >
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                select
                                id="selectedPeriod"
                                value={selectedPeriod || 'all'}
                                label={'Seleziona un periodo'}
                                onChange={(e) => getFunctions(enabled, e.target.value)}
                                fullWidth
                                variant="standard"
                            >
                                {
                                    periods?.map((period) => (
                                        <MenuItem key={period.id} value={period.id}>{period.name}</MenuItem>
                                    ))}
                            </TextField>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>

        <Grid container>
            <Grid item xs="auto">
                <FormControlLabel control={<Checkbox checked={enabled} onChange={() => {
                    setEnabled(!enabled);
                }}/>} label="Archiviati"/>
            </Grid>
        </Grid>

        <AgGrid children={loading ? [] : {...dataFunctions, rowData: gridData}}
                redirect={"/" + routes.functionDetails}/>
    </>);
}