import {useState} from "react";
import ListItemText from '@mui/material/ListItemText';
import {List, ListItemIcon, Tooltip} from "@mui/material";
import {ExpandMore} from "@mui/icons-material";
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import BookmarksOutlinedIcon from '@mui/icons-material/BookmarksOutlined';
import FolderCopyOutlinedIcon from '@mui/icons-material/FolderCopyOutlined';
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import FeaturedPlayListOutlined from '@mui/icons-material/FeaturedPlayListOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import MoneyOffOutlinedIcon from '@mui/icons-material/MoneyOffOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ListItemsElm from "./ListItemsElm";
import {getUser} from "../services/AuthService";
import {roles, routes} from "../utils/ReusableText";
import PieChartOutlineOutlinedIcon from '@mui/icons-material/PieChartOutlineOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import AddShoppingCartOutlinedIcon from '@mui/icons-material/AddShoppingCartOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

export default function ListItems({open}) {
    const user = getUser();
    const [openCollapse, setOpenCollapse] = useState(false);
    const [openCollapseTwo, setOpenCollapseTwo] = useState(false);
    const [openCollapseThree, setOpenCollapseThree] = useState(false);

    const handleClick = () => {
        setOpenCollapse(!openCollapse);
    };

    const handleClickTwo = () => {
        setOpenCollapseTwo(!openCollapseTwo);
    };

    const handleClickThree = () => {
        setOpenCollapseThree(!openCollapseThree);
    };

    function checkRoute() {
        if (user?.role === roles.manager) {
            return routes.homeManager
        } else if (user?.role === roles.user) {
            return routes.homeUser
        } else {
            return routes.homeSecretary
        }
    }

    return (
        <div>
            <List>
                <ListItemsElm label={'Home - Ordini'} icon={<Inventory2OutlinedIcon fontSize="small"/>}
                              open={open} route={checkRoute()}/>

                {user?.role === roles.secretary || user?.role === roles.manager ?
                    <ListItemsElm label={'Buoni acquisto'} icon={<ConfirmationNumberOutlinedIcon fontSize="small"/>}
                                  open={open}
                                  route={routes.coupons}/>
                    :
                    ''}
                {user?.role === roles.manager ?
                    <>
                        <ListItemButton onClick={handleClick}
                                        sx={{color: 'white', margin: open ? '0.5rem' : '', px: 0.5, py: 0.25, pl: 2,}}>
                            <ListItemIcon
                                sx={{
                                    display: open ? 'none' : 'inline-flex',
                                    color: 'rgba(255, 255, 255, 0.75)',
                                    marginLeft: '5px',
                                    minWidth: '42px',
                                }}
                            >
                                <Tooltip title="Amministrazione" placement="right">
                                    <SettingsOutlinedIcon fontSize="small"/>
                                </Tooltip>
                            </ListItemIcon>
                            <ListItemText
                                primary="Amministrazione"
                                sx={{
                                    opacity: open ? 'inline-block' : 'none',
                                    color: 'white',
                                    padding: '0.625rem 0',
                                }}
                            />
                            {openCollapse ? <ExpandLess/> : <ExpandMore/>}
                        </ListItemButton>
                        <Collapse in={openCollapse} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>

                                <ListItemsElm label={'Utenti'} icon={<PeopleAltOutlinedIcon fontSize="small"/>}
                                              open={open}
                                              route={routes.users} submenu={true}/>

                                <ListItemsElm label={'Funzioni'} icon={<BadgeOutlinedIcon fontSize="small"/>}
                                              open={open}
                                              submenu={true} route={routes.functions}/>

                                <ListItemsElm label={'Sedi'} icon={<PlaceOutlinedIcon fontSize="small"/>} open={open}
                                              submenu={true}
                                              route={routes.locations}/>

                                <ListItemsElm label={'Prodotti e forniture'}
                                              icon={<CreateOutlinedIcon fontSize="small"/>}
                                              open={open}
                                              submenu={true} route={routes.products}/>

                                <ListItemsElm label={'Categorie prodotti'}
                                              icon={<BookmarksOutlinedIcon fontSize="small"/>}
                                              open={open}
                                              submenu={true} route={routes.categories}/>

                                <ListItemsElm label={'Cataloghi'} icon={<FolderCopyOutlinedIcon fontSize="small"/>}
                                              open={open}
                                              submenu={true} route={routes.catalogs}/>

                                <ListItemsElm label={'Periodi'} icon={<TodayOutlinedIcon fontSize="small"/>} open={open}
                                              submenu={true} route={routes.periods}/>

                                <ListItemsElm label={'Consultazione log'}
                                              icon={<FeaturedPlayListOutlined fontSize="small"/>} open={open}
                                              submenu={true} route={routes.logs}/>

                            </List>
                        </Collapse>
                    </>
                    :
                    ''
                }

                {(user?.role === roles.manager || user?.role === roles.secretary) ?
                    <>
                        <ListItemButton onClick={handleClickTwo}
                                        sx={{color: 'white', margin: open ? '0.5rem' : '', px: 0.5, py: 0.25, pl: 2,}}>
                            <ListItemIcon
                                sx={{
                                    display: open ? 'none' : 'inline-flex',
                                    color: 'rgba(255, 255, 255, 0.75)',
                                    marginLeft: '5px',
                                    minWidth: '42px',
                                }}
                            >
                                <Tooltip title="Reports" placement="right">
                                    <BarChartOutlinedIcon fontSize="small"/>
                                </Tooltip>
                            </ListItemIcon>
                            <ListItemText
                                primary="Reports"
                                sx={{
                                    opacity: open ? 'inline-block' : 'none',
                                    color: 'white',
                                    padding: '0.625rem 0',
                                }}
                            />
                            {openCollapseTwo ? <ExpandLess/> : <ExpandMore/>}
                        </ListItemButton>
                        <Collapse in={openCollapseTwo} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>

                                <ListItemsElm label={'Limite di spesa'}
                                              icon={<MoneyOffOutlinedIcon fontSize="small"/>}
                                              open={open}
                                              submenu={true} route={routes.spendLimitReport}/>
                                <ListItemsElm label={'Articoli comandati per utente'}
                                              icon={<AccountCircleOutlinedIcon fontSize="small"/>}
                                              open={open}
                                              submenu={true} route={routes.itemsOrderedByUserReport}/>
                                <ListItemsElm label={'Articoli comandati'}
                                              icon={<AddShoppingCartOutlinedIcon fontSize="small"/>}
                                              open={open}
                                              submenu={true} route={routes.itemsOrderedReport}/>
                                <ListItemsElm label={'Stock prodotti'}
                                              icon={<InventoryOutlinedIcon fontSize="small"/>}
                                              open={open}
                                              submenu={true} route={routes.stockProductsReport}/>
                                <ListItemsElm label={'Stato budget sede'}
                                              icon={<LocationOnOutlinedIcon fontSize="small"/>}
                                              open={open}
                                              submenu={true} route={routes.budgetStatusLocationReport}/>
                                <ListItemsElm label={'Proporzione buoni su spesa totale'}
                                              icon={<PieChartOutlineOutlinedIcon fontSize="small"/>}
                                              open={open}
                                              submenu={true} route={routes.couponsTotalSpendReport}
                                />
                                <ListItemsElm label={'Stagionalità'}
                                              icon={<CalendarMonthOutlinedIcon fontSize="small"/>}
                                              open={open}
                                              submenu={true} route={routes.seasonalityReport}/>
                            </List>
                        </Collapse>
                    </>
                    :
                    ''}

                <>
                    <ListItemButton onClick={handleClickThree}
                                    sx={{color: 'white', margin: open ? '0.5rem' : '', px: 0.5, py: 0.25, pl: 2,}}>
                        <ListItemIcon
                            sx={{
                                display: open ? 'none' : 'inline-flex',
                                color: 'rgba(255, 255, 255, 0.75)',
                                marginLeft: '5px',
                                minWidth: '42px',
                            }}
                        >
                            <Tooltip title="AIUTO" placement="right">
                                <InfoOutlinedIcon fontSize="small"/>
                            </Tooltip>
                        </ListItemIcon>
                        <ListItemText
                            primary="Aiuto"
                            sx={{
                                opacity: open ? 'inline-block' : 'none',
                                color: 'white',
                                padding: '0.625rem 0',
                            }}
                        />
                        {openCollapseThree ? <ExpandLess/> : <ExpandMore/>}
                    </ListItemButton>
                    <Collapse in={openCollapseThree} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItemsElm label={'Manuali'}
                                          icon={<SupervisorAccountOutlinedIcon fontSize="small"/>}
                                          open={open}
                                          submenu={true} route={routes.userManual}/>

                        </List>
                    </Collapse>
                </>
            </List>
        </div>
    )

}