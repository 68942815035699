import React, {useEffect, useState} from 'react';
import {Button, Checkbox, FormControlLabel, Grid, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import ApiService from "../services/ApiService";
import AgGrid from "./AgGrid";
import {routes} from "../utils/ReusableText";
import {dataCategories} from "../utils/agGridDefs";


export default function Catalogs() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [enabled, setEnabled] = useState(false);

    useEffect(() => {
        setLoading(true);

        getCategories();
        // eslint-disable-next-line
    }, [enabled])

    function getCategories() {
        ApiService.getCategories(enabled).then(response => {
            setLoading(false);

            dataCategories.rowData = response;
        });
    }

    return (<>
        <Grid container sx={{marginTop: 2}} justifyContent={'space-between'}>
            <Grid item xs='auto'>
                <Typography variant="h5" component="h2" sx={{fontWeight: '500'}}>Categorie prodotti</Typography>
            </Grid>
            <Grid item xs='auto'>
                <Button variant="contained" color="primary"
                        fullWidth
                        onClick={() => {
                            navigate('/' + routes.editCategory)
                        }}>
                    Crea categoria
                </Button>
            </Grid>
        </Grid>

        <Grid container>
            <Grid item xs="auto">
                <FormControlLabel control={<Checkbox checked={enabled} onChange={() => {
                    setEnabled(!enabled);
                }}/>} label="Archiviati"/>
            </Grid>
        </Grid>

        <AgGrid children={loading ? [] : dataCategories} redirect={"/" + routes.categoryDetails}/>
    </>);
}