import {Box, Button, Grid, MenuItem, Stack, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import ApiService from "../services/ApiService";
import {NumericFormat} from "react-number-format";
import BasicDialog from "../components/BasicDialog";
import {label, routes, stockStatus, warningText} from "../utils/ReusableText";
import {handleClose} from "../utils/ReusableFunction";

export default function EditStock() {
    const navigate = useNavigate();
    const path = useLocation();
    const id = path.pathname.split('/')[2];
    const stockId = path.pathname.split('/')[3];
    const [description, setDescription] = useState(undefined);
    const [type, setType] = useState(undefined);
    const [quantity, setQuantity] = useState(undefined);
    const [supplier, setSupplier] = useState(undefined);
    const [notes, setNotes] = useState(undefined);
    const [isEdit, setIsEdit] = useState(stockId !== 'new');
    const [errorAlert, setErrorAlert] = useState(false);
    const [alreadyClicked, setAlreadyClicked] = useState(false);

    useEffect(() => {
        if (stockId !== 'new') {
            ApiService.getStockByStockId(stockId).then(response => {
                setType(response.status);
                setQuantity(Number(response.quantity));
                setSupplier(response.supplier);
                setNotes(response.notes);
                setDescription(response.description);
            })
        } else {
            setIsEdit(false);
        }
    }, [stockId]);

    function saveStock() {
        setAlreadyClicked(true);

        if (stockId !== 'new') {
            const body = {
                id: stockId,
                description: description,
                quantity: quantity,
                supplier: supplier,
                notes: notes
            }

            ApiService.updateStock(body).then(response => {
                navigate('/' + routes.productDetails + '/' + id);
            }).catch((e) => {
                setAlreadyClicked(false);
                setErrorAlert(true);
            });
        } else {
            const body = {
                description: description,
                quantity: quantity,
                supplier: supplier,
                notes: notes,
                stockLineType: type,
                productId: id
            }

            ApiService.createStock(body).then(response => {
                navigate('/' + routes.productDetails + '/' + id);
            }).catch((e) => {
                setAlreadyClicked(false);
                setErrorAlert(true);
            });
        }
    }

    function checkMandatoryStockFields() {
        return !(type && quantity && supplier);
    }

    function getLabel() {
        let text = '';
        if (isEdit) {
            if (type === stockStatus.supply) {
                text = label.stockSupply;
            } else {
                text = label.stockFix;
            }
        } else {
            text = 'Seleziona tipo di fornitura';
        }
        return text;
    }

    return (<>
        <Grid container sx={{marginTop: 2}}>
            <Grid item xs={12}>
                <Typography variant="h5" component="h2"
                            sx={{fontWeight: '500'}}>{isEdit ? 'Modifica Fornitura' : 'Nuova Fornitura'}</Typography>
            </Grid>
        </Grid>
        <Grid container sx={{mt: 4}}>
            <Grid item xs={12} md={4}>
                <Box component={'form'}>
                    <Stack spacing={1.5}>

                        {type === stockStatus.supply ?
                            <TextField
                                select
                                disabled={isEdit}
                                id="type"
                                value={type || ''}
                                label={getLabel()}
                                onChange={e => setType(e.target.value)}
                                required
                                variant={'standard'}
                                fullWidth
                            >
                                <MenuItem key={stockStatus.supply} value={stockStatus.supply}>Entrata merci</MenuItem>
                                <MenuItem key={stockStatus.removal} value={stockStatus.removal}>Correzione dello
                                    stock</MenuItem>
                            </TextField>
                            :
                            <TextField
                                select
                                disabled={isEdit}
                                id="type"
                                defaultValue={type}
                                value={type}
                                label={type ? stockStatus.removal : 'Seleziona tipo di fornitura'}
                                onChange={e => setType(e.target.value)}
                                required
                                variant={'standard'}
                                fullWidth
                            >
                                <MenuItem key={stockStatus.supply} value={stockStatus.supply}>Entrata merci</MenuItem>
                                <MenuItem key={stockStatus.removal} value={stockStatus.removal}>Correzione dello
                                    stock</MenuItem>
                            </TextField>
                        }

                        <TextField id="description" label="Descrizione" value={description}
                                   defaultValue={description} variant="standard" fullWidth
                                   InputLabelProps={isEdit ? {shrink: true} : ''}
                                   onChange={e => setDescription(e.target.value)}/>
                        <TextField id="supplier" label="Fornitore" value={supplier} defaultValue={supplier}
                                   InputLabelProps={isEdit ? {shrink: true} : ''} fullWidth
                                   variant="standard"
                                   onChange={e => setSupplier(e.target.value)} required/>
                        <NumericFormat
                            allowNegative={false}
                            decimalScale={0}
                            customInput={TextField} id="quantity"
                            label="Quantità"
                            defaultValue={quantity}
                            value={quantity}
                            variant="standard"
                            required
                            onChange={e => setQuantity(e.target.value)}
                            fullWidth/>
                        <TextField
                            id="notes"
                            label="Note"
                            defaultValue={notes}
                            value={notes}
                            variant="standard"
                            onChange={e => setNotes(e.target.value)}
                            InputLabelProps={isEdit ? {shrink: true} : ''}
                            multiline
                            rows={5}
                            fullWidth
                        />
                    </Stack>
                </Box>
                <Grid container sx={{mt: 3}} justifyContent={'space-between'}>
                    <Grid item>
                        <Button variant="flip" onClick={() => {
                            navigate('/' + routes.productDetails + '/' + id)
                        }}>
                            Cancella
                        </Button>

                    </Grid>
                    <Grid item>
                        {isEdit ?
                            <Button variant="contained" color="primary"
                                    disabled={checkMandatoryStockFields() || alreadyClicked}
                                    onClick={() => {
                                        saveStock()
                                    }}>Salva</Button>
                            :
                            <Button variant="contained" color="primary"
                                    disabled={checkMandatoryStockFields() || alreadyClicked}
                                    onClick={() => {
                                        saveStock()
                                    }}>Crea</Button>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

        <BasicDialog isOpen={errorAlert} handleClose={() => handleClose(setErrorAlert)} title={warningText.titleError}
                     body={warningText.somethingWentWrong} closeLabel={warningText.okButton} successLabel={undefined}/>
    </>);
}