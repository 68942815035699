export const voidUser = {
    id: undefined,
    email: "",
    firstName: "",
    lastName: "",
    disabled: false,
    fullName: "",
    lastLoginFormatted: ""
}

export const voidProduct = {
    id: undefined,
    name: "",
    status: "",
    price: 0,
    image: "",
    code: "",
    catalog: {
        id: -1,
        name: "",
        enabled: true,
        description: null
    },
    category: {
        id: -1,
        name: "",
        enabled: true,
        description: ""
    },
    minimumStockQuantity: 4,
    unitDescription: "",
    enabled: true,
    stock: 0,
    priceFormatted: ""
}

export const voidPeriod = {
    id: undefined,
    name: "",
    start: "",
    end: "",
    primarySchoolStudentBudget: 0,
    elementarySchoolFirstCycleStudentBudget: 0,
    elementarySchoolSecondCycleStudentBudget: 0,
    enabled: true,
    primarySchoolStudentBudgetFormatted: "",
    elementarySchoolFirstCycleStudentBudgetFormatted: "",
    elementarySchoolSecondCycleStudentBudgetFormatted: "",
    startFormatted: "",
    endFormatted: ""
}

export const voidBudget = {
    id: undefined,
    name: "",
    amount: 0,
    availableAmount: 0,
    schoolYear: {
        id: undefined,
        name: "",
        start: "",
        end: "",
        primarySchoolStudentBudget: 0,
        elementarySchoolFirstCycleStudentBudget: 0,
        elementarySchoolSecondCycleStudentBudget: 0,
        enabled: true,
        primarySchoolStudentBudgetFormatted: "",
        elementarySchoolFirstCycleStudentBudgetFormatted: "",
        elementarySchoolSecondCycleStudentBudgetFormatted: "",
        startFormatted: "",
        endFormatted: ""
    },
    location: {
        id: undefined,
        name: "",
        street: "",
        zip: "",
        city: "",
        enabled: true
    },
    catalog: {
        id: undefined,
        name: "",
        enabled: true,
        description: ""
    },
    users: [
        {
            id: undefined,
            email: "",
            firstName: "",
            lastName: "",
            disabled: false,
            fullName: "",
            lastLoginFormatted: ""
        }
    ],
    primarySchoolStudents: 0,
    elementarySchoolFirstCycleStudents: 0,
    elementarySchoolSecondCycleStudents: 0,
    arbitraryAmount: 0,
    enabled: true,
    amountFormatted: "",
    availableAmountFormatted: "",
    arbitraryAmountFormatted: ""
}