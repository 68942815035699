import React, {useCallback, useEffect, useState} from 'react';
import {Box, Button, Grid, MenuItem, TextField, Typography} from "@mui/material";
import ApiService from "../services/ApiService";
import {AgGridReact} from "ag-grid-react";
import {agGridItalianTranslation} from "../configuration/agGridItalianTranslation";
import BasicDialog from "./BasicDialog";
import SelectWithSearch from "./SelectWithSearch";
import {warningText} from "../utils/ReusableText";
import {handleClose} from "../utils/ReusableFunction";
import {logTypes} from "../utils/agGridDefs";
import {voidUser} from "../utils/ReusableObjects";

export default function Logs() {
    const [columnDefs, setColumnDefs] = useState([]);
    const [defaultColDef] = useState({
        resizable: true,
        flex: 1,
    });
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [type, setType] = useState();
    const [user, setUser] = useState(voidUser);
    const [listOfUsers, setListOfUsers] = useState([]);
    const [dateFrom, setDateFrom] = useState(undefined);
    const [dateTo, setDateTo] = useState(undefined);
    const [errorDateFrom, setErrorDateFrom] = useState(false);
    const [errorDateTo, setErrorDateTo] = useState(false);
    const [errorAlert, setErrorAlert] = useState(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onSelectEntity = useCallback((type, dateFrom, dateTo, user) => {
        fetchLogs(type, dateFrom, dateTo, user).then(response => {
            let selectedData;
            for (const singleType of logTypes) {
                if (singleType.name === type) {
                    selectedData = singleType.data.columnDefs;
                    setColumnDefs(selectedData);
                }
            }
            if (response) {
                setRowData(response);
            }
        });
    });

    useEffect(() => {
        ApiService.getUsers().then(response => {
            setListOfUsers(response);
        });
    }, []);

    const fetchLogs = async (type, dateFrom, dateTo, user) => {
        const from = dateFrom.replace('T', ' ');
        const to = dateTo.replace('T', ' ');

        return await ApiService.getLogsSearch(type, from, to, user.id !== -1 ? user.id : undefined).then(response => {
            setLoading(false);
            let selectedData;
            for (const singleType of logTypes) {
                if (singleType.name === type) {
                    selectedData = singleType.data.columnDefs;
                    setColumnDefs(selectedData);
                }
            }
            if (response.length > 0) {
                return response;
            } else {
                return [];
            }
        }).catch((e) => setErrorAlert(true));
    }

    function checkDateFrom(value) {
        const endDate = new Date(dateTo);
        const startDate = new Date(value);

        setDateFrom(value);

        if (startDate >= endDate) {
            setErrorDateFrom(true);
        } else {
            if (errorDateTo) {
                setErrorDateTo(false);
            } else {
                setErrorDateFrom(false);
            }
        }

    }

    function checkDateTo(value) {
        const startDate = new Date(dateFrom);
        const endDate = new Date(value);

        setDateTo(value);

        if (startDate >= endDate) {
            setErrorDateTo(true);
        } else {
            if (errorDateFrom) {
                setErrorDateFrom(false);
            } else {
                setErrorDateTo(false);
            }
        }
    }

    return (<>
        <Grid container sx={{mt: 2}} justifyContent={'space-between'}>
            <Grid item xs="auto">
                <Typography variant="h5" component="h2" sx={{fontWeight: '500'}}>Consultazione Log</Typography>
            </Grid>
        </Grid>

        <Grid container sx={{mt: 3}}>
            <Grid item xs={12} md={12} lg={12}>
                <Box
                    sx={{
                        background: '#EFEFEF',
                        p: 1,
                        mb: 2
                    }}
                >
                    <Grid container spacing={1}>
                        <Grid item xs={3} sm={3}>
                            {errorDateFrom ?
                                <TextField type="datetime-local"
                                           format="dd.MM.yyyy"
                                           error
                                           required
                                           fullWidth
                                           id="from"
                                           label="Data d'inizio"
                                           defaultValue={dateFrom}
                                           helperText="La data d'inizio non può essere superiore alla data fine"
                                           variant="standard"
                                           onChange={(e) => checkDateFrom(e.target.value)}
                                           InputLabelProps={{shrink: true}}
                                />
                                :
                                <TextField id="from" label="Data d'inizio" InputLabelProps={{shrink: true}}
                                           type="datetime-local"
                                           value={dateFrom}
                                           required
                                           variant="standard"
                                           inputFormat="dd.MM.yyyy"
                                           onChange={(e) => checkDateFrom(e.target.value)} fullWidth/>
                            }
                        </Grid>
                        <Grid item xs={3} sm={3}>
                            {errorDateTo ?
                                <TextField type="datetime-local"
                                           format="dd.MM.yyyy"
                                           error
                                           required
                                           fullWidth
                                           id="to"
                                           label="Data di fine"
                                           defaultValue={dateTo}
                                           helperText="La data di fine non può essere superiore alla data d'inizio"
                                           variant="standard"
                                           onChange={(e) => checkDateTo(e.target.value)}
                                           InputLabelProps={{shrink: true}}/>
                                :
                                <TextField id="to" label="Data di fine" InputLabelProps={{shrink: true}}
                                           type="datetime-local"
                                           required
                                           value={dateTo}
                                           variant="standard"
                                           inputFormat="dd.MM.yyyy"
                                           onChange={(e) => checkDateTo(e.target.value)} fullWidth/>
                            }
                        </Grid>
                        <Grid item xs={2} sm={2}>
                            <TextField
                                select
                                id="selectedType"
                                required
                                value={type || ''}
                                label={'Seleziona un\'entità'}
                                onChange={(e) => setType(e.target.value)}
                                fullWidth
                                variant="standard"
                            >
                                {
                                    logTypes.map((type) => (
                                        <MenuItem key={type.name} value={type.name}>{type.label}</MenuItem>
                                    ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={3} sm={3}>
                            <SelectWithSearch options={listOfUsers} value={user} onChange={setUser} required={false}
                                              multiple={false} label={"Seleziona utente"} id={"userSelect"} entity={'user'}/>
                        </Grid>
                        <Grid item xs={1} sm={1}>
                            <Button variant="contained" color="primary" fullWidth sx={{mt: 1}}
                                    disabled={!(dateFrom && dateTo && type && !errorDateTo && !errorDateFrom)}
                                    onClick={() => onSelectEntity(type, dateFrom, dateTo, user !== '' ? user : undefined)}>
                                Genera log
                            </Button>
                        </Grid>
                    </Grid>
                </Box>

                {!loading ? <>
                    <div style={{width: '100%', height: '100%'}} className="ag-theme-material ag-custom">
                        <div style={{width: '100%', height: '100%'}}>
                            <AgGridReact
                                columnDefs={columnDefs}
                                defaultColDef={defaultColDef}
                                rowData={rowData}
                                pagination={true}
                                paginationPageSize={25}
                                singleClickEdit={true}
                                domLayout={'autoHeight'}
                                localeText={agGridItalianTranslation}
                            >
                            </AgGridReact>
                        </div>
                    </div>
                </> : ''}
            </Grid>
        </Grid>

        <BasicDialog isOpen={errorAlert} handleClose={() => handleClose(setErrorAlert)} title={warningText.titleError}
                     body={warningText.somethingWentWrong} closeLabel={warningText.okButton} successLabel={undefined}/>
    </>);
}