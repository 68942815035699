import {
    Button,
    ButtonGroup, ClickAwayListener,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid, Grow, MenuItem, MenuList,
    Paper, Popper,
    Stack
} from "@mui/material";
import React, {useEffect, useState, useRef} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import ApiService from "../services/ApiService";
import BasicDialog from "../components/BasicDialog";
import SnackBarAlert from "../components/SnackBarAlert";
import {label, routes, warningText} from "../utils/ReusableText";
import {handleClose} from "../utils/ReusableFunction";
import {getUser} from "../services/AuthService";
import {AgGridReact} from "ag-grid-react";
import {agGridItalianTranslation} from "../configuration/agGridItalianTranslation";
import {columnCoupons, columnFunctions, columnOrders} from "../utils/agGridDefs";
import SelectWithSearch from "../components/SelectWithSearch";
import {voidBudget} from "../utils/ReusableObjects";
import MoreVertIcon from "@mui/icons-material/MoreVert";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (<div
        hidden={value !== index}
        id={`wrapped-tab-${index}`}
        {...other}
    >
        {value === index && (
            <Grid container sx={{mt: 2}}>
                <Grid item xs={12}>
                    {children}
                </Grid>
            </Grid>
        )}
    </div>);
}

TabPanel.propTypes = {
    children: PropTypes.node, index: PropTypes.any.isRequired, value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `wrapped-tab-${index}`, 'aria-controls': `wrapped-Orders-${index}`,
    };
}

export default function UserDetails() {
    const navigate = useNavigate();
    const path = useLocation();
    const id = Number(path.pathname.split('/')[2]);
    const [value, setValue] = React.useState('one');
    const [loading, setLoading] = React.useState(true);
    const [currentUser, setCurrentUser] = React.useState(getUser);
    const [openDialog, setOpenDialog] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [errorAlert, setErrorAlert] = useState(false);
    const [functionToAdd, setFunctionToAdd] = useState(voidBudget);
    const [isFetching, setIsFetching] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [listOfBudgets, setListOfBudgets] = useState(false);
    const [rowDataFunctions, setRowDataFunctions] = useState([]);
    const [rowDataOrders, setRowDataOrders] = useState([]);
    const [rowDataCoupons, setRowDataCoupons] = useState([]);
    const [columnDefsFunctions] = useState(columnFunctions);
    const [columnDefsOrders] = useState(columnOrders);
    const [columnDefsCoupons] = useState(columnCoupons);
    const [defaultColDef] = useState({
        resizable: true,
        flex: 1,
    });
    const [openButtonsGroup, setOpenButtonsGroup] = useState(false);
    const anchorRef = useRef(null);

    useEffect(() => {
        setLoading(true);

        ApiService.getUserById(id).then(response => {
            setCurrentUser(response);

            ApiService.getOrdersByUserId(id).then(response => {
                setLoading(false);
                if (response.length > 0) {
                    setRowDataOrders(response);
                }

                fetchBudgetsByUserId();
                fetchCouponsByUserId();
            });


        });
        // eslint-disable-next-line
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function fetchBudgetsByUserId() {
        ApiService.getBudgetsByUserId(id).then(response => {
            setLoading(false);
            if (response.budgets.length > 0) {
                setRowDataFunctions(response.budgets);
            }
        });
    }

    function fetchCouponsByUserId() {
        ApiService.getCouponsByUserId(id).then(response => {
            setLoading(false);
            if (response.length > 0) {
                setRowDataCoupons(response);
            }
        });
    }

    const confirmOpen = () => {
        setOpenDialog(true);
    };

    function deleteFunction() {
        ApiService.deleteUserById(id).then(response => {
            navigate('/' + routes.users);
        }).catch((e) => {
            if (e.body?.code === 2) {
                setOpenDialog(false);
                setOpenSnackBar(true);
            } else {
                setErrorAlert(true);
            }
        });
    }

    function archiveUser() {
        const body = {
            id: currentUser.id,
            firstName: currentUser.firstName,
            lastName: currentUser.lastName,
            disabled: !currentUser.disabled
        }

        ApiService.updateUser(body).then(response => {
            setCurrentUser(response);
            navigate('/' + routes.userDetails + '/' + id);

        })
    }

    function openModalToAddFunction() {
        setOpenModal(true);
        ApiService.getBudgetsToAdd(id).then(response => {
            setListOfBudgets(response.budgets)
        })
    };

    function addFunction() {
        setIsFetching(true)

        ApiService.getBudgetById(functionToAdd.id).then(response => {
            const budget = response;
            let users = []
            if (budget.users.length > 0) {
                for (const user of budget.users) {
                    users.push(user.id)
                }
            }
            users.push(currentUser.id);

            let hasLimit = true;
            if (budget.amount === null) {
                hasLimit = false;
            }

            const body = {
                id: functionToAdd.id,
                name: budget.name,
                schoolYearId: budget.schoolYear.id,
                catalogId: budget.catalog.id,
                locationId: budget.location ? budget.location.id : undefined,
                users: users,
                hasLimit: hasLimit,
                primarySchoolStudents: budget.primarySchoolStudents,
                elementarySchoolFirstCycleStudents: budget.elementarySchoolFirstCycleStudents,
                elementarySchoolSecondCycleStudents: budget.elementarySchoolSecondCycleStudents,
                arbitraryAmount: budget.amount === null ? undefined : Number(budget.arbitraryAmount * 100),
                enabled: budget.enabled
            }
            ApiService.updateBudget(body).then(response => {
                setIsFetching(false)
                fetchBudgetsByUserId()
                setOpenModal(false)
                setFunctionToAdd(voidBudget);
            }).catch((e) => {
                setErrorAlert(true);
            });

        })
    }

    //dropdown button
    const handleToggle = () => {
        setOpenButtonsGroup((prevOpen) => !prevOpen);
    };
    const handleCloseButtons = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpenButtonsGroup(false);
    };

    const handleRowClickOrders = (event) => {
        const dropdownButtonClicked = event.event.target.classList.contains('dropdown-button');
        if (!dropdownButtonClicked) {
            const rowId = event.data.id;
            window.location.href = `/` + routes.orderDetails + `/${rowId}`;
        }
    };

    const handleRowClickCoupons = (event) => {
        const dropdownButtonClicked = event.event.target.classList.contains('dropdown-button');
        if (!dropdownButtonClicked) {
            const rowId = event.data.id;
            window.location.href = `/` + routes.couponDetails + `/${rowId}`;
        }
    };

    const handleRowClickFunctions = (event) => {
        const dropdownButtonClicked = event.event.target.classList.contains('dropdown-button');
        if (!dropdownButtonClicked) {
            const rowId = event.data.id;
            window.location.href = `/` + routes.functionDetails + `/${rowId}`;
        }
    };

    return (
        <>
            <Grid container sx={{mb: 4}}>
                <Grid item xs={12}>
                    <Button variant="flip"
                            onClick={() => {
                        navigate('/' + routes.users)
                    }}>
                        Indietro
                    </Button>
                </Grid>
            </Grid>

            <Grid container sx={{marginTop: 2}}>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h5" component="h2"
                                        sx={{fontWeight: '500'}}>Utente {currentUser?.firstName} {currentUser?.lastName}</Typography>
                        </Grid>

                        <Grid item xs={12} md={6} justifyContent={'flex-end'}>
                            <Box component={'div'} sx={{'display': 'flex', 'justifyContent':'flex-end'}}>
                                <ButtonGroup variant="outlined" ref={anchorRef} aria-label="split button" sx={{mt:{xs:2, md:0}}}>

                                    <Button variant="flip" sx={{px: {xs:2, md:5}}}
                                            onClick={() => navigate(('/' + routes.editUser + '/' + id))}
                                            disabled={currentUser?.disabled}>
                                        Modifica
                                    </Button>
                                    <Button
                                        size="small"
                                        aria-controls={openButtonsGroup ? 'split-button-menu' : undefined}
                                        aria-expanded={openButtonsGroup ? 'true' : undefined}
                                        aria-label="select merge strategy"
                                        aria-haspopup="menu"
                                        onClick={handleToggle}
                                        variant="flip"
                                    >
                                        <MoreVertIcon />
                                    </Button>
                                </ButtonGroup>
                                <Popper
                                    sx={{
                                        zIndex: 1,
                                    }}
                                    open={openButtonsGroup}
                                    anchorEl={anchorRef.current}
                                    role={undefined}
                                    transition
                                    disablePortal
                                    placement={"bottom-end"}
                                >
                                    {({ TransitionProps }) => (
                                        <Grow
                                            {...TransitionProps}
                                        >
                                            <Paper>
                                                <ClickAwayListener onClickAway={handleCloseButtons}>
                                                    <MenuList id="split-button-menu" autoFocusItem sx={{'minWidth': '100px'}}>
                                                        <MenuItem onClick={() => archiveUser()} sx={{'fontSize': '0.875rem', 'minHeight': '38px'}}>
                                                            {!currentUser?.disabled ? label.archive : label.restore}
                                                        </MenuItem>
                                                        <MenuItem onClick={() => confirmOpen()} sx={{'fontSize': '0.875rem', 'minHeight': '38px'}}
                                                                disabled={currentUser?.disabled}>
                                                            Elimina
                                                        </MenuItem>
                                                    </MenuList>
                                                </ClickAwayListener>
                                            </Paper>
                                        </Grow>
                                    )}
                                </Popper>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container sx={{mt: 3}} columnSpacing={3}>
                <Grid item xs={12} md={6} lg={4}>
                    <Paper
                        component={'div'}
                        elevation={0}
                        sx={{
                            borderRadius: 0,
                            borderTop: '2px solid #c23052',
                            p: 2,
                            mb: 4
                        }}
                    >
                        <Stack spacing={1.25}>
                            <Typography><Typography component={'span'}
                                                    sx={{color: 'rgba(0,0,0,.7)'}}>Nome: </Typography> {currentUser?.firstName}
                            </Typography>
                            <Typography><Typography component={'span'}
                                                    sx={{color: 'rgba(0,0,0,.7)'}}>Cognome:</Typography> {currentUser?.lastName}
                            </Typography>
                            <Typography><Typography component={'span'}
                                                    sx={{color: 'rgba(0,0,0,.7)'}}>Email:</Typography> {currentUser?.email}
                            </Typography>
                        </Stack>
                    </Paper>
                </Grid>
            </Grid>


            <Box sx={{borderBottom: 1, borderColor: 'divider', marginTop: 3}}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab value="one"
                         label="Ordini"
                         {...a11yProps('one')}
                    />s
                    <Tab value="two" label="Funzioni" {...a11yProps('two')} />
                    <Tab value="three" label="Buoni" {...a11yProps('three')} />
                </Tabs>
            </Box>

            <TabPanel value={value} index="one">
                {!loading ? <>
                    <div style={{width: '100%', height: '100%'}} className="ag-theme-material ag-custom">
                        <div style={{width: '100%', height: '100%'}}>
                            <AgGridReact
                                columnDefs={columnDefsOrders}
                                defaultColDef={defaultColDef}
                                rowData={rowDataOrders}
                                pagination={true}
                                paginationPageSize={25}
                                singleClickEdit={true}
                                domLayout={'autoHeight'}
                                localeText={agGridItalianTranslation}
                                onRowClicked={handleRowClickOrders}
                            >
                            </AgGridReact>
                        </div>
                    </div>
                </> : ''}
            </TabPanel>
            <TabPanel value={value} index="two">
                <Grid container>
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                mb: 2
                            }}
                        >
                            <ButtonGroup>
                                <Button variant="outlined" color="primary"
                                        onClick={() => openModalToAddFunction()}>
                                    Aggiungi funzione all'utente
                                </Button>
                                <Button variant="contained" color="primary"
                                        onClick={() => navigate('/' + routes.createFunctionUser + '/' + id)}>
                                    Crea nuova funzione
                                </Button>
                            </ButtonGroup>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        {!loading ? <>
                            <div style={{width: '100%', height: '100%'}} className="ag-theme-material ag-custom">
                                <div style={{width: '100%', height: '100%'}}>
                                    <AgGridReact
                                        columnDefs={columnDefsFunctions}
                                        defaultColDef={defaultColDef}
                                        rowData={rowDataFunctions}
                                        pagination={true}
                                        paginationPageSize={25}
                                        singleClickEdit={true}
                                        domLayout={'autoHeight'}
                                        localeText={agGridItalianTranslation}
                                        onRowClicked={handleRowClickFunctions}
                                    >
                                    </AgGridReact>
                                </div>
                            </div>
                        </> : ''}
                    </Grid>
                </Grid>
            </TabPanel>
            <TabPanel value={value} index="three">
                <Grid container>
                    <Grid xs={12}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                mb: 2
                            }}
                        >
                            <Button variant="contained" color="primary"
                                    onClick={() => navigate('/' + routes.createCouponUser + '/' + id)}>
                                Crea nuovo coupon
                            </Button>
                        </Box>
                    </Grid>
                    <Grid xs={12}>
                        {!loading ? <>
                            <div style={{width: '100%', height: '100%'}} className="ag-theme-material ag-custom">
                                <div style={{width: '100%', height: '100%'}}>
                                    <AgGridReact
                                        columnDefs={columnDefsCoupons}
                                        defaultColDef={defaultColDef}
                                        rowData={rowDataCoupons}
                                        pagination={true}
                                        paginationPageSize={25}
                                        singleClickEdit={true}
                                        domLayout={'autoHeight'}
                                        localeText={agGridItalianTranslation}
                                        onRowClicked={handleRowClickCoupons}
                                    >
                                    </AgGridReact>
                                </div>
                            </div>
                        </> : ''}
                    </Grid>
                </Grid>
            </TabPanel>

            <BasicDialog isOpen={openDialog} handleClose={() => handleClose(setOpenDialog)}
                         deleteFunction={deleteFunction}
                         title={warningText.titleAttention}
                         body={warningText.confirmDelete} closeLabel={warningText.noButton}
                         successLabel={warningText.deleteButton}/>

            <SnackBarAlert isOpen={openSnackBar} handleClose={() => handleClose(setOpenSnackBar)} type={'warning'}
                           title={warningText.titleAttention}
                           duration={10000}
                           body={warningText.errorDelete}/>

            <BasicDialog isOpen={errorAlert} handleClose={() => handleClose(setErrorAlert)}
                         title={warningText.titleError}
                         body={warningText.somethingWentWrong} closeLabel={warningText.okButton}
                         successLabel={undefined}/>

            <Dialog
                open={openModal}
                onClose={() => handleClose(setOpenModal)}
                aria-labelledby="openSnackBar-dialog-title"
                aria-describedby="openSnackBar-dialog-description"
                maxWidth='sm'
                fullWidth
            >
                <DialogTitle id="openSnackBar-dialog-title">
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        Aggiungi funzione all'utente
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <SelectWithSearch options={listOfBudgets} value={functionToAdd} onChange={setFunctionToAdd}
                                      required={true}
                                      multiple={false} label={"Seleziona funzione"} id={"budgetSelect"}
                                      entity={'budget'}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose(setOpenModal)} variant={'outlined'}
                            color={'secondary'}>Annulla</Button>
                    <Button disabled={!(functionToAdd) || isFetching} onClick={() => addFunction()}
                            variant={'contained'}
                            color={'primary'}>
                        Aggiungi funzione
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}