import React, {useCallback, useEffect, useState} from 'react';
import {Box, Button, Grid, MenuItem, TextField, Typography} from "@mui/material";
import ApiService from "../services/ApiService";
import {AgGridReact} from "ag-grid-react";
import {agGridItalianTranslation} from "../configuration/agGridItalianTranslation";
import BasicDialog from "../components/BasicDialog";
import {handleClose} from "../utils/ReusableFunction";
import {warningText} from "../utils/ReusableText";
import CircularProgress from "@mui/material/CircularProgress";

export default function SeasonalityReport() {
    const [rowData, setRowData] = useState([]);
    const [columnDefs, setColumnDefs] = useState([]);
    const [defaultColDef] = useState({
        resizable: true,
        flex: 1,
    });
    const [loading, setLoading] = useState(true);
    const [catalogSelected, setCatalogSelected] = useState('');
    const [listOfCatalogs, setListOfCatalogs] = useState([]);
    const [dateFrom, setDateFrom] = useState(undefined);
    const [dateTo, setDateTo] = useState(undefined);
    const [errorDateFrom, setErrorDateFrom] = useState(false);
    const [errorDateTo, setErrorDateTo] = useState(false);
    const [errorAlert, setErrorAlert] = useState(false);
    // eslint-disable-next-line
    const [uniqueMonths, setUniqueMonths] = useState([]);
    const [spinner, setSpinner] = useState(false);

    // eslint-disable-next-line
    const columnDefsSeasonality = [
        {
            headerName: "Codice Prodotto",
            field: "productCode",
            sortable: true,
            minWidth: 100,
            maxWidth: 150,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Nome prodotto",
            field: "productName",
            sortable: true,
            minWidth: 200,
            filter: 'agNumberColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Categoria",
            field: "categoryName",
            sortable: true,
            minWidth: 200,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Totale pezzi ordinati",
            field: "totalQuantity",
            sortable: true,
            minWidth: 100,
            maxWidth: 200,
            filter: 'agNumberColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        ...getMonthColumns()
    ]

    function getMonthColumns() {
        const quantityMonths = [];

        rowData.forEach(item => {
            item.quantityMonths.forEach(month => {
                if (!quantityMonths.includes(month.period)) {
                    quantityMonths.push(month.period);
                }
            });
        });

        const monthColumns = quantityMonths.map(month => {
            return {
                headerName: month,
                field: `quantityMonths_${month}`,
                sortable: true,
                minWidth: 100,
                maxWidth: 150,
                filter: 'agNumberColumnFilter',
                filterParams: {suppressAndOrCondition: true},
                valueGetter: (params) => {
                    const monthData = params.data.quantityMonths.find(item => item.period === month);
                    return monthData ? monthData.quantity : '';
                }
            };
        });

        return monthColumns;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fetchReport = useCallback(async () => {
        await getSeasonalityReport().then(response => {
            setColumnDefs(columnDefsSeasonality);
            if (response) {
                setRowData(response);
            }
        });
        // eslint-disable-next-line
    }, [columnDefsSeasonality]);

    useEffect(() => {
        ApiService.getCatalogs().then(response => {
            setListOfCatalogs(response);
        });
    }, []);

    useEffect(() => {
        setColumnDefs(columnDefsSeasonality);
    }, [columnDefsSeasonality]);

    const getSeasonalityReport = async () => {
        setSpinner(true);
        return await ApiService.getSeasonalityReport(dateFrom, dateTo, catalogSelected).then(response => {
            setLoading(false);
            setSpinner(false);
            setColumnDefs(columnDefsSeasonality);
            if (response.length > 0) {
                const months = response.reduce((monthsList, item) => {
                    item.quantityMonths.forEach(quantityMonth => {
                        if (!monthsList.includes(quantityMonth.period)) {
                            monthsList.push(quantityMonth.period);
                        }
                    });
                    return monthsList;
                }, []);

                setUniqueMonths(months);

                return response;
            } else {
                return [];
            }
        }).catch((e) => setErrorAlert(true));
    }

    function checkDateFrom(value) {
        const endDate = new Date(dateTo);
        const startDate = new Date(value);

        setDateFrom(value);

        if (startDate >= endDate) {
            setErrorDateFrom(true);
        } else {
            if (errorDateTo) {
                setErrorDateTo(false);
            } else {
                setErrorDateFrom(false);
            }
        }

    }

    function checkDateTo(value) {
        const startDate = new Date(dateFrom);
        const endDate = new Date(value);

        setDateTo(value);

        if (startDate >= endDate) {
            setErrorDateTo(true);
        } else {
            if (errorDateFrom) {
                setErrorDateFrom(false);
            } else {
                setErrorDateTo(false);
            }
        }
    }

    return (<>
            <Grid container sx={{mt: 2}} justifyContent={'space-between'}>
                <Grid item xs="auto">
                    <Typography variant="h5" component="h2" sx={{fontWeight: '500'}}>Stagionalità</Typography>
                </Grid>
            </Grid>

            <Grid container sx={{mt: 3}}>
                <Grid item xs={12} md={12} lg={12}>
                    <Box
                        sx={{
                            background: '#EFEFEF',
                            p: 1,
                            mb: 2
                        }}
                    >
                        <Grid container spacing={1}>
                            <Grid item xs={5} sm>
                                <TextField
                                    select
                                    id="userSelected"
                                    value={catalogSelected || ''}
                                    label={'Seleziona un catalogo'}
                                    onChange={(e) => setCatalogSelected(e.target.value)}
                                    fullWidth
                                    required
                                    variant="standard"
                                >
                                    <MenuItem value={''}>Nessuna selezione</MenuItem>
                                    {listOfCatalogs.length > 0 ?
                                        listOfCatalogs.map((catalog) => (
                                            <MenuItem key={catalog.id}
                                                      value={catalog.id}>{catalog.name}</MenuItem>
                                        ))
                                        :
                                        <></>
                                    }
                                </TextField>
                            </Grid>
                            <Grid item xs={3} sm>
                                {errorDateFrom ?
                                    <TextField type="date"
                                               format="dd.MM.yyyy"
                                               error
                                               fullWidth
                                               id="from"
                                               required
                                               label="Dal"
                                               defaultValue={dateFrom}
                                               helperText="La data d'inizio non può essere superiore alla data fine"
                                               variant="standard"
                                               onChange={(e) => checkDateFrom(e.target.value)}
                                               InputLabelProps={{shrink: true}}
                                    />
                                    :
                                    <TextField id="from" label="Dal" InputLabelProps={{shrink: true}}
                                               type="date"
                                               value={dateFrom}
                                               required
                                               variant="standard"
                                               inputFormat="dd.MM.yyyy"
                                               onChange={(e) => checkDateFrom(e.target.value)} fullWidth/>
                                }
                            </Grid>
                            <Grid item xs={3} sm={3}>
                                {errorDateTo ?
                                    <TextField type="date"
                                               format="dd.MM.yyyy"
                                               error
                                               required
                                               fullWidth
                                               id="to"
                                               label="Al"
                                               defaultValue={dateTo}
                                               helperText="La data di fine non può essere superiore alla data d'inizio"
                                               variant="standard"
                                               onChange={(e) => checkDateTo(e.target.value)}
                                               InputLabelProps={{shrink: true}}/>
                                    :
                                    <TextField id="to" label="Al" InputLabelProps={{shrink: true}}
                                               type="date"
                                               value={dateTo}
                                               required
                                               variant="standard"
                                               inputFormat="dd.MM.yyyy"
                                               onChange={(e) => checkDateTo(e.target.value)} fullWidth/>
                                }
                            </Grid>
                            <Grid item xs={1} sm={2}>
                                <Button variant="contained" color="primary" fullWidth sx={{mt: 1}}
                                        disabled={!(catalogSelected && dateFrom && dateTo) || spinner}
                                        onClick={() => fetchReport()}>
                                    {spinner ? <CircularProgress size={20} color="inherit"/> : "Genera report"}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>

                    {!loading ? <>
                        <div style={{width: '100%', height: '100%'}} className="ag-theme-material ag-custom">
                            <div style={{width: '100%', height: '100%'}}>
                                <AgGridReact
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    rowData={rowData}
                                    pagination={true}
                                    paginationPageSize={25}
                                    singleClickEdit={true}
                                    domLayout={'autoHeight'}
                                    localeText={agGridItalianTranslation}
                                >
                                </AgGridReact>
                            </div>
                        </div>
                    </> : ''}
                </Grid>
            </Grid>

            <BasicDialog isOpen={errorAlert} handleClose={() => handleClose(setErrorAlert)}
                         title={warningText.titleError}
                         body={warningText.somethingWentWrong} closeLabel={warningText.okButton}
                         successLabel={undefined}/>
        </>
    );
}