import React, {useCallback, useEffect, useState} from 'react';
import {Box, Button, Grid, MenuItem, TextField, Typography} from "@mui/material";
import ApiService from "../services/ApiService";
import {AgGridReact} from "ag-grid-react";
import {agGridItalianTranslation} from "../configuration/agGridItalianTranslation";
import BasicDialog from "../components/BasicDialog";
import {columnDefsCouponTotalSpend} from "../utils/agGridDefs";
import {handleClose} from "../utils/ReusableFunction";
import {warningText} from "../utils/ReusableText";
import {NumericFormat} from "react-number-format";
import CircularProgress from "@mui/material/CircularProgress";

export default function CouponsTotalSpendReport() {
    const [rowData, setRowData] = useState([]);
    const [columnDefs, setColumnDefs] = useState([]);
    const [defaultColDef] = useState({
        resizable: true,
        flex: 1,
    });
    const [loading, setLoading] = useState(true);
    const [percentage, setPercentage] = useState(undefined);
    const [periodSelected, setPeriodSelected] = useState('');
    const [listOfPeriods, setListOfPeriods] = useState([]);
    const [spinner, setSpinner] = useState(false);
    const [errorAlert, setErrorAlert] = useState(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fetchReport = useCallback(() => {
        getCouponTotalSpendReport().then(response => {
            setColumnDefs(columnDefsCouponTotalSpend);
            if (response) {
                setRowData(response);
            }
        });
    });

    const getCouponTotalSpendReport = async () => {
        setSpinner(true);
        const filter = {
            schoolYearId: periodSelected,
            threshold: percentage
        }

        return await ApiService.getCouponTotalSpendReport(filter).then(response => {
            setSpinner(false);
            setLoading(false);
            setColumnDefs(columnDefsCouponTotalSpend);
            if (response.length > 0) {
                return response;
            } else {
                return [];
            }
        }).catch((e) => setErrorAlert(true));
    }


    useEffect(() => {
        ApiService.getPeriods().then(response => {
            setListOfPeriods(response);
        });
    }, []);

    return (<>
            <Grid container sx={{mt: 2}} justifyContent={'space-between'}>
                <Grid item xs="auto">
                    <Typography variant="h5" component="h2" sx={{fontWeight: '500'}}>Proporzione buoni su spesa
                        totale</Typography>
                </Grid>
            </Grid>

            <Grid container sx={{mt: 3}}>
                <Grid item xs={12} md={12} lg={12}>
                    <Box
                        sx={{
                            background: '#EFEFEF',
                            p: 1,
                            mb: 2
                        }}
                    >
                        <Grid container spacing={1}>
                            <Grid item xs={6} sm={6}>
                                <TextField
                                    select
                                    id="periodSelected"
                                    value={periodSelected || ''}
                                    label={'Seleziona periodo'}
                                    onChange={(e) => setPeriodSelected(e.target.value)}
                                    required
                                    fullWidth
                                    variant="standard"
                                >
                                    {listOfPeriods.length > 0 ?
                                        listOfPeriods.map((period) => (
                                            <MenuItem key={period.id}
                                                      value={period.id}>{period.name}</MenuItem>
                                        ))
                                        :
                                        <></>
                                    }
                                </TextField>
                            </Grid>
                            <Grid item xs={4} sm={4}>
                                <NumericFormat
                                    allowNegative={false}
                                    decimalScale={0}
                                    customInput={TextField} id="percentage"
                                    label="Inserisci percentuale %"
                                    defaultValue={percentage}
                                    value={percentage}
                                    required
                                    variant="standard"
                                    onChange={e => setPercentage(Number(e.target.value))}
                                    fullWidth/>
                            </Grid>
                            <Grid item xs={2} sm={2}>
                                <Button variant="contained" color="primary" fullWidth sx={{mt: 1}}
                                        disabled={!(percentage && periodSelected) || spinner}
                                        onClick={() => fetchReport()}>
                                    {spinner ? <CircularProgress size={20} color="inherit"/> : "Genera report"}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>

                    {!loading ? <>
                        <div style={{width: '100%', height: '100%'}} className="ag-theme-material ag-custom">
                            <div style={{width: '100%', height: '100%'}}>
                                <AgGridReact
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    rowData={rowData}
                                    pagination={true}
                                    paginationPageSize={25}
                                    singleClickEdit={true}
                                    domLayout={'autoHeight'}
                                    localeText={agGridItalianTranslation}
                                >
                                </AgGridReact>
                            </div>
                        </div>
                    </> : ''}
                </Grid>
            </Grid>

            <BasicDialog isOpen={errorAlert} handleClose={() => handleClose(setErrorAlert)}
                         title={warningText.titleError}
                         body={warningText.somethingWentWrong} closeLabel={warningText.okButton}
                         successLabel={undefined}/>
        </>
    );
}