import {Box, Button, FormControlLabel, Grid, MenuItem, Stack, Switch, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import ApiService from "../services/ApiService";
import {NumericFormat} from "react-number-format";
import BasicDialog from "../components/BasicDialog";
import SelectWithSearch from "../components/SelectWithSearch";
import {routes, warningText} from "../utils/ReusableText";
import {handleClose} from "../utils/ReusableFunction";

export default function CreateFunctionUser() {
    const navigate = useNavigate();
    const path = useLocation();
    const id = path.pathname.split('/')[2];
    const [name, setName] = useState(undefined);
    const [schoolYear, setSchoolYear] = useState(undefined);
    const [users, setUsers] = useState([]);
    const [studentsPrimary, setStudentsPrimary] = useState(0);
    const [studentsFirstClass, setStudentsFirstClass] = useState(0);
    const [studentsSecondClass, setStudentsSecondClass] = useState(0);
    const [arbitraryAmount, setArbitraryAmount] = useState(0);
    const [hasLimit, setHasLimit] = useState(true);
    const [catalog, setCatalog] = useState(undefined);
    const [location, setLocation] = useState('');
    const [listOfSchoolYear, setListOfSchoolYear] = useState(undefined);
    const [listOfLocations, setListOfLocations] = useState(undefined);
    const [listOfCatalogs, setListOfCatalogs] = useState(undefined);
    const [listOfUsers, setListOfUsers] = useState([]);
    const [errorAlert, setErrorAlert] = useState(false);
    const [alreadyClicked, setAlreadyClicked] = useState(false);
    const label = {inputProps: {'aria-label': 'Color switch demo'}};
    const [currUser, setCurrUser] = useState(undefined)

    useEffect(() => {
        ApiService.getUserById(id).then(res => {
           setCurrUser(res);
           const arrUsers = [];
           arrUsers.push(res);
           setUsers(arrUsers);
            ApiService.getUsers().then(response => {
                setListOfUsers(response);
            });

            ApiService.getPeriods().then(periods => {
                if (periods.length > 0) {
                    setListOfSchoolYear(periods)
                }
            })

            ApiService.getCatalogs().then(catalogs => {
                if (catalogs.length > 0) {
                    setListOfCatalogs(catalogs);
                }
            })

            ApiService.getLocations().then(locations => {
                if (locations.length > 0) {
                    setListOfLocations(locations);
                }
            })
        });
    }, [id])

    function saveFunction() {
        setAlreadyClicked(true);

        let usersIdArray = [];
        for (const user of users) {
            if (user.id && !usersIdArray.includes(user.id)) {
                usersIdArray.push(user.id);
            }
        }

        const body = {
            name: name,
            schoolYearId: schoolYear,
            catalogId: catalog,
            locationId: location === '' ? undefined : location,
            users: usersIdArray,
            hasLimit: hasLimit,
            primarySchoolStudents: Number(studentsPrimary),
            elementarySchoolFirstCycleStudents: Number(studentsFirstClass),
            elementarySchoolSecondCycleStudents: Number(studentsSecondClass),
            arbitraryAmount: Number(arbitraryAmount * 100)
        }
        ApiService.createBudgets(body).then(response => {
            navigate('/' + routes.userDetails + '/' + id)
        }).catch((e) => {
            setAlreadyClicked(false);
            setErrorAlert(true);
        });
    }

    function checkMandatoryFunctionFields() {
        return !(name && schoolYear && catalog && hasLimit !== undefined);
    }

    return (<>

        <Grid container sx={{marginTop: 2}}>
            <Grid item xs={12}>
                <Typography variant="h5" component="h2"
                            sx={{fontWeight: '500'}}>{'Nuova Funzione ' + currUser?.fullName}</Typography>
            </Grid>
        </Grid>

        <Grid container sx={{mt: 4}} columnSpacing={2.5}>
            <Grid item xs={12} md={8} lg={4}>
                <Box component={'form'}>
                    <Stack spacing={1.5} sx={{mb: {xs: 3, md: 0}}}>
                        <TextField id="name" label="Nome funzione" value={name} defaultValue={name}
                                   variant="standard" fullWidth
                                   onChange={e => setName(e?.target?.value)}
                                   InputLabelProps={''} required/>
                        <TextField
                            select
                            id="schoolYearIdSelect"
                            label={'Seleziona periodo'}
                            required
                            fullWidth
                            variant="standard"
                            value={schoolYear || ''}
                            onChange={(e) => setSchoolYear(e?.target?.value)}
                        >
                            {listOfSchoolYear?.length > 0 ? listOfSchoolYear.map((schoolYear) => (
                                <MenuItem key={schoolYear.id}
                                          value={schoolYear.id}>{schoolYear.name}</MenuItem>)) : ''}
                        </TextField>
                        <TextField
                            select
                            id="catalogIdSelect"
                            label={'Seleziona catalogo'}
                            required
                            fullWidth
                            variant="standard"
                            value={catalog || ''}
                            onChange={(e) => setCatalog(e?.target?.value)}
                        >
                            {listOfCatalogs?.length > 0 ? listOfCatalogs.map((catalog) => (
                                <MenuItem key={catalog.id} value={catalog.id}>{catalog.name}</MenuItem>)) : ''}
                        </TextField>
                        <TextField
                            select
                            id="locationIdSelect"
                            label={'Seleziona sede'}
                            fullWidth
                            variant="standard"
                            value={location || ''}
                            onChange={(e) => setLocation(e?.target?.value)}
                        >
                            <MenuItem value={''}>Nessuna selezione</MenuItem>)) : ''
                            {listOfLocations?.length > 0 ? listOfLocations.map((location) => (
                                <MenuItem key={location.id} value={location.id}>{location.name}</MenuItem>)) : ''}
                        </TextField>

                        <SelectWithSearch options={listOfUsers} value={users} onChange={setUsers} required={false}
                                          multiple={true} label={"Seleziona utente"} id={"userSelect"} entity={'user'}/>

                        <FormControlLabel control={<Switch {...label} defaultChecked/>} checked={!hasLimit}
                                          label="Budget Illimitato" onChange={(e) => setHasLimit(!e.target.checked)}/>

                        {hasLimit ?
                            <>
                                <NumericFormat
                                    allowNegative={false}
                                    decimalScale={0}
                                    customInput={TextField} id="quantity"
                                    label="N. studenti SI"
                                    defaultValue={studentsPrimary}
                                    value={studentsPrimary}
                                    variant="standard"
                                    onChange={e => setStudentsPrimary(e?.target?.value)}
                                    fullWidth/>
                                <NumericFormat
                                    allowNegative={false}
                                    decimalScale={0}
                                    customInput={TextField} id="studentsFirstClass"
                                    label="N. studenti SE 1° ciclo"
                                    defaultValue={studentsFirstClass}
                                    value={studentsFirstClass}
                                    variant="standard"
                                    onChange={e => setStudentsFirstClass(e?.target?.value)}
                                    fullWidth/>
                                <NumericFormat
                                    allowNegative={false}
                                    decimalScale={0}
                                    customInput={TextField} id="studentsSecondClass"
                                    label="N. studenti SE 2° ciclo"
                                    defaultValue={studentsSecondClass}
                                    value={studentsSecondClass}
                                    variant="standard"
                                    onChange={e => setStudentsSecondClass(e?.target?.value)}
                                    fullWidth/>
                                <NumericFormat decimalSeparator="."
                                               fixedDecimalScale={true}
                                               allowNegative={false}
                                               decimalScale={2}
                                               customInput={TextField} id="arbitraryAmount"
                                               label="Budget aggiuntivo (CHF)"
                                               defaultValue={arbitraryAmount}
                                               value={arbitraryAmount}
                                               variant="standard"
                                               onChange={e => setArbitraryAmount(e?.target?.value)}
                                               fullWidth required/>
                            </>
                            :
                            ''
                        }

                    </Stack>
                </Box>

                <Grid container sx={{mt: 3}} justifyContent={'space-between'}>
                    <Grid item>
                        <Button variant="flip" onClick={() => {
                            navigate('/' + routes.functions)
                        }}>
                            Cancella
                        </Button>

                    </Grid>
                    <Grid item>
                            <Button variant="contained" color="primary"
                                    disabled={checkMandatoryFunctionFields() || alreadyClicked}
                                    onClick={() => {
                                        saveFunction()
                                    }}>Crea</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

        <BasicDialog isOpen={errorAlert} handleClose={() => handleClose(setErrorAlert)} title={warningText.titleError}
                     body={warningText.somethingWentWrong} closeLabel={warningText.okButton} successLabel={undefined}/>
    </>);
}