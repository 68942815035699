import {
    colorFormatter,
    enabledFormatter,
    periodFormatter,
    statusFormatter,
    userDisabledFormatter
} from "./agGridFormatterFunctions";
import {italianEnabled, originalStatus, routes, stockStatus} from "./ReusableText";
import {Grid} from "@mui/material";
import {Link} from "react-router-dom";
import React from "react";
import {confirmStockDelete} from "../pages/ProductDetails";
import DropdownTableButton from "../configuration/DropdownTableButton";

export const dataLocations = {
    columnDefs: [
        {
            headerName: "Nome sede",
            field: "name",
            minWidth: 200,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Indirizzo",
            field: "street",
            minWidth: 300,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Cap",
            field: "zip",
            minWidth: 100,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Città",
            field: "city",
            minWidth: 200,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Archiviato",
            field: "enabled",
            cellRenderer: enabledFormatter,
            minWidth: 100,
            sortable: true,
            filter: 'agSetColumnFilter',
            filterParams: {
                suppressAndOrCondition: true,
                cellRenderer: function (params) {
                    return params.value ? italianEnabled.false : italianEnabled.true;
                },
                suppressSelectAll: true
            },
        },
        {
            headerName: "", suppressMenu: true, pinned: 'right', lockPinned: true, cellRenderer: function (params) {
                return (
                    <Grid container spacing={1} justifyContent={'flex-end'}>
                        <Grid item>
                            <Link className={'muiBtn muiBtnPrimary'}
                                  to={'/' + routes.locationDetails + '/' + params.data.id}>
                                Visualizza
                            </Link>
                        </Grid>
                        {params.data.enabled ?
                            <Grid item>
                                <Link to={'/' + routes.editLocation + '/' + params.data.id}
                                      className={'muiBtn muiBtnSecondary'}>
                                    Modifica
                                </Link>
                            </Grid>
                            :
                            ''
                        }
                    </Grid>
                )
            },
            suppressSizeToFit: true,
            minWidth: 220
        }
    ],
    defaultColDef: {
        resizable: true,
        flex: 1,
    },
    rowData: []
}

export const dataCatalogs = {
    columnDefs: [
        {
            headerName: "Nome catalogo",
            field: "name",
            sortable: true,
            minWidth: 200,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Descrizione",
            field: "description",
            sortable: true,
            minWidth: 200,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Archiviato",
            field: "enabled",
            cellRenderer: enabledFormatter,
            minWidth: 100,
            sortable: true,
            filter: 'agSetColumnFilter',
            filterParams: {
                suppressAndOrCondition: true,
                cellRenderer: function (params) {
                    return params.value ? italianEnabled.false : italianEnabled.true;
                },
                suppressSelectAll: true
            },
        },
        {
            headerName: "", suppressMenu: true, pinned: 'right', lockPinned: true, cellRenderer: function (params) {
                return (
                    <Grid container spacing={1} justifyContent={'flex-end'}>
                        <Grid item>
                            <Link to={'/' + routes.catalogDetails + '/' + params.data.id}
                                  className={'muiBtn muiBtnPrimary'}>
                                Visualizza
                            </Link>
                        </Grid>
                        {params.data.enabled ?
                            <Grid item>
                                <Link to={'/' + routes.editCatalog + '/' + params.data.id}
                                      className={'muiBtn muiBtnSecondary'}>
                                    Modifica
                                </Link>
                            </Grid>
                            :
                            ''
                        }
                    </Grid>
                )
            },
            suppressSizeToFit: true,
            minWidth: 220
        }
    ],
    defaultColDef: {
        resizable: true,
        flex: 1,
    },
    rowData: []
}

export const dataCategories = {
    columnDefs: [
        {
            headerName: "Nome categoria",
            field: "name",
            sortable: true,
            minWidth: 200,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Descrizione",
            field: "description",
            sortable: true,
            minWidth: 200,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Archiviato",
            field: "enabled",
            cellRenderer: enabledFormatter,
            minWidth: 100,
            sortable: true,
            filter: 'agSetColumnFilter',
            filterParams: {
                suppressAndOrCondition: true,
                cellRenderer: function (params) {
                    return params.value ? italianEnabled.false : italianEnabled.true;
                },
                suppressSelectAll: true
            },
        },
        {
            headerName: "", suppressMenu: true, pinned: 'right', lockPinned: true, cellRenderer: function (params) {
                return (
                    <Grid container spacing={1} justifyContent={'flex-end'}>
                        <Grid item>
                            <Link to={'/' + routes.categoryDetails + '/' + params.data.id}
                                  className={'muiBtn muiBtnPrimary'}>
                                Visualizza
                            </Link>
                        </Grid>
                        {params.data.enabled ?
                            <Grid item>
                                <Link to={'/' + routes.editCategory + '/' + params.data.id}
                                      className={'muiBtn muiBtnSecondary'}>
                                    Modifica
                                </Link>
                            </Grid>
                            :
                            ''
                        }
                    </Grid>
                )
            },
            suppressSizeToFit: true,
            minWidth: 220
        }
    ],
    defaultColDef: {
        resizable: true,
        flex: 1,
    },
    rowData: []
}

export const dataCoupons = {
    columnDefs: [
        {
            headerName: "ID Buono",
            field: "idComment",
            minWidth: 80,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Descrizione",
            field: "description",
            minWidth: 160,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Fornitore",
            field: "supplier",
            minWidth: 120,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Utente",
            field: "owner.fullName",
            minWidth: 120,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Funzione",
            field: "budget.name",
            minWidth: 120,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Valore (CHF)",
            field: "amountFormatted",
            minWidth: 60,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Archiviato",
            field: "enabled",
            cellRenderer: enabledFormatter,
            minWidth: 100,
            sortable: true,
            filter: 'agSetColumnFilter',
            filterParams: {
                suppressAndOrCondition: true,
                cellRenderer: function (params) {
                    return params.value ? italianEnabled.false : italianEnabled.true;
                },
                suppressSelectAll: true
            },
        },
        {
            headerName: "", suppressMenu: true, pinned: 'right', lockPinned: true, cellRenderer: function (params) {
                return (
                    <Grid container spacing={1} justifyContent={'flex-end'}>
                        <Grid item>
                            <Link to={'/' + routes.couponDetails + '/' + params.data.id}
                                  className={'muiBtn muiBtnPrimary'}>
                                Visualizza
                            </Link>
                        </Grid>
                        {params.data.enabled ?
                            <Grid item>
                                <Link to={'/' + routes.editCoupon + '/' + params.data.id}
                                      className={'muiBtn muiBtnSecondary'}>
                                    Modifica
                                </Link>
                            </Grid>
                            :
                            ''
                        }

                    </Grid>

                )
            },
            suppressSizeToFit: true,
            minWidth: 220
        }
    ], defaultColDef: {
        resizable: true,
        flex: 1,
    },
    rowData: []
}

export const dataFunctions = {
    columnDefs: [
        {
            headerName: "Nome funzione",
            field: "name",
            minWidth: 200,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Budget attuale (CHF)",
            field: "availableAmountFormatted",
            minWidth: 200,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Budget iniziale (CHF)",
            field: "amountFormatted",
            minWidth: 200,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Budget aggiuntivo (CHF)",
            field: "arbitraryAmountFormatted",
            minWidth: 200,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "N. studenti SI",
            field: "primarySchoolStudents",
            minWidth: 130,
            sortable: true,
            filter: 'agNumberColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "N. studenti SE 1° ciclo",
            field: "elementarySchoolFirstCycleStudents",
            minWidth: 130,
            sortable: true,
            filter: 'agNumberColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "N. studenti SE 2° ciclo",
            field: "elementarySchoolSecondCycleStudents",
            minWidth: 130,
            sortable: true,
            filter: 'agNumberColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Validità periodo",
            field: "schoolYear",
            cellRenderer: periodFormatter,
            minWidth: 200,
            sortable: true,
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Archiviato",
            field: "enabled",
            cellRenderer: enabledFormatter,
            minWidth: 100,
            sortable: true,
            filter: 'agSetColumnFilter',
            filterParams: {
                suppressAndOrCondition: true,
                cellRenderer: function (params) {
                    return params.value ? italianEnabled.false : italianEnabled.true;
                },
                suppressSelectAll: true
            },
        },
        {
            headerName: "", suppressMenu: true, pinned: 'right', lockPinned: true, cellRenderer: function (params) {
                return (
                    <Grid container spacing={1} justifyContent={'flex-end'}>
                        <Grid item>
                            <Link className={'muiBtn muiBtnPrimary'}
                                  to={'/' + routes.functionDetails + '/' + params.data.id}>
                                Visualizza
                            </Link>
                        </Grid>
                        {params.data.enabled ?
                            <Grid item>
                                <Link className={'muiBtn muiBtnSecondary'}
                                      to={'/' + routes.editFunction + '/' + params.data.id}>
                                    Modifica
                                </Link>
                            </Grid>
                            :
                            ''
                        }
                    </Grid>
                )
            },
            minWidth: 220,
            suppressSizeToFit: true,
        },
    ],
    defaultColDef: {
        resizable: true,
        flex: 1,
    },
    rowData: []
}

export const columnFunctions = [
    {
        headerName: "Nome funzione",
        field: "name",
        minWidth: 200,
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: {suppressAndOrCondition: true}
    },
    {
        headerName: "Budget attuale (CHF)",
        field: "availableAmountFormatted",
        minWidth: 200,
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: {suppressAndOrCondition: true}
    },
    {
        headerName: "Budget iniziale (CHF)",
        field: "amountFormatted",
        minWidth: 200,
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: {suppressAndOrCondition: true}
    },
    {
        headerName: "Budget aggiuntivo (CHF)",
        field: "arbitraryAmountFormatted",
        minWidth: 200,
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: {suppressAndOrCondition: true}
    },
    {
        headerName: "N. studenti SI",
        field: "primarySchoolStudents",
        minWidth: 130,
        sortable: true,
        filter: 'agNumberColumnFilter',
        filterParams: {suppressAndOrCondition: true}
    },
    {
        headerName: "N. studenti SE 1° ciclo",
        field: "elementarySchoolFirstCycleStudents",
        minWidth: 130,
        sortable: true,
        filter: 'agNumberColumnFilter',
        filterParams: {suppressAndOrCondition: true}
    },
    {
        headerName: "N. studenti SE 2° ciclo",
        field: "elementarySchoolSecondCycleStudents",
        minWidth: 130,
        sortable: true,
        filter: 'agNumberColumnFilter',
        filterParams: {suppressAndOrCondition: true}
    },
    {
        headerName: "Validità periodo",
        field: "schoolYear",
        cellRenderer: periodFormatter,
        minWidth: 200,
        sortable: true,
        filterParams: {suppressAndOrCondition: true}
    },
    {
        headerName: "Archiviato",
        field: "enabled",
        cellRenderer: enabledFormatter,
        minWidth: 100,
        sortable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
            suppressAndOrCondition: true,
            cellRenderer: function (params) {
                return params.value ? italianEnabled.false : italianEnabled.true;
            },
            suppressSelectAll: true
        },
    },
    {
        headerName: "", suppressMenu: true, pinned: 'right', lockPinned: true, cellRenderer: function (params) {
            return (
                <Grid container spacing={1} justifyContent={'flex-end'}>
                    <Grid item>
                        <Link className={'muiBtn muiBtnPrimary'}
                              to={'/' + routes.functionDetails + '/' + params.data.id}>
                            Visualizza
                        </Link>
                    </Grid>
                    {params.data.enabled ?
                        <Grid item>
                            <Link className={'muiBtn muiBtnSecondary'}
                                  to={'/' + routes.editFunction + '/' + params.data.id}>
                                Modifica
                            </Link>
                        </Grid>
                        :
                        ''
                    }
                </Grid>
            )
        },
        minWidth: 220,
        suppressSizeToFit: true,
    }
]

export const logTypes = [
    {
        id: 1,
        label: 'Forniture',
        name: 'STOCK',
        data: {
            columnDefs: [
                {
                    headerName: "Utente",
                    field: "user.fullName",
                    sortable: true,
                    minWidth: 200,
                    filter: 'agTextColumnFilter',
                    filterParams: {suppressAndOrCondition: true}
                },
                {
                    headerName: "Azione",
                    field: "operation",
                    sortable: true,
                    minWidth: 200,
                    filter: 'agTextColumnFilter',
                    filterParams: {suppressAndOrCondition: true}
                },
                {
                    headerName: "Data e ora operazione",
                    field: "createdAtFormatted",
                    sortable: true,
                    minWidth: 200,
                    filter: 'agTextColumnFilter',
                    filterParams: {suppressAndOrCondition: true}
                },
                {
                    headerName: "Codice Prodotto",
                    field: "stockLine.product.code",
                    sortable: true,
                    minWidth: 200,
                    filter: 'agTextColumnFilter',
                    filterParams: {suppressAndOrCondition: true}
                },
                {
                    headerName: "Nome Prodotto",
                    field: "stockLine.product.name",
                    sortable: true,
                    minWidth: 200,
                    filter: 'agTextColumnFilter',
                    filterParams: {suppressAndOrCondition: true}
                },
                {
                    headerName: "Tipologia",
                    field: "stockLine.statusLabel",
                    sortable: true,
                    minWidth: 200,
                    filter: 'agTextColumnFilter',
                    filterParams: {suppressAndOrCondition: true}
                }
            ]
        }
    }, {
        id: 2,
        label: 'Prodotti',
        name: 'PRODUCT',
        data: {
            columnDefs: [
                {
                    headerName: "Utente",
                    field: "user.fullName",
                    sortable: true,
                    minWidth: 200,
                    filter: 'agTextColumnFilter',
                    filterParams: {suppressAndOrCondition: true}
                },
                {
                    headerName: "Azione",
                    field: "operation",
                    sortable: true,
                    minWidth: 200,
                    filter: 'agTextColumnFilter',
                    filterParams: {suppressAndOrCondition: true}
                },
                {
                    headerName: "Data e ora operazione",
                    field: "createdAtFormatted",
                    sortable: true,
                    minWidth: 200,
                    filter: 'agTextColumnFilter',
                    filterParams: {suppressAndOrCondition: true}
                },
                {
                    headerName: "Codice Prodotto",
                    field: "product.code",
                    sortable: true,
                    minWidth: 200,
                    filter: 'agTextColumnFilter',
                    filterParams: {suppressAndOrCondition: true}
                },
                {
                    headerName: "Nome Prodotto",
                    field: "product.name",
                    sortable: true,
                    minWidth: 200,
                    filter: 'agTextColumnFilter',
                    filterParams: {suppressAndOrCondition: true}
                }
            ]
        }
    }, {
        id: 3,
        label: 'Ordini',
        name: 'ORDER',
        data: {
            columnDefs: [
                {
                    headerName: "Utente",
                    field: "user.fullName",
                    sortable: true,
                    minWidth: 200,
                    filter: 'agTextColumnFilter',
                    filterParams: {suppressAndOrCondition: true}
                },
                {
                    headerName: "Azione",
                    field: "operation",
                    sortable: true,
                    minWidth: 200,
                    filter: 'agTextColumnFilter',
                    filterParams: {suppressAndOrCondition: true}
                },
                {
                    headerName: "Data e ora operazione",
                    field: "createdAtFormatted",
                    sortable: true,
                    minWidth: 200,
                    filter: 'agTextColumnFilter',
                    filterParams: {suppressAndOrCondition: true}
                },
                {
                    headerName: "ID Ordine",
                    field: "order.id",
                    sortable: true,
                    minWidth: 200,
                    filter: 'agTextColumnFilter',
                    filterParams: {suppressAndOrCondition: true}
                }
            ]
        }
    }, {
        id: 4,
        label: 'Buoni acquisto',
        name: 'COUPON',
        data: {
            columnDefs: [
                {
                    headerName: "Utente",
                    field: "user.fullName",
                    sortable: true,
                    minWidth: 200,
                    filter: 'agTextColumnFilter',
                    filterParams: {suppressAndOrCondition: true}
                },
                {
                    headerName: "Azione",
                    field: "operation",
                    sortable: true,
                    minWidth: 200,
                    filter: 'agTextColumnFilter',
                    filterParams: {suppressAndOrCondition: true}
                },
                {
                    headerName: "Data e ora operazione",
                    field: "createdAtFormatted",
                    sortable: true,
                    minWidth: 200,
                    filter: 'agTextColumnFilter',
                    filterParams: {suppressAndOrCondition: true}
                },
                {
                    headerName: "ID Buono",
                    field: "coupon.idComment",
                    sortable: true,
                    minWidth: 200,
                    filter: 'agTextColumnFilter',
                    filterParams: {suppressAndOrCondition: true}
                },
                {
                    headerName: "Nome Buono",
                    field: "coupon.description",
                    sortable: true,
                    minWidth: 200,
                    filter: 'agTextColumnFilter',
                    filterParams: {suppressAndOrCondition: true}
                }
            ]
        }
    }];

export const dataPeriods = {
    columnDefs: [
        {
            headerName: "Nome periodo",
            field: "name",
            sortable: true,
            minWidth: 150,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Data d'inizio",
            field: "startFormatted",
            minWidth: 130,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Data di fine",
            field: "endFormatted",
            minWidth: 130,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Budget pro capite SI (CHF)",
            field: "primarySchoolStudentBudgetFormatted",
            minWidth: 100, sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Budget pro capite SE 1° ciclo (CHF)",
            field: "elementarySchoolFirstCycleStudentBudgetFormatted",
            minWidth: 100, sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Budget pro capite SE 2° ciclo (CHF)",
            field: "elementarySchoolSecondCycleStudentBudgetFormatted",
            minWidth: 100, sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Archiviato",
            field: "enabled",
            cellRenderer: enabledFormatter,
            minWidth: 100,
            sortable: true,
            filter: 'agSetColumnFilter',
            filterParams: {
                suppressAndOrCondition: true,
                cellRenderer: function (params) {
                    return params.value ? italianEnabled.false : italianEnabled.true;
                },
                suppressSelectAll: true
            },
        },
        {
            headerName: "", suppressMenu: true, pinned: 'right', lockPinned: true, cellRenderer: function (params) {
                return (
                    <Grid container spacing={1} justifyContent={'flex-end'}>
                        <Grid item>
                            <Link to={'/' + routes.periodDetails + '/' + params.data.id}
                                  className={'muiBtn muiBtnPrimary'}>
                                Visualizza
                            </Link>
                        </Grid>
                        {params.data.enabled ?
                            <Grid item>
                                <Link to={'/' + routes.editPeriod + '/' + params.data.id}
                                      className={'muiBtn muiBtnSecondary'}>
                                    Modifica
                                </Link>
                            </Grid>
                            :
                            ''
                        }
                    </Grid>
                )
            },
            suppressSizeToFit: true,
            minWidth: 220
        }
    ],
    defaultColDef: {
        resizable: true,
        flex: 1,
    },
    rowData: []
}

export const dataProducts = {
    columnDefs: [
        {
            headerName: "Codice prodotto",
            field: "code",
            minWidth: 80,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        }, {
            headerName: "Nome prodotto",
            field: "name",
            minWidth: 400,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Costo Unitario (CHF)",
            field: "priceFormatted",
            minWidth: 80,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Punto di riordino",
            field: "minimumStockQuantity",
            minWidth: 80,
            sortable: true,
            filter: 'agNumberColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Catalogo",
            field: "catalog.name",
            minWidth: 80,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Categoria",
            field: "category.name",
            width: 100,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Archiviato",
            field: "enabled",
            cellRenderer: enabledFormatter,
            minWidth: 100,
            sortable: true,
            filter: 'agSetColumnFilter',
            filterParams: {
                suppressAndOrCondition: true,
                cellRenderer: function (params) {
                    return params.value ? italianEnabled.false : italianEnabled.true;
                },
                suppressSelectAll: true
            },
        },
        {
            headerName: "",
            suppressMenu: true,
            cellRenderer: (params) => <DropdownTableButton {...params}/>,
            cellEditorPopup: true,
            width: 80,
            pinned: 'right',
            lockPinned: true
        },
    ],
    defaultColDef: {
        resizable: true,
        flex: 1,
    },
    rowData: []
}

export const dataUsers = {
    columnDefs: [
        {
            headerName: "ID Utente", field: "id", sortable: true, minWidth: 100,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Cognome", field: "lastName", minWidth: 180, sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Nome", field: "firstName", sortable: true, minWidth: 180,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Email", field: "email", sortable: true, minWidth: 250,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Data ultimo accesso", field: "lastLoginFormatted", sortable: true, minWidth: 250,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Archiviato",
            field: "disabled",
            cellRenderer: userDisabledFormatter,
            minWidth: 100,
            sortable: true,
            filter: 'agSetColumnFilter',
            filterParams: {
                suppressAndOrCondition: true,
                cellRenderer: function (params) {
                    return params.value ? italianEnabled.true : italianEnabled.false;
                },
                suppressSelectAll: true
            },
        },
        {
            headerName: "", suppressMenu: true, pinned: 'right', lockPinned: true, cellRenderer: function (params) {
                return (
                    <Grid container spacing={1} justifyContent={'flex-end'}>
                        <Grid item>
                            <Link className={'muiBtn muiBtnPrimary'}
                                  to={'/' + routes.userDetails + '/' + params.data.id}>
                                Visualizza
                            </Link>
                        </Grid>
                        {!params.data.disabled ?
                            <Grid item>
                                <Link className={'muiBtn muiBtnSecondary'}
                                      to={'/' + routes.editUser + '/' + params.data.id}>
                                    Modifica
                                </Link>
                            </Grid>
                            :
                            ''
                        }
                    </Grid>
                )
            },
            minWidth: 220,
            suppressSizeToFit: true,
        },
    ],
    defaultColDef: {
        resizable: true,
        flex: 1,
    },
    rowData: []
}

export const dataOrders = {
    columnDefs: [
        {
            headerName: "Creato da",
            field: "createdBy",
            minWidth: 180,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Funzione",
            field: "budgetName",
            minWidth: 160,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Periodo",
            field: "schoolYearName",
            minWidth: 120,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Totale (CHF)",
            field: "totalFormatted",
            minWidth: 120,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Sede fornitura",
            field: "location",
            minWidth: 200,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Data creazione",
            field: "createdAtFormatted",
            minWidth: 160,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Data di modifica",
            field: "updatededAtFormatted",
            sortable: true,
            minWidth: 160,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Stato ordine",
            field: "statusLabel",
            cellRenderer: statusFormatter,
            minWidth: 200,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "", suppressMenu: true, pinned: 'right', lockPinned: true, cellRenderer: function (params) {
                return (
                    <Grid container spacing={1} justifyContent={'flex-end'}>
                        <Grid item>
                            <Link className={'muiBtn muiBtnPrimary'}
                                  to={'/' + routes.orderUserDetails + '/' + params.data.id}>
                                Dettaglio
                            </Link>
                        </Grid>
                        {params.data.status !== originalStatus.inProgress ?
                            ''
                            :
                            <Grid item>
                                <Link
                                    className={'muiBtn muiBtnSecondary'}
                                    to={'/' + routes.orderInfo + '/' + params.data.id}
                                >
                                    Modifica
                                </Link>
                            </Grid>
                        }

                    </Grid>
                )
            },
            suppressSizeToFit: true,
            minWidth: 220
        },
    ],
    defaultColDef: {
        resizable: true,
        flex: 1,
    },
    rowData: []
}

export const dataFunctionsUser = {
    columnDefs: [
        {
            headerName: "Nome funzione",
            field: "name",
            minWidth: 250,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Budget attuale (CHF)",
            field: "availableAmountFormatted",
            minWidth: 150,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Budget iniziale (CHF)",
            field: "amountFormatted",
            minWidth: 150,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Budget aggiuntivo (CHF)",
            field: "arbitraryAmountFormatted",
            minWidth: 200,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "N. studenti SI",
            field: "primarySchoolStudents",
            minWidth: 150,
            sortable: true,
            filter: 'agNumberColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "N. studenti SE 1° ciclo",
            field: "elementarySchoolFirstCycleStudents",
            minWidth: 150,
            sortable: true,
            filter: 'agNumberColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "N. studenti SE 2° ciclo",
            field: "elementarySchoolSecondCycleStudents",
            minWidth: 15,
            sortable: true,
            filter: 'agNumberColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Validità periodo",
            field: "schoolYear",
            cellRenderer: periodFormatter,
            minWidth: 150,
            sortable: true,
            filterParams: {suppressAndOrCondition: true}
        },
    ],
    defaultColDef: {
        resizable: true,
        flex: 1,
    },
    rowData: []
}

export const dataCouponsUser = {
    columnDefs: [
        {
            headerName: "ID Buono",
            field: "idComment",
            minWidth: 160,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Descrizione",
            field: "description",
            minWidth: 160,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Fornitore",
            field: "supplier",
            minWidth: 120,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Utente",
            field: "owner.fullName",
            minWidth: 120,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Funzione",
            field: "budget.name",
            minWidth: 120,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Valore (CHF)",
            field: "amountFormatted",
            minWidth: 100,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
    ],
    defaultColDef: {
        resizable: true,
        flex: 1,
    },
    rowData: []
}

export const dataReturn = {
    columnDefs: [
        {
            headerName: "Codice prodotto",
            field: "product.code",
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true},
            maxWidth: 200,
        },
        {
            headerName: "Nome prodotto",
            field: "product.name",
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true},
            minWidth: 500,
        },
        {
            headerName: "Quantità",
            field: "quantity",
            sortable: true,
            filter: 'agNumberColumnFilter',
            filterParams: {suppressAndOrCondition: true},
            minWidth: 200
        },
        {
            headerName: "Quantità iniziale",
            field: "originalQuantity",
            sortable: true,
            filter: 'agNumberColumnFilter',
            filterParams: {suppressAndOrCondition: true},
            minWidth: 200
        },
        {
            headerName: "Quantità resa",
            field: "returnedQuantity",
            cellRenderer: colorFormatter,
            sortable: true,
            filter: 'agNumberColumnFilter',
            filterParams: {suppressAndOrCondition: true},
            minWidth: 200
        },
        {
            headerName: "Costo unitario (CHF)",
            field: "product.priceFormatted",
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true},
            minWidth: 200
        }
    ],
    defaultColDef: {
        resizable: true,
        flex: 1,
    },
    rowData: []
}

export const dataOrderDetails = {
    columnDefs: [
        {
            headerName: "Codice prodotto",
            field: "product.code",
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true},
            maxWidth: 200,
        },
        {
            headerName: "Nome prodotto",
            field: "product.name",
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true},
            minWidth: 500,
        },
        {
            headerName: "Quantità",
            field: "quantity",
            sortable: true,
            filter: 'agNumberColumnFilter',
            filterParams: {suppressAndOrCondition: true},
            minWidth: 200
        },
        {
            headerName: "Costo unitario (CHF)",
            field: "product.priceFormatted",
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true},
            minWidth: 200
        }
    ],
    defaultColDef: {
        resizable: true,
        flex: 1,
    },
    rowData: []
}

export const dataStocks = {
    columnDefs: [
        {
            headerName: "Tipologia",
            field: "statusLabel",
            minWidth: 140,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Data di creazione",
            field: "createdAtFormatted",
            minWidth: 200,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Descrizione",
            field: "description",
            minWidth: 200,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Quantità",
            field: "quantity",
            minWidth: 80,
            sortable: true,
            filter: 'agNumberColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Fornitore",
            field: "supplier",
            minWidth: 180,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "", suppressMenu: true, pinned: 'right', lockPinned: true, cellRenderer: function (params) {
                return (
                    <Grid container spacing={1} justifyContent={'flex-end'}>
                        {(params.data.status === stockStatus.supply || params.data.status === stockStatus.removal) ?
                            <>
                                <Grid item>
                                    <Link
                                        to={'/' + routes.editStock + '/' + params.data.product.id + '/' + params.data.id}
                                        className={'muiBtn muiBtnSecondary'}>
                                        Modifica
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link className={'muiBtn muiBtnSecondary'}
                                          onClick={() => confirmStockDelete(params)}>
                                        Elimina
                                    </Link>
                                </Grid>
                            </>
                            :
                            ''
                        }
                    </Grid>
                )
            },
            width: 220,
            suppressSizeToFit: true,
        },
    ],
    defaultColDef: {
        resizable: true,
        flex: 1,
    },
    rowData: []
}

export const columnOrders = [
    {
        headerName: "Creato da",
        field: "createdBy",
        minWidth: 180,
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: {suppressAndOrCondition: true}
    },
    {
        headerName: "Funzione",
        field: "budgetName",
        minWidth: 160,
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: {suppressAndOrCondition: true}
    },
    {
        headerName: "Periodo",
        field: "schoolYearName",
        minWidth: 120,
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: {suppressAndOrCondition: true}
    },
    {
        headerName: "Totale (CHF)",
        field: "totalFormatted",
        minWidth: 120,
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: {suppressAndOrCondition: true}
    },
    {
        headerName: "Sede fornitura",
        field: "location",
        minWidth: 200,
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: {suppressAndOrCondition: true}
    },
    {
        headerName: "Data creazione",
        field: "createdAtFormatted",
        minWidth: 160,
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: {suppressAndOrCondition: true}
    },
    {
        headerName: "Stato ordine",
        field: "statusLabel",
        cellRenderer: statusFormatter,
        minWidth: 200,
        sortable: true
    },
    {
        headerName: "", suppressMenu: true, pinned: 'right', lockPinned: true, cellRenderer: function (params) {
            return (
                <Grid container spacing={1}>
                    <Grid item>
                        <Link className={'muiBtn muiBtnPrimary'}
                              to={'/' + routes.orderDetails + '/' + params.data.id}>
                            Dettaglio
                        </Link>
                    </Grid>
                    {params.data.status !== originalStatus.inProgress ?
                        ''
                        :
                        <Grid item>
                            <Link
                                className={'muiBtn muiBtnSecondary'}
                                to={'/' + routes.orderInfo + '/' + params.data.id}
                            >
                                Modifica
                            </Link>
                        </Grid>
                    }

                </Grid>
            )
        },
        suppressSizeToFit: true,
        width: 200
    },
]

export const columnOrdersBudgetDetails = [
    {
        headerName: "Utente",
        field: "user",
        minWidth: 180,
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: {suppressAndOrCondition: true}
    },
    {
        headerName: "Creato da",
        field: "createdBy",
        minWidth: 180,
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: {suppressAndOrCondition: true}
    },
    {
        headerName: "Funzione",
        field: "budgetName",
        minWidth: 160,
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: {suppressAndOrCondition: true}
    },
    {
        headerName: "Periodo",
        field: "schoolYearName",
        minWidth: 120,
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: {suppressAndOrCondition: true}
    },
    {
        headerName: "Totale (CHF)",
        field: "totalFormatted",
        minWidth: 120,
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: {suppressAndOrCondition: true}
    },
    {
        headerName: "Sede fornitura",
        field: "location",
        minWidth: 200,
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: {suppressAndOrCondition: true}
    },
    {
        headerName: "Data creazione",
        field: "createdAtFormatted",
        minWidth: 160,
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: {suppressAndOrCondition: true}
    },
    {
        headerName: "Stato ordine",
        field: "statusLabel",
        cellRenderer: statusFormatter,
        minWidth: 200,
        sortable: true
    },
    {
        headerName: "", suppressMenu: true, pinned: 'right', lockPinned: true, cellRenderer: function (params) {
            console.log(params)
            return (
                <Grid container spacing={1}>
                    <Grid item>
                        <Link className={'muiBtn muiBtnPrimary'}
                              to={'/' + routes.orderDetails + '/' + params.data.id}>
                            Dettaglio
                        </Link>
                    </Grid>
                    {params.data?.status !== originalStatus.inProgress ?
                        ''
                        :
                        <Grid item>
                            <Link
                                className={'muiBtn muiBtnSecondary'}
                                to={'/' + routes.orderInfo + '/' + params.data.id}
                            >
                                Modifica
                            </Link>
                        </Grid>
                    }

                </Grid>
            )
        },
        suppressSizeToFit: true,
        width: 200
    },
]

export const columnCoupons = [
    {
        headerName: "ID Buono",
        field: "idComment",
        minWidth: 80,
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: {suppressAndOrCondition: true}
    },
    {
        headerName: "Descrizione",
        field: "description",
        minWidth: 160,
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: {suppressAndOrCondition: true}
    },
    {
        headerName: "Fornitore",
        field: "supplier",
        minWidth: 120,
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: {suppressAndOrCondition: true}
    },
    {
        headerName: "Utente",
        field: "owner.fullName",
        minWidth: 120,
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: {suppressAndOrCondition: true}
    },
    {
        headerName: "Funzione",
        field: "budget.name",
        minWidth: 120,
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: {suppressAndOrCondition: true}
    },
    {
        headerName: "Valore (CHF)",
        field: "amountFormatted",
        minWidth: 60,
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: {suppressAndOrCondition: true}
    },
    {
        headerName: "Archiviato",
        field: "enabled",
        cellRenderer: enabledFormatter,
        minWidth: 100,
        sortable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
            suppressAndOrCondition: true,
            cellRenderer: function (params) {
                return params.value ? italianEnabled.false : italianEnabled.true;
            },
            suppressSelectAll: true
        },
    },
    {
        headerName: "", suppressMenu: true, pinned: 'right', lockPinned: true, cellRenderer: function (params) {
            return (
                <Grid container spacing={1} justifyContent={'flex-end'}>
                    <Grid item>
                        <Link to={'/' + routes.couponDetails + '/' + params.data.id}
                              className={'muiBtn muiBtnPrimary'}>
                            Visualizza
                        </Link>
                    </Grid>
                    {params.data.enabled ?
                        <Grid item>
                            <Link to={'/' + routes.editCoupon + '/' + params.data.id}
                                  className={'muiBtn muiBtnSecondary'}>
                                Modifica
                            </Link>
                        </Grid>
                        :
                        ''
                    }

                </Grid>

            )
        },
        suppressSizeToFit: true,
        minWidth: 220
    }
]

export const columnCouponsBudgetDetails = [
    {
        headerName: "ID Buono",
        field: "idComment",
        minWidth: 80,
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: {suppressAndOrCondition: true}
    },
    {
        headerName: "Descrizione",
        field: "description",
        minWidth: 160,
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: {suppressAndOrCondition: true}
    },
    {
        headerName: "Fornitore",
        field: "supplier",
        minWidth: 120,
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: {suppressAndOrCondition: true}
    },
    {
        headerName: "Utente",
        field: "owner.fullName",
        minWidth: 120,
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: {suppressAndOrCondition: true}
    },
    {
        headerName: "Funzione",
        field: "budget.name",
        minWidth: 120,
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: {suppressAndOrCondition: true}
    },
    {
        headerName: "Valore (CHF)",
        field: "amountFormatted",
        minWidth: 60,
        sortable: true,
        filter: 'agTextColumnFilter',
        filterParams: {suppressAndOrCondition: true}
    },
    {
        headerName: "Archiviato",
        field: "enabled",
        cellRenderer: enabledFormatter,
        minWidth: 100,
        sortable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
            suppressAndOrCondition: true,
            cellRenderer: function (params) {
                return params.value ? italianEnabled.false : italianEnabled.true;
            },
            suppressSelectAll: true
        },
    },
    {
        headerName: "", suppressMenu: true, pinned: 'right', lockPinned: true, cellRenderer: function (params) {
            return (
                <Grid container spacing={1} justifyContent={'flex-end'}>
                    <Grid item>
                        <Link to={'/' + routes.couponDetails + '/' + params.data.id}
                              className={'muiBtn muiBtnPrimary'}>
                            Visualizza
                        </Link>
                    </Grid>
                    {params.data.enabled ?
                        <Grid item>
                            <Link to={'/' + routes.editCoupon + '/' + params.data.id}
                                  className={'muiBtn muiBtnSecondary'}>
                                Modifica
                            </Link>
                        </Grid>
                        :
                        ''
                    }

                </Grid>

            )
        },
        suppressSizeToFit: true,
        minWidth: 220
    }
]

export const columnDefsItemsOrdered = [
    {
        headerName: "Utente",
        field: "user.fullName",
        sortable: true,
        minWidth: 200,
        filter: 'agTextColumnFilter',
        filterParams: {suppressAndOrCondition: true}
    },
    {
        headerName: "Funzione",
        field: "budgetName",
        sortable: true,
        minWidth: 200,
        filter: 'agTextColumnFilter',
        filterParams: {suppressAndOrCondition: true}
    },
    {
        headerName: "Quantità",
        field: "totalItemQuantity",
        sortable: true,
        minWidth: 200,
        filter: 'agNumberColumnFilter',
        filterParams: {suppressAndOrCondition: true}
    }
]

export const columnDefsStockProducts = [
    {
        headerName: "Codice prodotto",
        field: "productCode",
        sortable: true,
        minWidth: 80,
        maxWidth: 120,
        filter: 'agTextColumnFilter',
        filterParams: {suppressAndOrCondition: true}
    },
    {
        headerName: "Nome prodotto",
        field: "productName",
        sortable: true,
        minWidth: 300,
        maxWidth: 500,
        filter: 'agTextColumnFilter',
        filterParams: {suppressAndOrCondition: true}
    },
    {
        headerName: "Categoria",
        field: "categoryName",
        sortable: true,
        minWidth: 200,
        maxWidth: 300,
        filter: 'agTextColumnFilter',
        filterParams: {suppressAndOrCondition: true}
    },
    {
        headerName: "Fornitore",
        field: "suppliers",
        sortable: true,
        minWidth: 200,
        maxWidth: 300,
        filter: 'agTextColumnFilter',
        filterParams: {suppressAndOrCondition: true}
    },
    {
        headerName: "Quantità minima",
        field: "minimumStockQuantity",
        sortable: true,
        maxWidth: 150,
        minWidth: 75,
        filter: 'agTextColumnFilter',
        filterParams: {suppressAndOrCondition: true}
    },
    {
        headerName: "Stock finale",
        field: "availableQuantityTo",
        sortable: true,
        maxWidth: 150,
        minWidth: 75,
        filter: 'agTextColumnFilter',
        filterParams: {suppressAndOrCondition: true}
    },
    {
        headerName: "Stock iniziale",
        field: "availableQuantityFrom",
        sortable: true,
        maxWidth: 150,
        minWidth: 75,
        filter: 'agTextColumnFilter',
        filterParams: {suppressAndOrCondition: true}
    },
    {
        headerName: "Quantità uscita",
        field: "availableQuantityOut",
        sortable: true,
        maxWidth: 150,
        minWidth: 75,
        filter: 'agTextColumnFilter',
        filterParams: {suppressAndOrCondition: true}
    },
    {
        headerName: "Quantità entrata",
        field: "availableQuantityIn",
        sortable: true,
        maxWidth: 150,
        minWidth: 75,
        filter: 'agTextColumnFilter',
        filterParams: {suppressAndOrCondition: true}
    },
    {
        headerName: "Stock previsione",
        field: "diffOutIn",
        sortable: true,
        maxWidth: 150,
        minWidth: 75,
        filter: 'agTextColumnFilter',
        filterParams: {suppressAndOrCondition: true}
    },
]

export const columnDefsItemsOrderedByUser = [
    {
        headerName: "Codice prodotto",
        field: "productCode",
        sortable: true,
        minWidth: 200,
        filter: 'agTextColumnFilter',
        filterParams: {suppressAndOrCondition: true}
    },
    {
        headerName: "Nome prodotto",
        field: "productName",
        sortable: true,
        minWidth: 200,
        filter: 'agTextColumnFilter',
        filterParams: {suppressAndOrCondition: true}
    },
    {
        headerName: "Quantità",
        field: "totalItemQuantity",
        sortable: true,
        minWidth: 200,
        filter: 'agNumberColumnFilter',
        filterParams: {suppressAndOrCondition: true}
    }
]

export const columnDefsBudgetStatusLocation = [
    {
        headerName: "Utenti",
        field: "users",
        sortable: true,
        minWidth: 200,
        filter: 'agTextColumnFilter',
        filterParams: {suppressAndOrCondition: true}
    },
    {
        headerName: "Funzione",
        field: "budgetName",
        sortable: true,
        minWidth: 200,
        filter: 'agTextColumnFilter',
        filterParams: {suppressAndOrCondition: true}
    },
    {
        headerName: "Budget iniziale",
        field: "budgetAmount",
        sortable: true,
        minWidth: 200,
        filter: 'agNumberColumnFilter',
        filterParams: {suppressAndOrCondition: true}
    },
    {
        headerName: "Importo speso",
        field: "totalAmount",
        sortable: true,
        minWidth: 200,
        filter: 'agNumberColumnFilter',
        filterParams: {suppressAndOrCondition: true}
    },
    {
        headerName: "Percentuale spesa %",
        field: "amountPercentage",
        sortable: true,
        minWidth: 200,
        filter: 'agNumberColumnFilter',
        filterParams: {suppressAndOrCondition: true}
    }
]

export const columnDefsCouponTotalSpend = [
    {
        headerName: "Utente",
        field: "user.email",
        sortable: true,
        minWidth: 200,
        filter: 'agTextColumnFilter',
        filterParams: {suppressAndOrCondition: true}
    },
    {
        headerName: "Funzione",
        field: "budgetName",
        sortable: true,
        minWidth: 200,
        filter: 'agTextColumnFilter',
        filterParams: {suppressAndOrCondition: true}
    },
    {
        headerName: "Percentuale %",
        field: "couponPercentage",
        sortable: true,
        minWidth: 200,
        filter: 'agNumberColumnFilter',
        filterParams: {suppressAndOrCondition: true}
    }
]