import React, {useCallback, useEffect, useState} from 'react';
import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from "@mui/material";
import ApiService from "../services/ApiService";
import {AgGridReact} from "ag-grid-react";
import {agGridItalianTranslation} from "../configuration/agGridItalianTranslation";
import BasicDialog from "../components/BasicDialog";
import SelectWithSearch from "../components/SelectWithSearch";
import {voidBudget, voidUser} from "../utils/ReusableObjects";
import {columnDefsItemsOrderedByUser} from "../utils/agGridDefs";
import {handleClose} from "../utils/ReusableFunction";
import {warningText} from "../utils/ReusableText";
import CircularProgress from "@mui/material/CircularProgress";

export default function ItemsOrderedByUserReport() {
    const [rowData, setRowData] = useState([]);
    const [columnDefs, setColumnDefs] = useState([]);
    const [defaultColDef] = useState({
        resizable: true,
        flex: 1,
    });
    const [filterType, setFilterType] = useState("userPeriod");
    const [loading, setLoading] = useState(true);
    const [budgetSelected, setBudgetSelected] = useState(voidBudget);
    const [userSelected, setUserSelected] = useState(voidUser);
    const [listOfUsers, setListOfUsers] = useState([]);
    const [listOfBudgets, setListOfBudgets] = useState([]);
    const [dateFrom, setDateFrom] = useState(undefined);
    const [dateTo, setDateTo] = useState(undefined);
    const [errorDateFrom, setErrorDateFrom] = useState(false);
    const [errorDateTo, setErrorDateTo] = useState(false);
    const [errorAlert, setErrorAlert] = useState(false);
    const [spinner, setSpinner] = useState(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fetchReport = useCallback(() => {
        getItemsOrderedByUserReport().then(response => {
            setColumnDefs(columnDefsItemsOrderedByUser);
            if (response) {
                setRowData(response);
            }
        });
    });

    useEffect(() => {
        ApiService.getUsers().then(response => {
            setListOfUsers(response);
            fetchBudgetsEnabled();
        });
    }, []);

    function fetchBudgetsEnabled() {
        ApiService.getBudgets().then(response => {
            setListOfBudgets(response);
        });
    }

    function selectRadioType(type) {
        if (type === 'userPeriod') {
            setUserSelected(voidUser);
            setDateFrom('');
            setDateTo('');
        } else if ('function') {
            setUserSelected(voidUser);
            setBudgetSelected(voidBudget);
            fetchBudgetsEnabled();
        } else {
            setUserSelected(voidUser);
            fetchBudgetsEnabled();
            setBudgetSelected(voidBudget);
        }
    }

    const getItemsOrderedByUserReport = async () => {
        setSpinner(true);
        let filter;
        if (filterType === "userPeriod" && userSelected && dateFrom && dateTo) {
            filter = {
                from: dateFrom.replace('T', ' '),
                to: dateTo.replace('T', ' '),
                userId: userSelected.id
            }
        } else if (filterType === "userFunction" && userSelected && budgetSelected) {
            filter = {
                budgetId: budgetSelected.id,
                userId: userSelected.id
            }
        } else if (filterType === "function" && budgetSelected) {
            filter = {
                budgetId: budgetSelected.id
            }
        }

        return await ApiService.getItemsOrderedByUserReport(filter).then(response => {
            setLoading(false);
            setSpinner(false);
            setColumnDefs(columnDefsItemsOrderedByUser);
            if (response.length > 0) {
                return response;
            } else {
                return [];
            }
        }).catch((e) => setErrorAlert(true));
    }

    function setUserAndFetchBudgets(value) {
        setUserSelected(value);
        ApiService.getBudgetsByUserId(value.id).then(response => {
            setListOfBudgets(response.budgets);
        })
    }

    function checkDateFrom(value) {
        const endDate = new Date(dateTo);
        const startDate = new Date(value);
        setDateFrom(value === '' ? undefined : value);

        if (startDate >= endDate) {
            setErrorDateFrom(true);
        } else {
            if (errorDateTo) {
                setErrorDateTo(false);
            } else {
                setErrorDateFrom(false);
            }
        }

    }

    function checkDateTo(value) {
        const startDate = new Date(dateFrom);
        const endDate = new Date(value);
        setDateTo(value === '' ? undefined : value);

        if (startDate >= endDate) {
            setErrorDateTo(true);
        } else {
            if (errorDateFrom) {
                setErrorDateFrom(false);
            } else {
                setErrorDateTo(false);
            }
        }
    }

    return (<>
            <Grid container sx={{mt: 2}} justifyContent={'space-between'}>
                <Grid item xs="auto">
                    <Typography variant="h5" component="h2" sx={{fontWeight: '500'}}>Articoli comandati per
                        utente</Typography>
                </Grid>
            </Grid>

            <Grid container sx={{mt: 3}}>
                <Grid item xs={12} md={12} lg={12}>
                    <Box
                        sx={{
                            background: '#EFEFEF',
                            p: 1,
                            mb: 2
                        }}
                    >
                        <Grid container spacing={1} alignItems="center">
                            <Grid item>
                                <FormControl component="fieldset">
                                    <RadioGroup
                                        aria-label="filterType"
                                        name="filterType"
                                        value={filterType}
                                        onChange={(e) => setFilterType(e.target.value)}
                                    >
                                        <Grid container alignItems="center" justifyContent="center" spacing={1}>
                                            <Grid item>
                                                <FormControlLabel value="userPeriod" control={<Radio/>}
                                                                  label="Utente + Periodo" onClick={() => {
                                                    selectRadioType('userPeriod')
                                                }}/>
                                            </Grid>
                                            <Grid item>
                                                <FormControlLabel value="userFunction" control={<Radio/>}
                                                                  label="Utente + Funzione" onClick={() => {
                                                    selectRadioType('userFunction')
                                                }}/>
                                            </Grid>
                                            <Grid item>
                                                <FormControlLabel value="function" control={<Radio/>}
                                                                  label="Solo Funzione" onClick={() => {
                                                    selectRadioType('function')
                                                }}/>
                                            </Grid>
                                        </Grid>
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            {filterType === "userPeriod" ?
                                <><Grid item xs={4} sm={4}>
                                    <SelectWithSearch options={listOfUsers} value={userSelected}
                                                      onChange={setUserAndFetchBudgets}
                                                      required={false} multiple={false} label={"Seleziona utente"}
                                                      id={"userSelect"} entity={'user'}/>
                                </Grid>
                                    <Grid item xs={3} sm={3}>
                                        {errorDateFrom ?
                                            <TextField type="datetime-local"
                                                       inputFormat="yyyy-MM-ddTHH:mm"
                                                       error
                                                       fullWidth
                                                       id="from"
                                                       label="Dal"
                                                       value={dateFrom}
                                                       helperText="La data d'inizio non può essere superiore alla data fine"
                                                       variant="standard"
                                                       onChange={(e) => checkDateFrom(e.target.value)}
                                                       InputLabelProps={{shrink: true}}
                                                       disabled={budgetSelected?.id !== undefined}
                                            />
                                            :
                                            <TextField id="from" label="Dal" InputLabelProps={{shrink: true}}
                                                       type="datetime-local"
                                                       value={dateFrom}
                                                       variant="standard"
                                                       inputFormat="yyyy-MM-ddTHH:mm"
                                                       onChange={(e) => checkDateFrom(e.target.value)} fullWidth
                                                       disabled={budgetSelected?.id !== undefined}/>
                                        }
                                    </Grid>
                                    <Grid item xs={3} sm={3}>
                                        {errorDateTo ?
                                            <TextField type="datetime-local"
                                                       inputFormat="yyyy-MM-ddTHH:mm"
                                                       error
                                                       fullWidth
                                                       id="to"
                                                       label="Al"
                                                       value={dateTo}
                                                       helperText="La data di fine non può essere superiore alla data d'inizio"
                                                       variant="standard"
                                                       onChange={(e) => checkDateTo(e.target.value)}
                                                       InputLabelProps={{shrink: true}}
                                                       disabled={budgetSelected?.id !== undefined}/>
                                            :
                                            <TextField id="to" label="Al" InputLabelProps={{shrink: true}}
                                                       type="datetime-local"
                                                       value={dateTo}
                                                       variant="standard"
                                                       inputFormat="yyyy-MM-ddTHH:mm"
                                                       onChange={(e) => checkDateTo(e.target.value)} fullWidth
                                                       disabled={budgetSelected?.id !== undefined}/>
                                        }
                                    </Grid></>
                                :
                                <></>}
                            {filterType === "userFunction" ?
                                <><Grid item xs={5} sm={5}>
                                    <SelectWithSearch options={listOfUsers} value={userSelected}
                                                      onChange={setUserAndFetchBudgets}
                                                      required={false} multiple={false} label={"Seleziona utente"}
                                                      id={"userSelect"} entity={'user'}/>
                                </Grid>
                                    <Grid item xs={5} sm={5}>
                                        <SelectWithSearch options={listOfBudgets} value={budgetSelected}
                                                          onChange={setBudgetSelected} required={false}
                                                          multiple={false} label={"Seleziona funzione"}
                                                          id={"budgetSelect"}
                                                          entity={'budget'}/>
                                    </Grid></>
                                :
                                <></>
                            }
                            {filterType === "function" ?
                                <Grid item xs={5} sm={5}>
                                    <SelectWithSearch options={listOfBudgets} value={budgetSelected}
                                                      onChange={setBudgetSelected} required={false}
                                                      multiple={false} label={"Seleziona funzione"} id={"budgetSelect"}
                                                      entity={'budget'}/>
                                </Grid>
                                :
                                <></>
                            }
                            <Grid item xs={2} sm={2}>
                                <Button variant="contained" color="primary" fullWidth sx={{mt: 1}}
                                        onClick={() => fetchReport()}
                                        disabled={!((userSelected?.id && dateFrom && dateTo && !errorDateFrom && !errorDateTo) || (userSelected?.id && budgetSelected?.id) || budgetSelected?.id) || spinner}>
                                    {spinner ? <CircularProgress size={20} color="inherit"/> : "Genera report"}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>

                    {!loading ? <>
                        <div style={{width: '100%', height: '100%'}} className="ag-theme-material ag-custom">
                            <div style={{width: '100%', height: '100%'}}>
                                <AgGridReact
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    rowData={rowData}
                                    pagination={true}
                                    paginationPageSize={25}
                                    singleClickEdit={true}
                                    domLayout={'autoHeight'}
                                    localeText={agGridItalianTranslation}
                                >
                                </AgGridReact>
                            </div>
                        </div>
                    </> : ''}
                </Grid>
            </Grid>

            <BasicDialog isOpen={errorAlert} handleClose={() => handleClose(setErrorAlert)}
                         title={warningText.titleError}
                         body={warningText.somethingWentWrong} closeLabel={warningText.okButton}
                         successLabel={undefined}/>
        </>
    );
}