import {Box, Button, Grid, Stack, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import ApiService from "../services/ApiService";
import {NumericFormat} from "react-number-format";
import BasicDialog from "../components/BasicDialog";
import SelectWithSearch from "../components/SelectWithSearch";
import {voidBudget, voidUser} from "../utils/ReusableObjects";
import {label, routes, warningText} from "../utils/ReusableText";
import {handleClose} from "../utils/ReusableFunction";
import CardDetailCart from "../card/CardDetail";

export default function CreateCouponUser() {
    const navigate = useNavigate();
    const path = useLocation();
    const id = path.pathname.split('/')[2];
    const [description, setDescription] = useState(undefined);
    const [supplier, setSupplier] = useState(undefined);
    const [amount, setAmount] = useState(undefined);
    const [invoiceNumber, setInvoiceNumber] = useState(undefined);
    const [idComment, setIdComment] = useState(undefined);
    const [budget, setBudget] = useState(voidBudget);
    const [owner, setOwner] = useState(voidUser);
    const [listOfFunctions, setListOfFunctions] = useState([]);
    const [listOfUsers, setListOfUsers] = useState([]);
    const [errorAlert, setErrorAlert] = useState(false);
    const [alreadyClicked, setAlreadyClicked] = useState(false);
    const [showBudget, setShowBudget] = useState(false);

    useEffect(() => {
        ApiService.getUsers().then(response => {
            setListOfUsers(response);
            ApiService.getUserById(id).then(res => {
                setOwner(res);
                fetchBudgets(id);
            });
        });

    }, [id]);

    function fetchBudgets(userId) {
        const currDate = new Date()
        ApiService.getBudgetsByUserIdForOrders(userId).then(response => {
            if (response.budgets.length > 0) {
                const listOfBudgetsOk = [];
                for (const budget of response.budgets) {
                    if (budget?.enabled && new Date(budget?.schoolYear?.end) >= currDate && budget?.schoolYear?.enabled) {
                        listOfBudgetsOk.push(budget)
                    }

                    setListOfFunctions(listOfBudgetsOk);
                }
            } else {
                setListOfFunctions([]);
            }
        });
    }

    function saveCoupon() {
        setAlreadyClicked(true);

        const body = {
            description: description,
            supplier: supplier,
            amount: (Number(amount) * 100),
            invoiceNumber: invoiceNumber,
            idComment: idComment,
            budgetId: budget.id,
            ownerId: owner.id
        }

        ApiService.createCoupon(body).then(response => {
            navigate('/' + routes.userDetails + '/' + id)

        }).catch((e) => {
            setAlreadyClicked(false);
            setErrorAlert(true);
        });
    }

    function setUserForFunctions(selectedValue) {
        setBudget(voidBudget);
        if (selectedValue !== null) {
            setOwner(selectedValue);

            fetchBudgets(selectedValue.id);
        } else {
            setOwner(voidUser)
        }
    }

    function setBudgetAndCard(value) {
        setBudget(value);
        setShowBudget(true);
    }

    function checkMandatoryCouponFields() {
        return !(idComment && description && supplier && amount && owner?.id && budget !== voidBudget);
    }

    return (<>
        <Grid container sx={{marginTop: 2}}>
            <Grid item xs={12}>
                <Typography variant="h5" component="h2"
                            sx={{fontWeight: '500'}}>{'Nuovo buono acquisto ' + owner.fullName}</Typography>
            </Grid>
        </Grid>

        <Grid container sx={{mt: {xs: 3, md: 4}}} columnSpacing={{xs: 3, md: 5}} justifyContent="space-between">
            <Grid item xs={12} md={8} lg={4}>
                <Box component={'form'}>
                    <Stack spacing={1.5} sx={{mb: {xs: 3, md: 0}}}>
                        <TextField id="idComment" label="ID Buono" value={idComment} defaultValue={idComment}
                                   variant="standard" fullWidth onChange={e => setIdComment(e.target.value)}
                                   InputLabelProps={{shrink: true}} required/>
                        <TextField id="description" label="Descrizione" value={description}
                                   defaultValue={description}
                                   variant="standard" fullWidth
                                   onChange={e => setDescription(e.target.value)}
                                   InputLabelProps={{shrink: true}} required/>

                        <SelectWithSearch options={listOfUsers} value={owner} onChange={setUserForFunctions}
                                          required={true}
                                          multiple={false} label={"Seleziona utente"} id={"userSelect"}
                                          entity={'user'}/>

                        <SelectWithSearch options={listOfFunctions} value={budget} onChange={setBudgetAndCard}
                                          required={true}
                                          multiple={false} label={"Seleziona funzione"} id={"budgetSelect"}
                                          entity={'budget'}/>


                        <NumericFormat decimalSeparator="."
                                       fixedDecimalScale={true}
                                       allowNegative={false}
                                       required
                                       decimalScale={2}
                                       customInput={TextField} id="amount"
                                       label="Valore (CHF)"
                                       defaultValue={amount}
                                       value={amount}
                                       variant="standard"
                                       onChange={e => setAmount(e.target.value)}
                                       fullWidth/>
                        <TextField id="supplier" label="Fornitore" value={supplier} defaultValue={supplier}
                                   variant="standard" fullWidth
                                   onChange={e => setSupplier(e.target.value)}
                                   InputLabelProps={{shrink: true}} required/>
                        <TextField id="invoiceNumber" label="N. Fattura" value={invoiceNumber}
                                   defaultValue={invoiceNumber}
                                   variant="standard" fullWidth
                                   onChange={e => setInvoiceNumber(e.target.value)}
                                   InputLabelProps={{shrink: true}}/>
                    </Stack>
                </Box>

                <Grid container sx={{mt: 3}} justifyContent={'space-between'}>
                    <Grid item>
                        <Button variant="flip" onClick={() => {
                            navigate('/' + routes.coupons)
                        }}>
                            Cancella
                        </Button>
                    </Grid>
                    <Grid item>

                        <Button variant="contained" color="primary"
                                disabled={checkMandatoryCouponFields() || alreadyClicked}
                                onClick={() => {
                                    saveCoupon()
                                }}>Crea</Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={5} lg={4}>
                <Box component={'div'} sx={{mb: 1}}>
                    <CardDetailCart show={showBudget}
                                    budget={budget.availableAmount === null ? label.noLimitBudget : budget.availableAmountFormatted + label.chfWithSpace}
                                    period={budget.schoolYear.name} location={budget.location?.name}/>
                </Box>
            </Grid>
        </Grid>


        <BasicDialog isOpen={errorAlert} handleClose={() => handleClose(setErrorAlert)}
                     title={warningText.titleError}
                     body={warningText.somethingWentWrong} closeLabel={warningText.okButton}
                     successLabel={undefined}/>
    </>);
}