import React from 'react';
import {Box, Paper, Typography} from "@mui/material";
import {useLocation} from "react-router-dom";

export default function CardDetailCart({show, budget, total, user, period, budgetName, location, notes, alertBudget, modifiedAt}) {
    const path = useLocation();
    const id = path.pathname.split('/')[2];

    return (
        <>
            {!show ?
                ''
                :

                <Paper
                    component={'div'}
                    elevation={0}
                    sx={{
                        borderRadius: 0,
                        borderTop: '2px solid #c23052',
                        p: 2
                    }}
                >
                    {
                        budget ?
                            <Box sx={{p: 1, display: 'flex', justifyContent: 'space-between'}}>
                                <Typography sx={{fontWeight: 600}}>Budget</Typography>
                                <Typography
                                    sx={{fontWeight: 600}}>{budget}</Typography>
                            </Box>
                            :
                            ''
                    }
                    {
                        total ?
                            <Box sx={{
                                p: 1,
                                display: 'flex',
                                justifyContent: 'space-between',
                                mb: 1.75,
                                background: 'rgba(0, 0, 0, 0.05)'
                            }}>
                                <Typography sx={{fontWeight: 600}}>TOTALE</Typography>
                                <Typography sx={{fontWeight: 600}}>{total} CHF</Typography>
                            </Box>
                            :
                            ''
                    }
                    {
                        alertBudget ?
                            <Box sx={{display: 'flex', mb: 1,}}>
                                <Typography sx={{pl: 1, color: 'red', display: 'inline-flex', fontSize: '0.875rem'}}>Il
                                    costo totale supera il budget disponibile!</Typography>
                            </Box>
                            :
                            ''
                    }
                    {
                        user ?
                            <Box sx={{display: 'flex', mb: 1,}}>
                                <Typography sx={{
                                    display: 'inline-flex',
                                    color: 'rgba(0, 0, 0, 0.7)',
                                    fontSize: '0.875rem'
                                }}>Utente:</Typography>
                                <Typography sx={{
                                    pl: 1,
                                    display: 'inline-flex',
                                    fontSize: '0.875rem'
                                }}>{user.fullName}</Typography>
                            </Box>
                            :
                            ''
                    }
                    {
                        period ?
                            <Box sx={{display: 'flex', mb: 1,}}>
                                <Typography sx={{
                                    display: 'inline-flex',
                                    color: 'rgba(0, 0, 0, 0.7)',
                                    fontSize: '0.875rem'
                                }}>Periodo:</Typography>
                                <Typography
                                    sx={{pl: 1, display: 'inline-flex', fontSize: '0.875rem'}}>{period}</Typography>
                            </Box>
                            : ''
                    }
                    {
                        budgetName ?
                            <Box sx={{display: 'flex', mb: 1,}}>
                                <Typography sx={{
                                    display: 'inline-flex',
                                    color: 'rgba(0, 0, 0, 0.7)',
                                    fontSize: '0.875rem'
                                }}>Funzione:</Typography>
                                <Typography
                                    sx={{pl: 1, display: 'inline-flex', fontSize: '0.875rem'}}>{budgetName}</Typography>
                            </Box>
                            :
                            ''
                    }
                    {
                        location ?
                            <Box sx={{display: 'flex', mb: 1,}}>
                                <Typography
                                    sx={{display: 'inline-flex', color: 'rgba(0, 0, 0, 0.7)', fontSize: '0.875rem'}}>Sede
                                    fornitura:</Typography>
                                <Typography
                                    sx={{pl: 1, display: 'inline-flex', fontSize: '0.875rem'}}>{location}</Typography>
                            </Box>
                            :
                            ''
                    }
                    {
                        modifiedAt ?
                            <Box sx={{display: 'flex', mb: 1,}}>
                                <Typography
                                    sx={{display: 'inline-flex', color: 'rgba(0, 0, 0, 0.7)', fontSize: '0.875rem'}}>Ordine:</Typography>
                                <Typography
                                    sx={{pl: 1, display: 'inline-flex', fontSize: '0.875rem'}}>{id} | {modifiedAt}</Typography>
                            </Box>
                            :
                            ''
                    }
                    {
                        notes ?
                            <Box sx={{display: 'flex', mb: 1,}}>
                                <Typography sx={{
                                    display: 'inline-flex',
                                    color: 'rgba(0, 0, 0, 0.7)',
                                    fontSize: '0.875rem'
                                }}>Note:</Typography>
                                <Typography
                                    sx={{pl: 1, display: 'inline-flex', fontSize: '0.875rem'}}>{notes}</Typography>
                            </Box>
                            :
                            ''
                    }
                </Paper>}
        </>
    );
}