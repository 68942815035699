import {styled} from '@mui/material/styles';
import {StepLabel} from "@mui/material";

const StyledStepperLabel = styled(StepLabel)(({theme}) => ({
    '& .MuiStepLabel-label': {
        '&.Mui-active': {
            color: theme.palette.primary.main,
        },
        '&.Mui-completed': {
            color: theme.palette.primary.main,
            display: 'none',
            [theme.breakpoints.up("md")]: {
                display: "inline-block"
            }

        },
        '&.Mui-disabled': {
            display: 'none',
            [theme.breakpoints.up('md')]: {
                display: "inline-block"
            }
        }
    },
}));

export default function CustomStepperLabel({label}) {
    return (
        <StyledStepperLabel>{label}</StyledStepperLabel>
    )
}
