import keycloak from "../Keycloak";
import EventObserver from "../utils/EventObserver";
import {storage} from "../utils/ReusableText";

function setUser(user) {
    localStorage.setItem(storage.user, JSON.stringify(user));
}

function deleteToken() {
    localStorage.clear()
    EventObserver.remove(storage.userEvent)
    keycloak.logout();

}

function getToken() {
    return keycloak.token;
}

function getUser() {
    return JSON.parse(localStorage.getItem(storage.user));
}

export {
    getUser,
    getToken,
    setUser,
    deleteToken
};
