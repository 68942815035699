import {Button, Grid, Stack, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Download} from "@mui/icons-material";
import ApiService from "../services/ApiService";
import {getUser} from "../services/AuthService";
import {roles, warningText} from "../utils/ReusableText";
import BasicDialog from "../components/BasicDialog";
import {downloadPdf, handleClose} from "../utils/ReusableFunction";

export default function Manuals() {
    const user = getUser();
    const role = user.role;
    const [alert, setAlert] = useState(false);
    const [manuals, setManuals] = useState([]);

    useEffect(() => {
        if (role === roles.manager) {
            ApiService.getAllManualsManager().then(response => {
                setManuals(response);
            })
        } else if (role === roles.secretary) {
            ApiService.getAllManualsSecretary().then(response => {
                setManuals(response);
            })
        } else {
            ApiService.getAllManualsUser().then(response => {
                setManuals(response);
            })
        }
        // eslint-disable-next-line
    }, []);

    async function downloadManualByRole(apiFunction, filename) {
        const response = await apiFunction(filename);
        if (response.ok) {
            const pdfBlob = await response.blob();
            await downloadPdf(pdfBlob, filename);
        } else {
            setAlert(true);
        }
    }

    function downloadManual(filename) {
        if (role === roles.manager) {
            downloadManualByRole(ApiService.getManualByManager, filename);
        } else if (role === roles.secretary) {
            downloadManualByRole(ApiService.getManualBySecretary, filename);
        } else {
            downloadManualByRole(ApiService.getManualByUser, filename);
        }
    }

    return (<>
            <Grid container sx={{mt: 2}} justifyContent={'space-between'}>
                <Grid item xs="auto">
                    <Typography variant="h5" component="h2" sx={{fontWeight: '500'}}>Manuali</Typography>
                </Grid>
            </Grid>
            {manuals && manuals.length > 0 ?
                manuals.map((manual) =>
                    <Grid container sx={{mt: 3}} columnSpacing={3}>
                        <Grid item xs={12} md={2}>
                            <Stack spacing={1.25}>
                                <Button variant="flip" fullWidth startIcon={<Download/>}
                                        onClick={() => downloadManual(manual)}>
                                    {manual}
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                )
                :
                ''}

            <BasicDialog isOpen={alert} handleClose={() => handleClose(setAlert)} title={warningText.titleError}
                         body={warningText.somethingWentWrong} closeLabel={warningText.okButton}
                         successLabel={undefined}/>
        </>
    );
}