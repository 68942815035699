export const handleClose = (setter, event, reason) => {
    if (reason === 'clickaway') {
        return;
    }
    setter(false);
};

export async function downloadPdf(pdfResponse, filename) {
    const pdfObjectURL = URL.createObjectURL(pdfResponse);
    const downloadLink = document.createElement('a');
    downloadLink.href = pdfObjectURL;
    downloadLink.download = filename;
    downloadLink.click();
    URL.revokeObjectURL(pdfObjectURL);
}