import {Box, Button, Grid, Stack, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import ApiService from "../services/ApiService";
import BasicDialog from "../components/BasicDialog";
import {routes, warningText} from "../utils/ReusableText";
import {handleClose} from "../utils/ReusableFunction";

export default function EditCatalog() {
    const navigate = useNavigate();
    const path = useLocation();
    const id = path.pathname.split('/')[2];
    const [name, setName] = useState(undefined);
    const [description, setDescription] = useState(undefined);
    const [enabled, setEnabled] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const [errorAlert, setErrorAlert] = useState(false);
    const [alreadyClicked, setAlreadyClicked] = useState(false);

    useEffect(() => {
        if (id) {
            setIsEdit(true);
            ApiService.getCatalogById(id).then(response => {
                setName(response.name);
                if (response.description) {
                    setDescription(response.description);
                }
                setEnabled(response.enabled);
            })
        } else {
            setIsEdit(false);
        }
    }, [id])

    function saveCatalog() {
        setAlreadyClicked(true);

        if (id) {
            const body = {
                id: id,
                name: name,
                description: description,
                enabled: enabled
            }
            ApiService.updateCatalog(body).then(response => {
                navigate('/' + routes.catalogs)
            }).catch((e) => {
                setAlreadyClicked(false);
                setErrorAlert(true);
            });
        } else {
            const body = {
                name: name,
                description: description,
            }
            ApiService.createCatalog(body).then(response => {
                navigate('/' + routes.catalogs)
            }).catch((e) => {
                setAlreadyClicked(false);
                setErrorAlert(true);
            });
        }
    }

    function checkMandatoryCatalogFields() {
        return !(name);
    }

    return (<div>
        <Grid container sx={{marginTop: 2}}>
            <Grid item xs={12}>
                <Typography variant="h5" component="h2"
                            sx={{fontWeight: '500'}}>{isEdit ? 'Modifica Catalogo' : 'Nuovo Catalogo'}</Typography>
            </Grid>
        </Grid>

        <Grid container sx={{mt: 4}} columnSpacing={2.5}>
            <Grid item xs={12} md={8} lg={4}>
                <Box component={'form'}>
                    <Stack spacing={1.5} sx={{mb: {xs: 3, md: 0}}}>
                        <TextField id="name" label="Nome catalogo" size="small" value={name} defaultValue={name}
                                   variant="standard"
                                   fullWidth
                                   onChange={e => setName(e.target.value)}
                                   InputLabelProps={isEdit ? {shrink: true} : ''}
                                   required/>
                        <TextField id="description" label="Descrizione" size="small" value={description}
                                   defaultValue={description}
                                   variant="standard"
                                   fullWidth
                                   onChange={e => setDescription(e.target.value)}
                                   InputLabelProps={isEdit ? {shrink: true} : ''}/>
                    </Stack>
                </Box>
                <Grid container sx={{mt: 3}} justifyContent={'space-between'}>
                    <Grid item>
                        <Button variant="flip" onClick={() => {
                            navigate('/' + routes.catalogs)
                        }}>
                            Cancella
                        </Button>

                    </Grid>
                    <Grid item>
                        {isEdit ?
                            <Button variant="contained" color="primary"
                                    disabled={checkMandatoryCatalogFields() || alreadyClicked}
                                    onClick={() => {
                                        saveCatalog()
                                    }}>Salva</Button>
                            :
                            <Button variant="contained" color="primary"
                                    disabled={checkMandatoryCatalogFields() || alreadyClicked}
                                    onClick={() => {
                                        saveCatalog()
                                    }}>Crea</Button>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

        <BasicDialog isOpen={errorAlert} handleClose={() => handleClose(setErrorAlert)} title={warningText.titleError}
                     body={warningText.somethingWentWrong} closeLabel={warningText.okButton} successLabel={undefined}/>
    </div>);
}