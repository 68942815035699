import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {
    Box,
    Button,
    ButtonGroup,
    Card,
    CardContent,
    Checkbox,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    IconButton,
    MenuItem,
    Step,
    Stepper,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import {FormatListBulleted, ShoppingCartOutlined, ViewModule} from '@mui/icons-material';
import InfiniteScroll from 'react-infinite-scroll-component';
import ApiService from '../services/ApiService';
import {label, roles, roundCircleStock, routes, warningText} from '../utils/ReusableText';
import {handleClose} from '../utils/ReusableFunction';
import {getUser} from '../services/AuthService';
import CustomStepperLabel from '../components/Stepper';
import CardDetailCart from '../card/CardDetail';
import ProductCard from '../components/ProductCard';
import TableViewProduct from '../card/TableViewProduct';
import BasicDialog from '../components/BasicDialog';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import DeleteIcon from '@mui/icons-material/Delete';
import CircleIcon from "@mui/icons-material/Circle";
import SnackBarAlert from "../components/SnackBarAlert";

function getSteps() {
    return ['Informazioni', 'Prodotti', 'Conferma'];
}

export default function OrderProducts() {
    const user = getUser();
    const currentUserRole = getUser();
    const [manager] = useState(currentUserRole.role === roles.manager || currentUserRole.role === roles.secretary);
    const navigate = useNavigate();
    const {pathname} = useLocation();
    const steps = getSteps();
    const pageSize = 28;
    const pathSegments = pathname.split('/');
    const id = pathSegments[2];
    const [activeStep, setActiveStep] = useState(1);
    const [products, setProducts] = useState([]);
    const [catalog, setCatalog] = useState(undefined);
    const [category, setCategory] = useState('');
    const [listOfCategories, setListOfCategories] = useState(undefined);
    const [order, setOrder] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [moreContent, setMoreContent] = useState(true);
    const [query, setQuery] = useState(undefined);
    const [page, setPage] = useState(0);
    const [budgetSelected, setBudgetSelected] = useState(undefined);
    const [tableView, setTableView] = useState(false);
    const [errorAlert, setErrorAlert] = useState(false);
    const [all, setAll] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [confirmBack, setConfirmBack] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState(false);

    useEffect(() => {
        setActiveStep(1)

        if (id) {
            setIsEdit(true);
            ApiService.getOrderById(id).then(response => {
                if (manager || response.user.id === currentUserRole.id) {
                    setOrder(response);
                    setCatalog(response?.budget?.catalog);
                    if (response?.budget?.amount === null) {
                        setBudgetSelected(label.noLimitBudget);
                    } else {
                        setBudgetSelected((response.budget?.availableAmount / 100).toFixed(2) + label.chfWithSpace);
                    }

                    fetchProducts(response?.budget?.catalog?.id);

                    ApiService.getCategoriesByCatalogId(response?.budget?.catalog.id).then(response => {
                        if (response.length > 0) {
                            setListOfCategories(response);
                        }
                    });
                } else {
                    navigate('error-page')
                }
            });
        }
        // eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        fetchProductsBySearch(page, pageSize, catalog?.id, query, category, all)
        // eslint-disable-next-line
    }, [all]);

    function fetchProducts(catalogId) {
        setPage(0);
        const pageZero = 0;
        fetchProductsBySearch(pageZero, pageSize, catalogId, query, category, all);
    }

    function fetchProductsBySearch(page, pageSize, catalogId, query, category, all) {
        ApiService.getProductsBySearch(page, pageSize, catalogId, query, category, all)
            .then(response => {
                setProducts(response.products);
                setMoreContent(response.products.length >= 28);
            })
            .catch(error => {
                console.error("Error fetching products:", error);
            });
    }

    function fetchMoreProducts() {
        const pageMore = page + 1;

        ApiService.getProductsBySearch(pageMore, pageSize, catalog?.id, query, category, all)
            .then(response => {
                if (response.products.length === 0) {
                    setProducts([]);
                } else {
                    setProducts(prevProducts => [...prevProducts, ...response.products]);
                }
                setMoreContent(response.products.length >= 28);
                setPage(pageMore);
            })
            .catch(error => {
                console.error("Error fetching more products:", error);
            });
    }

    function selectCategory(value) {
        setCategory(value);
        if (value === '') {
            fetchProducts(catalog.id);
        }
    }

    const changeOrderInitial = (orderFinal) => {
        setOrder(orderFinal);
    }

    const handleKeypress = e => {
        if (e.keyCode === 13) {
            fetchProducts(catalog.id);
        }
    };

    function navigateToOrderDetails() {
        let role = '';
        if (user) {
            role = user.role;
            if (role === roles.manager) {
                navigate('/' + routes.orderDetails + '/' + order.id);
            } else {
                navigate('/' + routes.orderUserDetails + '/' + order.id)
            }
        }
    }

    const handleClickOpenModal = () => {
        setOpenModal(true);
    };
    const handleCloseModal = () => {
        setOpenModal(false);
    };

    function getDescriptionRoundCircle(item) {
        let circleColor = 'orange';
        let setTitle = roundCircleStock.orange;

        if (item.product.stock > item.product.minimumStockQuantity) {
            setTitle = roundCircleStock.green;
            circleColor = 'green';
        } else if ((item.product.stock <= 0 && item.product.minimumStockQuantity >= 0)) {
            setTitle = roundCircleStock.red;
            circleColor = 'red';
        } else {
        }

        return <Tooltip title={setTitle} placement="top">
            {circleColor === 'green' ?
                <CircleIcon sx={{
                    color: (theme) => theme.palette.success.main,
                    width: '16px',
                    height: '16px'
                }}/>
                :
                <CircleIcon sx={{
                    color: (theme) => circleColor === 'red' ? theme.palette.error.main : theme.palette.warning.main,
                    width: '16px',
                    height: '16px'
                }}/>}
        </Tooltip>;
    }

    function deleteItem(itemId) {
        ApiService.deleteOrderItemByIds(order.id, itemId).then(response => {
            setOrder(response);
            changeOrderInitial(response);
        }).catch((e) => setErrorAlert(true));
    }

    function handleChange(itemId, event) {
        const value = event ? Number(event) : undefined;

        const body = {
            id: itemId,
            quantity: value,
            orderId: order.id
        };
        ApiService.updateOrderItem(body).then(response => {
            setOrder(response);
            changeOrderInitial(response);
        }).catch((e) => setErrorAlert(true));
    }


    function deleteOrder() {
        ApiService.deleteOrderByOrderId(id).then(response => {
            navigate('/');
        }).catch((e) => {
            if (e.body?.code === 2) {
                setOpenDialog(false);
                setOpenSnackBar(true);
            } else {
                setErrorAlert(true);
            }
        });
    }

    return (
        <>

            <Grid container sx={{mb: 4}} justifyContent="space-between">
                <Grid item xs={'auto'}>
                    <Button variant="flip"
                            onClick={() => {
                                navigate('/' + routes.orderInfo + '/' + id);
                            }}>
                        Indietro
                    </Button>
                </Grid>
                <Grid item xs={'auto'}>
                    <Button variant="outlined" color={'secondary'} onClick={() => {
                        if (isEdit) {
                            setConfirmBack(true)
                        } else {
                            navigate('/');
                        }
                    }}>
                        Annulla
                    </Button>
                </Grid>
            </Grid>

            <Grid container sx={{mb: 4}}>
                <Grid item xs={6}>
                    <Typography variant="h5" component="h2"
                                sx={{fontWeight: '500'}}>{isEdit ? 'Modifica ordine' : 'Nuovo Ordine'}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Box component={'div'} sx={{'display': 'flex', 'justifyContent': 'flex-end'}}>
                        <Button variant="outlined" color={'primary'} sx={{px: {xs: 2, md: 5}}} onClick={() => {
                            navigateToOrderDetails();
                        }}>
                            Salva
                        </Button>
                    </Box>
                </Grid>
            </Grid>

            <Box
                component={'div'}
                sx={{
                    display: 'grid',
                    width: '100%',
                    mt: 3,
                    columnGap: 1,
                    rowGap: 1,
                    gridTemplateColumns: {
                        xs: '1fr',
                        md: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr'
                    },
                    gridTemplateRows: {
                        xs: 'auto',
                        md: 'auto'
                    },
                    gridTemplateAreas: {
                        xs: `
                            'step'
                            'box'
                            'desc'
                            'btn'
                            'search'
                            `,
                        md: `
                            'step step step step step step step step btn btn btn btn'
                            '. . . . . . . . box box box box'
                            'desc desc desc desc desc desc desc desc box box box box'
                            'search search search search search search search search box box box box'
                            `,
                    }
                }}
            >
                <Box component={'div'}
                     sx={{
                         width: '100%',
                         gridArea: 'step',
                     }}
                >
                    <Grid container>
                        <Grid item xs={12} lg={9}>
                            <Stepper activeStep={activeStep}>
                                {steps.map((label) => (
                                    <Step key={label}>
                                        <CustomStepperLabel label={label}/>
                                    </Step>
                                ))}
                            </Stepper>
                        </Grid>
                    </Grid>
                </Box>
                <Grid container spacing={1}
                      sx={{
                          width: '100%',
                          gridArea: 'btn',
                      }}
                >
                    <Grid item xs={12} xl={6}>
                        <Button
                            variant="flip"
                            onClick={handleClickOpenModal}
                            fullWidth
                            startIcon={<ShoppingBasketIcon/>}
                            size={'large'}
                            sx={{
                                height: {xl: '42px'}
                            }}
                        >
                            Vedi carrello
                        </Button>
                    </Grid>
                    <Grid item xs={12} xl={6}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                navigate('/' + routes.orderCart + '/' + id + (isEdit ? '/edit' : ''));
                            }}
                            fullWidth
                            startIcon={<ShoppingCartOutlined/>}
                            size={'large'}
                        >
                            Procedi all'ordine
                            {order?.items?.length > 0 ?
                                <Chip label={order?.items?.length} size={'small'}
                                      sx={{
                                          color: '#c23052',
                                          background: '#ffffff',
                                          ml: 1
                                      }}
                                />
                                :
                                ''}

                        </Button>
                    </Grid>
                </Grid>

                <Box component={'div'}
                     sx={{
                         width: '100%',
                         gridArea: 'box',
                         mt: {
                             xs: 1.25
                         }
                     }}
                >
                    {order?.budget ?
                        <CardDetailCart show={true} budget={budgetSelected} total={order.totalFormatted}
                                        user={order.user}
                                        period={order.budget.schoolYear.name} location={order.deliveryLocation.name}
                                        budgetName={order.budget.name} notes={order.notes}
                                        modifiedAt={order.updatededAtFormatted}/>
                        :
                        ""}

                </Box>
                <Box component={'div'}
                     sx={{
                         width: '100%',
                         gridArea: 'desc',
                     }}
                >
                    <Box>
                        <Typography sx={{mb: 0.75}}>Catalogo: <Typography component={'span'}
                                                                          sx={{fontWeight: 600}}>{order?.budget?.catalog?.name}</Typography></Typography>
                    </Box>
                </Box>
                <Box component={'div'}
                     sx={{
                         width: '100%',
                         gridArea: 'search',
                     }}
                >
                    <Grid container justifyContent={{xs: "flex-end", xl: "flex-start"}}
                          columns={{xs: 5, md: 8, lg: 8, xl: 10}}
                          rowSpacing={1.25} columnSpacing={1.5} sx={{mt: 0.75}}>
                        <Grid item xs={5} md={8} lg={3} xl={4}>
                            <TextField id="query" label="Cerca per codice o nome prodotto" value={query}
                                       defaultValue={query}
                                       variant="standard"
                                       onChange={e => setQuery(e.target.value)}
                                       onKeyDown={handleKeypress}
                                       InputLabelProps={isEdit ? {shrink: true} : ''} fullWidth/>
                        </Grid>
                        <Grid item xs={5} md={5} lg={2} xl={3}>
                            <TextField
                                select
                                id="categoryIdSelect"
                                label={'Seleziona categoria'}
                                fullWidth
                                variant="standard"
                                value={category || ''}
                                onChange={(e) => selectCategory(e.target.value)}
                            >
                                <MenuItem value={''}>Nessuna selezione</MenuItem>
                                {listOfCategories?.length > 0 ? listOfCategories.map((category) => (
                                    <MenuItem key={category.id}
                                              value={category.id}>{category.name}</MenuItem>)) : ''}
                            </TextField>

                        </Grid>
                        <Grid item xs={5} md={3} lg={3} xl={"auto"}>
                            <FormControlLabel sx={{mt: 2}} control={<Checkbox checked={!all} onChange={() => {
                                setAll(!all);
                            }}/>}
                                              label="Solo disponibili"/>
                        </Grid>
                        <Grid item xs={2} md={2} xl>
                            <Button variant="contained" color="primary" sx={{mt: 1}} fullWidth
                                    onClick={() => fetchProducts(catalog?.id)}>
                                Cerca
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>

            <Box component={'div'}>
                <ButtonGroup variant="contained" aria-label="outlined primary button group" sx={{mt: 1}}>
                    <Tooltip title="Vista card">
                        <Button variant={tableView ? "flip" : "contained"} color="primary"
                                onClick={() => setTableView(false)}>
                            <ViewModule/>
                        </Button>
                    </Tooltip>
                    <Tooltip title="Vista tabellare">
                        <Button variant={!tableView ? "flip" : "contained"} color="primary"
                                onClick={() => setTableView(true)}>
                            <FormatListBulleted/>
                        </Button>
                    </Tooltip>
                </ButtonGroup>
            </Box>

            <InfiniteScroll
                dataLength={products.length}
                next={fetchMoreProducts}
                hasMore={moreContent}
                loader={<h4>In caricamento...</h4>}
            >
                {!tableView ?
                    <Grid container spacing={2} sx={{mt: 1, mb: 3}}>
                        {products?.length > 0 ?
                            products.map((product) =>
                                <ProductCard key={order.id} product={product} orderInitial={order || undefined}
                                             changeOrderInitial={changeOrderInitial}/>
                            )
                            :
                            <Grid item xs={12}>
                                <p>Nessun prodotto trovato</p>
                            </Grid>
                        }
                    </Grid>
                    :
                    <Box sx={{mt: 3}}>
                        <Box
                            sx={{
                                display: {
                                    xs: 'none',
                                    md: 'grid'
                                },
                                width: '100%',
                                p: 1.5,
                                gridTemplateColumns: {
                                    xs: '1fr 1fr 1fr 1fr 1fr',
                                    md: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr'
                                },
                                gridTemplateRows: {
                                    xs: 'auto',
                                    md: 'auto'
                                },
                                gridTemplateAreas: {
                                    xs: `
                    'code code code availability availability'
                    'title title title title title'
                    'price input image add'
                    `,
                                    md: `
                    'code title title title title availability price unit input image add' 
                    `,
                                },
                                borderBottom: '1px solid rgba(0, 0, 0, 0.46)',
                                fontSize: '0.75rem',
                            }}
                        >
                            <Box
                                sx={{
                                    width: '100%',
                                    gridArea: 'code',
                                }}
                            >
                                <Typography color="textSecondary" sx={{fontSize: '0.75rem'}}>Cod.</Typography>
                            </Box>

                            <Box
                                sx={{
                                    width: '100%',
                                    gridArea: 'availability',
                                }}
                            >
                                <Typography color="textSecondary" sx={{fontSize: '0.75rem'}}>Disponibilità</Typography>
                            </Box>

                            <Box
                                sx={{
                                    width: '100%',
                                    gridArea: 'title',
                                }}
                            >
                                <Typography color="textSecondary" sx={{fontSize: '0.75rem'}}>Nome prodotto</Typography>
                            </Box>
                            <Box
                                sx={{
                                    width: '100%',
                                    gridArea: 'price'
                                }}
                            >
                                <Typography color="textSecondary" sx={{fontSize: '0.75rem'}}>Costo</Typography>
                            </Box>
                            <Box
                                sx={{
                                    width: '100%',
                                    gridArea: 'unit'
                                }}
                            >
                                <Typography color="textSecondary" sx={{fontSize: '0.75rem'}}>Des. unità</Typography>
                            </Box>
                            <Box
                                sx={{
                                    width: '100%',
                                    gridArea: 'input',
                                }}
                            >
                                <Typography color="textSecondary" sx={{fontSize: '0.75rem'}}>Quantità</Typography>
                            </Box>
                            <Box
                                sx={{
                                    width: '100%',
                                    gridArea: 'image',
                                    display: 'flex',
                                    justifyContent: 'flex-end'
                                }}
                            >
                                <Typography color="textSecondary" sx={{fontSize: '0.75rem'}}></Typography>
                            </Box>
                            <Box
                                sx={{
                                    width: '100%',
                                    gridArea: 'add'
                                }}
                            />
                        </Box>
                        <Box
                            sx={{
                                "& > div:nth-child(even)": {
                                    background: '#ffffff',
                                }
                            }}
                        >
                            {products?.length > 0 ?
                                products.map((product) =>
                                    <TableViewProduct key={order.id + 'table'} product={product}
                                                      orderInitial={order || undefined}
                                                      changeOrderInitial={changeOrderInitial}/>
                                )
                                :
                                ""
                            }
                        </Box>
                    </Box>
                }

            </InfiniteScroll>

            <BasicDialog isOpen={errorAlert} handleClose={() => handleClose(setErrorAlert)}
                         title={warningText.titleError}
                         body={warningText.somethingWentWrong} closeLabel={warningText.okButton}
                         successLabel={undefined}/>

            <Dialog
                fullWidth
                maxWidth={'md'}
                open={openModal}
                onClose={handleCloseModal}
            >
                <DialogTitle>Carrello</DialogTitle>
                <DialogContent>

                    <Box>
                        <Box
                            sx={{
                                display: {
                                    xs: 'none',
                                    md: 'grid'
                                },
                                width: '100%',
                                py: {xs: 0, md: 0.5},
                                px: 0,
                                gridTemplateColumns: {
                                    xs: '1fr 1fr 1fr 1fr 1fr',
                                    md: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr'
                                },
                                gridTemplateRows: {
                                    xs: 'auto',
                                    md: 'auto'
                                },
                                gridTemplateAreas: {
                                    xs: `
                            'code code code availability availability'
                            'title title title title title'
                            'price price input input add'
                            `,
                                    md: `
                            'code title title title title title availability price price unit unit input add' 
                            `,
                                },
                                borderBottom: '1px solid rgba(0, 0, 0, 0.46)',
                                fontSize: '0.75rem',
                            }}
                        >
                            <Box
                                sx={{
                                    width: '100%',
                                    gridArea: 'code',
                                }}
                            >
                                <Typography color="textSecondary" sx={{fontSize: '0.75rem'}}>Cod.</Typography>
                            </Box>

                            <Box
                                sx={{
                                    width: '100%',
                                    gridArea: 'availability',
                                }}
                            >
                                <Typography color="textSecondary" sx={{fontSize: '0.75rem'}}>Disp.</Typography>
                            </Box>

                            <Box
                                sx={{
                                    width: '100%',
                                    gridArea: 'title',
                                }}
                            >
                                <Typography color="textSecondary" sx={{fontSize: '0.75rem'}}>Nome prodotto</Typography>
                            </Box>
                            <Box
                                sx={{
                                    width: '100%',
                                    gridArea: 'price'
                                }}
                            >
                                <Typography color="textSecondary" sx={{fontSize: '0.75rem'}}>Costo</Typography>
                            </Box>
                            <Box
                                sx={{
                                    width: '100%',
                                    gridArea: 'unit'
                                }}
                            >
                                <Typography color="textSecondary" sx={{fontSize: '0.75rem'}}>Des. unità</Typography>
                            </Box>
                            <Box
                                sx={{
                                    width: '100%',
                                    gridArea: 'input',
                                }}
                            >
                                <Typography color="textSecondary" sx={{fontSize: '0.75rem'}}>Quantità</Typography>
                            </Box>
                            <Box
                                sx={{
                                    width: '100%',
                                    gridArea: 'add'
                                }}
                            />
                        </Box>
                        <Box component={'div'}
                             sx={{
                                 maxHeight: '160px',
                                 overflowY: 'scroll'
                             }}
                        >
                            {order?.items?.length > 0 ?
                                order.items.map((item) => {
                                    return (<><Card
                                            variant="outlined"
                                            sx={{
                                                borderRadius: 0,
                                                border: 'none',
                                                borderBottom: '1px solid rgba(0, 0, 0, 0.46)',
                                                mb: {xs: 1.5, md: 0}
                                            }}
                                        >
                                            <CardContent
                                                sx={{
                                                    py: {xs: 0, md: 0.5},
                                                    px: 0,
                                                    pb: '4px!important'
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'grid',
                                                        width: '100%',
                                                        columnGap: 0.5,
                                                        rowGap: 0.125,
                                                        gridTemplateColumns: {
                                                            xs: '1fr 1fr 1fr 1fr 1fr',
                                                            md: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr'
                                                        },
                                                        gridTemplateRows: {
                                                            xs: 'auto',
                                                            md: 'auto'
                                                        },
                                                        gridTemplateAreas: {
                                                            xs: `
                            'code code code availability availability'
                            'title title title title title'
                            'price price input input add'
                            `,
                                                            md: `
                            'code title title title title title availability price price unit unit input add' 
                            `,
                                                        }
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            width: '100%',
                                                            gridArea: 'code',
                                                            display: 'flex',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        <Typography color="textSecondary" sx={{fontSize: '0.75rem'}}>
                                                            <Typography
                                                                component={'span'}
                                                                sx={{
                                                                    display: {md: 'none'},
                                                                    fontSize: '0.75rem'
                                                                }}>Cod.</Typography> {item.product?.code}</Typography>
                                                    </Box>

                                                    <Box
                                                        sx={{
                                                            width: '100%',
                                                            gridArea: 'availability',
                                                            display: 'flex',
                                                            justifyContent: {
                                                                xs: 'flex-end',
                                                                md: 'flex-start'
                                                            },
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        {getDescriptionRoundCircle(item)}
                                                    </Box>

                                                    <Box
                                                        sx={{
                                                            width: '100%',
                                                            gridArea: 'title',
                                                            display: 'flex',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        <Typography sx={{fontSize: {md: '0.875rem'}, py: 0.75}}>
                                                            {item.product?.name}
                                                        </Typography>
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            width: '100%',
                                                            gridArea: 'price',
                                                            display: 'flex',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{fontSize: '0.875rem'}}>{item.product?.priceFormatted} CHF</Typography>
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            width: '100%',
                                                            gridArea: 'input',
                                                            display: 'flex',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        <TextField
                                                            type="number"
                                                            id="quantity"
                                                            variant={'outlined'}
                                                            size={'xsmall'}
                                                            onChange={(event) => handleChange(item.id, event.target.value)}
                                                            sx={{
                                                                width: '100%',
                                                            }}
                                                            InputProps={{
                                                                inputProps: {min: 1}
                                                            }}
                                                            value={item.quantity}
                                                        >
                                                        </TextField>
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            width: '100%',
                                                            gridArea: 'unit',
                                                            display: {
                                                                xs: 'none',
                                                                md: 'inline-flex'
                                                            },
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{fontSize: '0.875rem'}}>{item.product?.unitDescription}</Typography>
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            width: '100%',
                                                            gridArea: 'add',
                                                            display: 'flex',
                                                            justifyContent: 'flex-end',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        <Tooltip title="Rimuovi dal carrello" placement="top">
                                                            <IconButton color="primary"
                                                                        onClick={() => deleteItem(item.id)}>
                                                                <DeleteIcon sx={{fontSize: '1.25rem'}}/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Box>
                                                </Box>
                                            </CardContent>
                                        </Card></>
                                    )
                                })
                                :
                                <></>}
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal}>Ok</Button>
                </DialogActions>
            </Dialog>

            <BasicDialog isOpen={confirmBack} handleClose={() => {
                handleClose(setConfirmBack);
                navigate("/")
            }}
                         title={warningText.titleAttention}
                         body={warningText.confirmBack} closeLabel={warningText.exitButton}
                         successLabel={warningText.deleteButton} deleteFunction={deleteOrder}/>

            <BasicDialog isOpen={openDialog} handleClose={() => handleClose(setOpenDialog)} deleteFunction={deleteOrder}
                         title={warningText.titleAttention}
                         body={warningText.confirmDelete} closeLabel={warningText.noButton}
                         successLabel={warningText.deleteButton}/>

            <SnackBarAlert isOpen={openSnackBar} handleClose={() => handleClose(setOpenSnackBar)} type={'error'}
                           title={warningText.titleError}
                           duration={10000}
                           body={warningText.somethingWentWrong}/>
        </>
    );
}