import React, {useEffect, useState, useRef} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {
    Box,
    Button,
    ButtonGroup,
    ClickAwayListener,
    Grid,
    Grow, MenuItem,
    MenuList,
    Paper,
    Popper,
    Stack,
    Typography,
} from "@mui/material";
import ApiService from "../services/ApiService";
import BasicDialog from "../components/BasicDialog";
import SnackBarAlert from "../components/SnackBarAlert";
import {label, routes, warningText} from "../utils/ReusableText";
import {handleClose} from "../utils/ReusableFunction";
import MoreVertIcon from "@mui/icons-material/MoreVert";

export default function CouponDetails() {
    const navigate = useNavigate();
    const path = useLocation();
    const id = path.pathname.split('/')[2];
    const [coupon, setCoupon] = useState(undefined);
    const [openDialog, setOpenDialog] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [errorAlert, setErrorAlert] = useState(false);
    const [openButtonsGroup, setOpenButtonsGroup] = useState(false);
    const anchorRef = useRef(null);

    useEffect(() => {
        if (id) {
            ApiService.getCouponById(id).then(response => {
                setCoupon(response);
            })
        }
    }, [id]);

    const confirmOpen = () => {
        setOpenDialog(true);
    };

    function deleteCoupon() {
        ApiService.deleteCouponById(id).then(response => {
            navigate('/' + routes.coupons);
        }).catch((e) => {
            if (e.body?.code === 2) {
                setOpenDialog(false);
                setOpenSnackBar(true);
            } else {
                setErrorAlert(true);
            }
        });
    }

    function cancelCoupon() {
        const body = {
            id: coupon.id,
            idComment: coupon.idComment,
            description: coupon.description,
            supplier: coupon.supplier,
            amount: coupon.amount,
            invoiceNumber: coupon.invoiceNumber,
            budgetId: coupon.budget.id,
            ownerId: coupon.owner?.id,
            enabled: !coupon.enabled
        }

        ApiService.updateCoupon(body).then(response => {
            setCoupon(response);
            navigate('/' + routes.couponDetails + '/' + id);
        }).catch((e) => {
            setErrorAlert(true);
        });
    }

    //dropdown button
    const handleToggle = () => {
        setOpenButtonsGroup((prevOpen) => !prevOpen);
    };
    const handleCloseButtons = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpenButtonsGroup(false);
    };

    return (<>

        <Grid container sx={{mb: 4}}>
            <Grid item xs={12}>
                <Button variant="flip" onClick={() => {
                    navigate('/' + routes.coupons)
                }}>
                    Indietro
                </Button>
            </Grid>
        </Grid>

        <Grid container sx={{marginTop: 2}} justifyContent={'space-between'}>
            <Grid item xs={12} md={6}>
                <Typography variant="h5" component="h2" sx={{fontWeight: '500'}}>Dettaglio Buono</Typography>
            </Grid>

            <Grid item xs={12} md={6} justifyContent={'flex-end'}>
                <Box component={'div'} sx={{'display': 'flex', 'justifyContent':'flex-end'}}>
                    <ButtonGroup variant="outlined" ref={anchorRef} aria-label="split button" sx={{mt:{xs:2, md:0}}}>

                        <Button variant="contained" color="primary" sx={{px: {xs:2, md:5}}}
                                onClick={() => navigate(('/' + routes.editCoupon + '/' + id))}
                                disabled={!coupon?.enabled}
                                fullWidth>
                            Modifica
                        </Button>

                        <Button
                            size="small"
                            aria-controls={openButtonsGroup ? 'split-button-menu' : undefined}
                            aria-expanded={openButtonsGroup ? 'true' : undefined}
                            aria-label="select merge strategy"
                            aria-haspopup="menu"
                            onClick={handleToggle}
                            variant="contained"
                        >
                            <MoreVertIcon />
                        </Button>
                    </ButtonGroup>
                    <Popper
                        sx={{
                            zIndex: 1,
                        }}
                        open={openButtonsGroup}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                        placement={"bottom-end"}
                    >
                        {({ TransitionProps }) => (
                        <Grow
                            {...TransitionProps}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleCloseButtons}>
                                    <MenuList id="split-button-menu" autoFocusItem sx={{'minWidth': '100px'}}>
                                        <MenuItem onClick={() => cancelCoupon()} sx={{'fontSize': '0.875rem', 'minHeight': '38px'}}>
                                            {coupon?.enabled ? label.archive : label.restore}
                                        </MenuItem>
                                        <MenuItem onClick={() => confirmOpen()} sx={{'fontSize': '0.875rem', 'minHeight': '38px'}}
                                                disabled={!coupon?.enabled}>
                                            Elimina
                                        </MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                        )}
                    </Popper>
                </Box>
            </Grid>
        </Grid>

        <Grid container sx={{mt: 3}} columnSpacing={3}>
            <Grid item xs={12} md={6} lg={4}>
                <Paper
                    component={'div'}
                    elevation={0}
                    sx={{
                        borderRadius: 0,
                        borderTop: '2px solid #c23052',
                        p: 2,
                        mb: 4
                    }}
                >
                    <Stack spacing={1.25}>
                        <Typography><Typography component={'span'}
                                                sx={{color: 'rgba(0,0,0,.7)'}}>ID
                            Buono: </Typography>{coupon?.idComment}
                        </Typography>
                        <Typography><Typography component={'span'}
                                                sx={{color: 'rgba(0,0,0,.7)'}}>Descrizione: </Typography>{coupon?.description}
                        </Typography>
                        <Typography>
                            <Typography component={'span'}
                                        sx={{color: 'rgba(0,0,0,.7)'}}>Fornitore: </Typography>{coupon?.supplier}
                        </Typography>
                        <Typography>
                            <Typography component={'span'}
                                        sx={{color: 'rgba(0,0,0,.7)'}}>Utente: </Typography>{coupon?.owner?.fullName}
                        </Typography>
                        <Typography>
                            <Typography component={'span'}
                                        sx={{color: 'rgba(0,0,0,.7)'}}>Funzione: </Typography>{coupon?.budget?.name}
                        </Typography>
                        <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Valore in
                            CHF: </Typography>{coupon?.amountFormatted}</Typography>

                        {coupon?.invoiceNumber ?
                            <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Nr. fattura
                                SAP: </Typography>{coupon?.invoiceNumber}</Typography>
                            :
                            ''
                        }
                    </Stack>
                </Paper>
            </Grid>
        </Grid>

        <BasicDialog isOpen={openDialog} handleClose={() => handleClose(setOpenDialog)} deleteFunction={deleteCoupon}
                     title={warningText.titleAttention}
                     body={warningText.confirmDelete} closeLabel={warningText.okButton}
                     successLabel={warningText.deleteButton}/>

        <SnackBarAlert isOpen={openSnackBar} handleClose={() => handleClose(setOpenSnackBar)} type={'warning'}
                       title={warningText.titleAttention}
                       duration={10000}
                       body={warningText.errorDelete}/>

        <BasicDialog isOpen={errorAlert} handleClose={() => handleClose(setErrorAlert)} title={warningText.titleError}
                     body={warningText.somethingWentWrong} closeLabel={warningText.okButton} successLabel={undefined}/>
    </>);
}