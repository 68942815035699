import {Button, Grid, MenuItem, Paper, TextField} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import AgGrid from "../components/AgGrid";
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import ApiService from "../services/ApiService";
import {getUser} from "../services/AuthService";
import {originalStatus, routes, storage} from "../utils/ReusableText";
import {dataCoupons, dataFunctionsUser} from "../utils/agGridDefs";
import {AgGridReact} from "ag-grid-react";
import {agGridItalianTranslation} from "../configuration/agGridItalianTranslation";

export default function HomeSecretary() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const user = getUser();
    const [columnDefs] = useState([
        {
            headerName: "ID Ordine",
            field: "id",
            minWidth: 180,
            sortable: true,
            filter: 'agNumberColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        }, {
            headerName: "Utente",
            field: "user",
            minWidth: 200,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        }, {
            headerName: "Creato da",
            field: "createdBy",
            minWidth: 180,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        }, {
            headerName: "Funzione",
            field: "budgetName",
            minWidth: 180,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        }, {
            headerName: "Periodo",
            field: "schoolYearName",
            minWidth: 120,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        }, {
            headerName: "Totale (CHF)",
            field: "totalFormatted",
            minWidth: 100,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        }, {
            headerName: "Sede fornitura",
            field: "location",
            minWidth: 150,
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        }, {
            headerName: "Data creazione",
            field: "createdAtFormatted",
            sortable: true,
            minWidth: 160,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "Data di modifica",
            field: "updatededAtFormatted",
            sortable: true,
            minWidth: 160,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true}
        },
        {
            headerName: "", suppressMenu: true, pinned: 'right', lockPinned: true, cellRenderer: function (params) {
                return (<Grid container spacing={1} justifyContent={'flex-end'}>
                    <Grid item>
                        <Link className={'muiBtn muiBtnPrimary'} to={'/' + routes.orderUserDetails + '/' + params.data.id}>
                            Dettaglio
                        </Link>
                    </Grid>
                    {params.data.status !== originalStatus.inProgress ? '' :
                        <Grid item>
                            <Link
                                className={'muiBtn muiBtnSecondary'}
                                to={'/' + routes.orderInfo + '/' + params.data.id}
                            >
                                Modifica
                            </Link>
                        </Grid>}
                </Grid>)
            }, suppressSizeToFit: true, minWidth: 200
        },
    ],);
    const [defaultColDef] = useState({
        resizable: true,
        flex: 1,
    });
    const [rowData, setRowData] = useState([]);
    const [catalogs, setCatalogs] = useState(undefined);
    const [periods, setPeriods] = useState(undefined);
    const [selectedCatalog, setSelectedCatalog] = useState('');
    const [selectedPeriod, setSelectedPeriod] = useState('all');
    const [clickedButton, setClickedButton] = useState(undefined);

    const onClickStatus = useCallback((status, catalog, period) => {
        sessionStorage.setItem(storage.selectedStatusTab, status);

        fetchOrders(status, catalog === '' ? undefined : catalog, period === '' ? undefined : period).then(response => {
            setRowData(response);
        });
    }, []);

    const fetchOrders = async (status, catalog, period) => {
        setClickedButton(status);

        return await ApiService.getOrdersByStatus(status, catalog === '' ? undefined : catalog, period === 'all' ? undefined : period).then(response => {
            setLoading(false);
            if (response.length > 0) {
                return response;
            } else {
                return [];
            }

        });
    }

    useEffect(() => {
        setLoading(true);

        const statusStorage = sessionStorage.getItem(storage.selectedStatusTab)
        setClickedButton(statusStorage ? statusStorage : originalStatus.submitted)
        const catalogStorage = sessionStorage.getItem(storage.selectedCatalog);
        const periodStorage = sessionStorage.getItem(storage.selectedPeriod);

        ApiService.getCatalogsAll().then(response => {
            if (response.length > 0) {
                setCatalogs(response);

                setSelectedCatalog(catalogStorage ? catalogStorage : response[0].id);
            }

            ApiService.getPeriods().then(r => {
                if (r.length > 0) {
                    setSelectedPeriod(periodStorage ? periodStorage : selectedPeriod);
                    const periodAll = {
                        id: 'all',
                        name: 'Tutti'
                    }
                    r.unshift(periodAll);
                    setPeriods(r);

                }

                ApiService.getOrdersByStatus(statusStorage ? statusStorage : originalStatus.submitted, catalogStorage ? catalogStorage : response[0].id, selectedPeriod === 'all' ? undefined : selectedPeriod).then(res => {
                    setLoading(false);

                    if (res.length > 0) {
                        setRowData(res);
                    }

                    ApiService.getCouponsAll().then(res => {
                        if (res.length > 0) {
                            dataCoupons.rowData = res;
                        }
                        ApiService.getBudgetsByUserId(user.id).then(r => {
                            setLoading(false);
                            if (r.budgets.length > 0) {
                                dataFunctionsUser.rowData = r.budgets;
                            }
                        });
                    });
                });
            })

        });
        // eslint-disable-next-line
    }, []);

    function fetchOrdersByFilters(itemSelected, periodSelected) {
        setSelectedCatalog(itemSelected);
        setSelectedPeriod(periodSelected);
        sessionStorage.setItem(storage.selectedCatalog, itemSelected);
        sessionStorage.setItem(storage.selectedPeriod, periodSelected);
        fetchOrders(clickedButton, itemSelected === '' ? undefined : itemSelected, periodSelected === 'all' ? undefined : periodSelected).then(response => {
            if (response) {
                setRowData(response);
            }
        });
    }

    const handleRowClick = (event) => {
        const dropdownButtonClicked = event.event.target.classList.contains('dropdown-button');
        if (!dropdownButtonClicked) {
            const rowId = event.data.id;
            window.location.href = `/` + routes.orderUserDetails + `/${rowId}`;
        }
    };

    return (<div>
        <Grid container sx={{marginTop: 2}} justifyContent={'space-between'}>
            <Grid item xs="auto">
                <Typography variant="h5" component="h2" sx={{fontWeight: '500'}}>Homepage</Typography>
            </Grid>
            <Grid item xs="auto">
                <Button variant="contained" color="primary"
                        onClick={() => {
                            navigate('/' + routes.orderInfo)
                        }}
                >
                    Crea ordine
                </Button>
            </Grid>
        </Grid>

        <Grid container sx={{mt: 3}}>
            <Grid item xs={8} md={8} lg={8}>
                <Box
                    sx={{
                        background: '#EFEFEF',
                        p: 1,
                        mb: 2
                    }}
                >
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                select
                                id="selectedType"
                                value={selectedCatalog || ''}
                                label={'Seleziona catalogo'}
                                onChange={(e) => fetchOrdersByFilters(e.target.value, selectedPeriod)}
                                fullWidth
                                variant="standard"
                            >
                                {
                                    catalogs?.map((catalog) => (
                                        <MenuItem key={catalog.id} value={catalog.id}>{catalog.name}</MenuItem>
                                    ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                select
                                id="selectedPeriod"
                                value={selectedPeriod || ''}
                                label={'Seleziona periodo'}
                                onChange={(e) => fetchOrdersByFilters(selectedCatalog, e.target.value)}
                                fullWidth
                                variant="standard"
                            >
                                {
                                    periods?.map((period) => (
                                        <MenuItem key={period.id} value={period.id}>{period.name}</MenuItem>
                                    ))}
                            </TextField>

                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
        <Box
            sx={{
                borderBottom: '1px solid rgba(0,0,0,0.12)',
                mt: 4,
                mb: 2
            }}
        >
            <Button className={clickedButton === originalStatus.submitted ? 'active' : ''} variant="text"
                    color={'secondary'}
                    size='large'
                    onClick={() => onClickStatus(originalStatus.submitted, selectedCatalog, selectedPeriod)}
                    sx={{
                        px: 2,
                        py: 1,
                        borderRadius: 0,
                        borderBottom: '2px solid transparent',
                        '&:hover': {
                            borderBottom: '2px solid #c23052',
                        },
                        '&.active': {
                            borderBottom: '2px solid #c23052',
                        },
                        '&:hover.active': {
                            background: 'none',
                        }
                    }}
            >
                Ordini da approvare
            </Button>
            <Button className={clickedButton === originalStatus.inProgress ? 'active' : ''} variant="text"
                    color={'secondary'}
                    size='large'
                    onClick={() => onClickStatus(originalStatus.inProgress, selectedCatalog, selectedPeriod)}
                    sx={{
                        px: 2,
                        py: 1,
                        borderRadius: 0,
                        borderBottom: '2px solid transparent',
                        '&:hover': {
                            borderBottom: '2px solid #c23052',
                        },
                        '&.active': {
                            borderBottom: '2px solid #c23052',
                        },
                        '&:hover.active': {
                            background: 'none',
                        }
                    }}
            >
                Ordini in stesura
            </Button>
            <Button className={clickedButton === originalStatus.approved ? 'active' : ''} variant="text"
                    color={'secondary'}
                    size='large'
                    onClick={() => onClickStatus(originalStatus.approved, selectedCatalog, selectedPeriod)}
                    sx={{
                        px: 2,
                        py: 1,
                        borderRadius: 0,
                        borderBottom: '2px solid transparent',
                        '&:hover': {
                            borderBottom: '2px solid #c23052',
                        },
                        '&.active': {
                            borderBottom: '2px solid #c23052',
                        },
                        '&:hover.active': {
                            background: 'none',
                        }
                    }}
            >
                Ordini da evadere
            </Button>
            <Button className={clickedButton === originalStatus.processed ? 'active' : ''} variant="text"
                    color={'secondary'}
                    size='large'
                    onClick={() => onClickStatus(originalStatus.processed, selectedCatalog, selectedPeriod)}
                    sx={{
                        px: 2,
                        py: 1,
                        borderRadius: 0,
                        borderBottom: '2px solid transparent',
                        '&:hover': {
                            borderBottom: '2px solid #c23052',
                        },
                        '&.active': {
                            borderBottom: '2px solid #c23052',
                        },
                        '&:hover.active': {
                            background: 'none',
                        }
                    }}
            >
                Ordini evasi
            </Button>
            <Button className={clickedButton === originalStatus.aborted ? 'active' : ''} variant="text"
                    color={'secondary'}
                    size='large'
                    onClick={() => onClickStatus(originalStatus.aborted, selectedCatalog, selectedPeriod)}
                    sx={{
                        px: 2,
                        py: 1,
                        borderRadius: 0,
                        borderBottom: '2px solid transparent',
                        '&:hover': {
                            borderBottom: '2px solid #c23052',
                        },
                        '&.active': {
                            borderBottom: '2px solid #c23052',
                        },
                        '&:hover.active': {
                            background: 'none',
                        }
                    }}
            >
                Ordini cancellati
            </Button>
        </Box>

        {!loading ? <>
            <div style={{width: '100%', height: '100%'}} className="ag-theme-material ag-custom">
                <div style={{width: '100%', height: '100%'}}>
                    <AgGridReact
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        rowData={rowData}
                        pagination={true}
                        paginationPageSize={25}
                        singleClickEdit={true}
                        domLayout={'autoHeight'}
                        localeText={agGridItalianTranslation}
                        onRowClicked={handleRowClick}
                    >
                    </AgGridReact>
                </div>
            </div>
        </> : ''}

        <Paper
            component={'div'}
            elevation={0}
            sx={{
                borderRadius: 0,
                borderTop: '2px solid #c23052',
                p: 2,
                my: 5
            }}
        >
            <Grid container columnSpacing={3} justifyContent={'space-between'}>
                <Grid item>
                    <Typography variant="h5" component="h3" sx={{fontWeight: '500'}}>Buoni
                        acquisto</Typography>
                </Grid>
                <Grid item>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <Button variant={'contained'} color={'primary'} onClick={() => {
                            navigate('/' + routes.editCoupon)
                        }}>
                            Nuovo buono
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <AgGrid children={loading ? [] : dataCoupons} redirect={"/" + routes.editCoupon}/>
                </Grid>
            </Grid>
        </Paper>

        <Grid container columnSpacing={3} justifyContent={'space-between'} sx={{my: 5}}>
            <Grid item>
                <Typography variant="h5" component="h3" sx={{fontWeight: '500'}}>Le mie funzioni</Typography>
            </Grid>
            <Grid item xs={12}>
                <AgGrid children={loading ? [] : dataFunctionsUser}/>
            </Grid>
        </Grid>
    </div>);
}