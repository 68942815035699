import React, {useState} from "react";
import {Box, Button, Card, CardContent, TextField, Typography} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ApiService from "../services/ApiService";
import {originalStatus, warningText} from "../utils/ReusableText";
import BasicDialog from "../components/BasicDialog";
import {handleClose} from "../utils/ReusableFunction";

export default function CardCartOrderReturn({
                                                item,
                                                updateListOfItemsToReturn,
                                                orderInitial,
                                                changeOrderInitial,
                                                alertDeleteItemFromOrder
                                            }) {
    const [quantityToReturn, setQuantityToReturn] = useState(item.quantity);
    const [order, setOrder] = useState(orderInitial);
    const [errorAlert, setErrorAlert] = useState(false);

    function updateReturnedItem(selectedValue) {
        let value = Number(selectedValue) ? selectedValue : 0;
        if (value > item.quantity) {
            value = item.quantity
        }
        setQuantityToReturn(value);

        const objectToReturn = {
            orderItemId: item.id, quantityToReturn: item.quantity === 0 ? item.quantity : item.quantity - value
        }
        updateListOfItemsToReturn(objectToReturn);
    }


    function deleteItem(itemId, itemQuantity) {
        const body = {
            orderId: order.id,
            orderItemId: itemId,
            quantityToDelete: itemQuantity
        }

        ApiService.partialReturnSubmittedSingleItemOrder(body).then(response => {
            setOrder(response);
            changeOrderInitial(response);
            alertDeleteItemFromOrder(true);
        }).catch((e) => setErrorAlert(true));
    }

    return (<>
        <Card
            variant="outlined"
            sx={{
                borderRadius: 0, border: 'none', borderBottom: '1px solid rgba(0, 0, 0, 0.46)',
            }}
        >
            <CardContent
                sx={{
                    p: 1.5, pb: '10px!important'
                }}
            >
                <Box
                    sx={{
                        display: 'grid', width: '100%', columnGap: 1, rowGap: 1, gridTemplateColumns: {
                            xs: '1fr 1fr 1fr 1fr 1fr', md: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr'
                        }, gridTemplateRows: {
                            xs: 'auto', md: 'auto'
                        }, gridTemplateAreas: {
                            xs: `
                            'code code code availability availability'
                            'title title title title title'
                            'price input delete'
                            `, md: `
                            'code title title title title availability price unit input delete' 
                            `,
                        }
                    }}
                >
                    <Box
                        sx={{
                            width: '100%', gridArea: 'code', display: 'flex', alignItems: 'center'
                        }}
                    >
                        <Typography color="textSecondary" sx={{fontSize: '0.875rem'}}> <Typography
                            component={'span'}
                            sx={{display: {md: 'none'}}}>Cod.</Typography> {item.product.code}</Typography>
                    </Box>

                    <Box
                        sx={{
                            width: '100%', gridArea: 'title', display: 'flex', alignItems: 'center'
                        }}
                    >
                        <Typography sx={{fontSize: {md: '0.875rem'}}}>
                            {item.product.name}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            width: '100%', gridArea: 'price', display: 'flex', alignItems: 'center'
                        }}
                    >
                        <Typography sx={{fontSize: '0.875rem'}}>{item.priceFormatted} CHF</Typography>
                    </Box>

                    <Box
                        sx={{
                            width: '100%', gridArea: 'unit', display: {
                                xs: 'none', md: 'inline-flex'
                            }, alignItems: 'center'
                        }}
                    >
                        <Typography sx={{fontSize: '0.875rem'}}>{item.product.unitDescription}</Typography>
                    </Box>

                    <Box
                        sx={{
                            width: '100%', gridArea: 'input',
                        }}
                    >
                        <TextField
                            type="number"
                            value={quantityToReturn}
                            id="return-quantity"
                            onChange={(event) => updateReturnedItem(event.target.value)}
                            variant={'standard'}
                            max={item.quantity}
                            sx={{
                                width: '60px'
                            }}
                            InputProps={{
                                inputProps: {min: 0, max: item.quantity}
                            }}
                        >
                        </TextField>
                    </Box>

                    {order?.status === originalStatus.submitted ?
                        <Box
                            sx={{
                                width: '100%',
                                gridArea: 'delete',
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center'
                            }}
                        >
                            <Button color={'error'}
                                    sx={{
                                        minWidth: 'initial', p: 0
                                    }}
                                    onClick={() => deleteItem(item.id, item.quantity)}
                            >
                                <DeleteOutlineIcon/>
                            </Button>
                        </Box>
                        :
                        <></>
                    }
                </Box>
            </CardContent>
        </Card>

        <BasicDialog isOpen={errorAlert} handleClose={() => handleClose(setErrorAlert)}
                     title={warningText.titleError}
                     body={warningText.somethingWentWrong} closeLabel={warningText.okButton}
                     successLabel={undefined}/>
    </>)
}