import React, {useEffect, useState} from 'react';
import {Button, Checkbox, FormControlLabel, Grid, Typography} from "@mui/material";
import AgGrid from "./AgGrid";
import {useNavigate} from "react-router-dom";
import ApiService from "../services/ApiService";
import {routes} from "../utils/ReusableText";
import {dataUsers} from "../utils/agGridDefs";

export default function Users() {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [enabled, setEnabled] = useState(false);

    useEffect(() => {
        setLoading(true);

        getUsers();
        // eslint-disable-next-line
    }, [enabled]);

    function getUsers() {
        ApiService.getUsers(enabled).then(response => {
            setLoading(false);

            if (response.length > 0) {
                dataUsers.rowData = response;
            }
        });
    }

    return (<>
        <Grid container sx={{marginTop: 2}} justifyContent={'space-between'}>
            <Grid item xs='auto'>
                <Typography variant="h5" component="h2" sx={{fontWeight: '500'}}>Utenti</Typography>
            </Grid>
            <Grid item xs='auto'>
                <Button variant="contained" color="primary"
                        fullWidth
                        onClick={() => {
                            navigate('/' + routes.editUser)
                        }}>
                    Crea utente
                </Button>
            </Grid>

            <Grid container>
                <Grid item xs="auto">
                    <FormControlLabel control={<Checkbox checked={enabled} onChange={() => {
                        setEnabled(!enabled);
                    }}/>} label="Archiviati"/>
                </Grid>
            </Grid>
        </Grid>

        <AgGrid children={loading ? [] : dataUsers} redirect={"/" + routes.userDetails}/>
    </>);
}