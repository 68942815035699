import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Grid,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import ApiService from "../services/ApiService";
import {styled} from "@mui/material/styles";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import BasicDialog from "../components/BasicDialog";
import SelectWithSearch from "../components/SelectWithSearch";
import {voidBudget, voidUser} from "../utils/ReusableObjects";
import {handleClose} from "../utils/ReusableFunction";
import {warningText} from "../utils/ReusableText";
import CircularProgress from "@mui/material/CircularProgress";

const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        color: 'rgba(0,0,0,.54)',
        fontSize: '10px',
        paddingBottom: '8px'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: '12px',
        fontWeight: '400',
        paddingTop: '5px',
        paddingBottom: '5px',
    },
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: 'rgba(0,0,0,.01)',
    },
}));

export default function SpendLimitReport() {
    const [budgetSelected, setBudgetSelected] = useState(voidBudget);
    const [userSelected, setUserSelected] = useState(voidUser);
    const [listOfUsers, setListOfUsers] = useState([]);
    const [listOfBudgets, setListOfBudgets] = useState([]);
    const [spendLimitData, setSpendLimitData] = useState(undefined);
    const [errorAlert, setErrorAlert] = useState(false);
    const [voidBudgets, setVoidBudgets] = useState(false);
    const [spinner, setSpinner] = useState(false);

    useEffect(() => {
        ApiService.getUsers().then(response => {
            setListOfUsers(response);
            ApiService.getBudgets().then(response => {
                setListOfBudgets(response);
            });
        });
    }, []);

    function fetchBudgetsEnabled(value) {
        if (voidBudgets) {
            ApiService.getBudgets().then(response => {
                setListOfBudgets(response);
                setBudgetSelected(voidBudget);
            });
        } else {
            if (value) {
                setBudgetSelected(value);
            } else {
                setBudgetSelected(voidBudget);
            }
        }
    }

    function setUser(value) {
        setVoidBudgets(false);
        setUserSelected(value);
        setBudgetSelected(voidBudget);

        ApiService.getBudgetsByUserId(value.id).then(response => {
            if (response.budgets.length > 0) {
                setListOfBudgets(response.budgets);
            } else {
                setVoidBudgets(true);
                setListOfBudgets([])
            }
        });
    }

    function getSpendLimitReport() {
        setSpinner(true);
        const filter = {
            budgetId: budgetSelected.id
        }

        ApiService.getSpendLimitReport(filter).then(response => {
            setSpinner(false);
            setSpendLimitData(response)
        }).catch((e) => setErrorAlert(true));
    }

    return (<>
            <Grid container sx={{mt: 2}} justifyContent={'space-between'}>
                <Grid item xs="auto">
                    <Typography variant="h5" component="h2" sx={{fontWeight: '500'}}>Limite di spesa</Typography>
                </Grid>
            </Grid>

            <Grid container sx={{mt: 3}}>
                <Grid item xs={12} md={12} lg={12}>
                    <Box
                        sx={{
                            background: '#EFEFEF',
                            p: 1,
                            mb: 2
                        }}
                        id={"print-none-grid"}
                    >
                        <Grid container spacing={1}>
                            <Grid item xs={5} sm={5}>
                                <SelectWithSearch options={listOfUsers} onChange={setUser} value={userSelected}
                                                  required={false} multiple={false} label={"Seleziona utente"}
                                                  id={"userSelect"} entity={'user'}/>
                            </Grid>
                            <Grid item xs={5} sm={5}>
                                <SelectWithSearch options={listOfBudgets} value={budgetSelected}
                                                  onChange={fetchBudgetsEnabled} required={true}
                                                  multiple={false} label={"Seleziona funzione"} id={"budgetSelect"}
                                                  entity={'budget'}/>

                            </Grid>
                            <Grid item xs={2} sm={2}>
                                <Button variant="contained" color="primary" fullWidth sx={{mt: 1}}
                                        disabled={!budgetSelected.id || spinner} onClick={() => getSpendLimitReport()}>
                                    {spinner ? <CircularProgress size={20} color="inherit"/> : "Genera report"}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>

                    {!spendLimitData ?
                        <></>
                        :
                        <>
                            <Grid container sx={{mt: 3}} columnSpacing={3}>
                                <Grid item xs={12} md={7} lg={6}>
                                    <Paper
                                        component={'div'}
                                        elevation={0}
                                        sx={{
                                            borderRadius: 0,
                                            borderTop: '2px solid #c23052',
                                            p: 2,
                                            mb: 2
                                        }}
                                    >
                                        <Grid container spacing={2} sx={{width: '100%'}}>
                                            <Grid item xs={6}>
                                                <Stack spacing={1.25}>
                                                    <Typography><Typography component={'span'}
                                                                            sx={{color: 'rgba(0,0,0,.7)'}}>Nome: </Typography> {spendLimitData?.orders?.length > 0 ? spendLimitData?.orders[0].user?.fullName : ''}
                                                    </Typography>
                                                    <Typography><Typography component={'span'}
                                                                            sx={{color: 'rgba(0,0,0,.7)'}}>Credito
                                                        iniziale:</Typography> {spendLimitData?.amountFormatted}
                                                    </Typography>
                                                    <Typography><Typography component={'span'}
                                                                            sx={{color: 'rgba(0,0,0,.7)'}}>Credito
                                                        consunto:</Typography> {spendLimitData?.amountFormatted}
                                                    </Typography>
                                                    <Typography><Typography component={'span'}
                                                                            sx={{color: 'rgba(0,0,0,.7)'}}>Saldo:</Typography> {spendLimitData?.availableAmountFormatted}
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Stack spacing={1.25}>
                                                    <Typography><Typography component={'span'}
                                                                            sx={{color: 'rgba(0,0,0,.7)'}}>Funzione:</Typography> {spendLimitData?.orders?.length > 0 ? spendLimitData?.orders[0].budget?.name : ''}
                                                    </Typography>
                                                    <Typography>
                                                        <Typography component={'span'}
                                                                    sx={{color: 'rgba(0,0,0,.7)'}}>Consunto da
                                                            economato:</Typography> {spendLimitData?.totalAmountFromOrders}
                                                    </Typography>
                                                    <Typography><Typography component={'span'}
                                                                            sx={{color: 'rgba(0,0,0,.7)'}}>Consunto
                                                        con buoni:</Typography> {spendLimitData?.totalAmountFromCoupons}
                                                    </Typography>
                                                    <Typography><Typography component={'span'}
                                                                            sx={{color: 'rgba(0,0,0,.7)'}}>Percentuale
                                                        buoni:</Typography> {spendLimitData?.couponPercentage}
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <Stack spacing={1.25}>
                                        <Button variant="flip" fullWidth color="primary"
                                                startIcon={<LocalPrintshopOutlinedIcon/>}
                                                onClick={() => window.print()}>
                                            Stampa
                                        </Button>
                                    </Stack>
                                </Grid>
                            </Grid>

                            <Grid container sx={{mt: 2}} justifyContent={'space-between'}>
                                <Grid item xs="auto">
                                    <Typography variant="h6" component="h2" sx={{fontWeight: '500'}}>Lista
                                        ordini</Typography>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12}>
                                    {spendLimitData.orders?.length > 0 ?

                                        <Table sx={{minWidth: 700}} aria-label="customized table" size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell width='40%'>Utente</StyledTableCell>
                                                    <StyledTableCell width='30%'>Data creazione</StyledTableCell>
                                                    <StyledTableCell width='30%' sx={{textAlign: 'right'}}>Totale
                                                        (CHF)</StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {spendLimitData.orders.map((order) => (
                                                    <StyledTableRow>
                                                        <StyledTableCell>{order.user.fullName}</StyledTableCell>
                                                        <StyledTableCell>{order.createdAtFormatted}</StyledTableCell>
                                                        <StyledTableCell
                                                            sx={{textAlign: 'right'}}>{order.totalFormatted}</StyledTableCell>
                                                    </StyledTableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                        :
                                        <p>Nessun risultato presente</p>
                                    }
                                </Grid>
                            </Grid>

                            <Grid container sx={{mt: 4}} justifyContent={'space-between'}>
                                <Grid item xs="auto">
                                    <Typography variant="h6" component="h2" sx={{fontWeight: '500'}}>Lista
                                        buoni</Typography>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12}>
                                    {spendLimitData.coupons && spendLimitData.coupons.length > 0 ?
                                        <Table sx={{minWidth: 700}} aria-label="customized table" size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell width='40%'>Utente</StyledTableCell>
                                                    <StyledTableCell width='30%'>Data creazione</StyledTableCell>
                                                    <StyledTableCell width='30%' sx={{textAlign: 'right'}}>Totale
                                                        (CHF)</StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {spendLimitData.coupons.map((coupon) => (
                                                    <StyledTableRow>
                                                        <StyledTableCell>{coupon.owner?.fullName}</StyledTableCell>
                                                        <StyledTableCell>{coupon.createdAtFormatted}</StyledTableCell>
                                                        <StyledTableCell
                                                            sx={{textAlign: 'right'}}>{coupon.amountFormatted}</StyledTableCell>
                                                    </StyledTableRow>
                                                ))}
                                            < /TableBody>
                                        </Table>
                                        :
                                        <p>Nessun risultato presente</p>
                                    }
                                </Grid>
                            </Grid>
                        </>}
                </Grid>
            </Grid>

            <BasicDialog isOpen={errorAlert} handleClose={() => handleClose(setErrorAlert)}
                         title={warningText.titleError}
                         body={warningText.somethingWentWrong} closeLabel={warningText.okButton}
                         successLabel={undefined}/>
        </>
    )
}