import {Box, Button, Grid, Stack, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import ApiService from "../services/ApiService";
import BasicDialog from "../components/BasicDialog";
import {routes, warningText} from "../utils/ReusableText";
import {handleClose} from "../utils/ReusableFunction";

export default function EditUser() {
    const navigate = useNavigate();
    const path = useLocation();
    const id = path.pathname.split('/')[2];

    const [firstName, setFirstName] = useState(undefined);
    const [lastName, setLastName] = useState(undefined);
    const [email, setEmail] = useState(undefined);
    const [disabled, setDisabled] = useState(undefined);

    const [isEdit, setIsEdit] = useState(false);

    const [errorAlert, setErrorAlert] = useState(false);
    const [alreadyClicked, setAlreadyClicked] = useState(false);

    useEffect(() => {
        if (id) {
            setIsEdit(true);

            ApiService.getUserById(id).then(response => {
                setFirstName(response.firstName)
                setLastName(response.lastName)
                setEmail(response.email)
                setDisabled(response.disabled);
            })
        } else {
            setIsEdit(false);
        }
    }, [id])

    function saveUser() {
        setAlreadyClicked(true);

        if (id) {
            const body = {
                id: id,
                firstName: firstName,
                lastName: lastName,
                email: email,
                disabled: disabled
            }

            ApiService.updateUser(body).then(response => {
                if (response) {
                    navigate('/' + routes.users)
                }
            }).catch((e) => {
                setAlreadyClicked(false);
                setErrorAlert(true);
            });
        } else {
            const body = {
                email: email,
                firstName: firstName,
                lastName: lastName
            }

            ApiService.createUser(body).then(response => {
                navigate('/' + routes.users)
            }).catch((e) => {
                setAlreadyClicked(false);
                setErrorAlert(true);
            });
        }
    }

    function checkMandatoryUserFieldsCreate() {
        return !(email);
    }

    return (<>

        <Grid container sx={{marginTop: 2}}>
            <Grid item xs={12}>
                <Typography variant="h5" component="h2"
                            sx={{fontWeight: '500'}}>{isEdit ? 'Modifica Utente' : 'Nuovo Utente'}</Typography>
            </Grid>
        </Grid>


        <Grid container sx={{mt: 4}} columnSpacing={2.5}>
            <Grid item xs={12} md={8} lg={4}>
                <Box component={'form'}>
                    <Stack spacing={1.5} sx={{mb: {xs: 3, md: 0}}}>

                        <TextField id="firstName" label="Nome" value={firstName} defaultValue={firstName}
                                   variant="standard"
                                   InputLabelProps={isEdit ? {shrink: true} : ''}
                                   onChange={e => setFirstName(e.target.value)} fullWidth/>
                        <TextField id="lastName" label="Cognome" value={lastName} defaultValue={lastName}
                                   variant="standard"
                                   InputLabelProps={isEdit ? {shrink: true} : ''}
                                   onChange={e => setLastName(e.target.value)} fullWidth/>

                        <TextField id="email" label="Email" value={email} defaultValue={email} type={"email"}
                                   variant="standard"
                                   InputLabelProps={isEdit ? {shrink: true} : ''}
                                   onChange={e => setEmail(e.target.value)} required fullWidth/>
                    </Stack>
                </Box>
                <Grid container sx={{mt: 3}} justifyContent={'space-between'}>
                    <Grid item>
                        <Button variant="flip" onClick={() => {
                            navigate('/' + routes.users)
                        }}>
                            Cancella
                        </Button>

                    </Grid>
                    <Grid item>
                        {isEdit ?
                            <Button variant="contained" color="primary"
                                    disabled={alreadyClicked}
                                    onClick={() => {
                                        saveUser()
                                    }}>Salva</Button>
                            :
                            <Button variant="contained" color="primary"
                                    disabled={checkMandatoryUserFieldsCreate() || alreadyClicked}
                                    onClick={() => {
                                        saveUser()
                                    }}>Crea</Button>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

        <BasicDialog isOpen={errorAlert} handleClose={() => handleClose(setErrorAlert)} title={warningText.titleError}
                     body={warningText.somethingWentWrong} closeLabel={warningText.okButton} successLabel={undefined}/>
    </>);
}