import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Paper} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import AgGrid from "../components/AgGrid";
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import ApiService from "../services/ApiService";
import {getUser} from "../services/AuthService";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import {routes} from "../utils/ReusableText";
import {handleClose} from "../utils/ReusableFunction";
import {dataCouponsUser, dataFunctionsUser, dataOrders} from "../utils/agGridDefs";

export default function HomeUser() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [noBudgetModal, setNoBudgetModal] = useState(false);
    const user = getUser();

    useEffect(() => {
        setLoading(true);

        ApiService.getBudgetsByUserId(user.id).then(response => {
            if (response.budgets.length > 0) {
                setNoBudgetModal(false)
            } else {
                setNoBudgetModal(true)
            }
        });

        ApiService.getOrdersByUserId(user.id).then(response => {
            if (response.length > 0) {
                dataOrders.rowData = response;
            }
            ApiService.getCouponsByUserId(user.id).then(res => {
                if (res.length > 0) {
                    dataCouponsUser.rowData = res;
                }

                ApiService.getBudgetsByUserId(user.id).then(r => {
                    setLoading(false);
                    if (r.budgets.length > 0) {
                        setNoBudgetModal(false)
                        dataFunctionsUser.rowData = r.budgets;
                    } else {
                        setNoBudgetModal(true)
                    }
                });
            });

        });
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Grid container sx={{mt: 2}}>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h5" component="h2"
                                        sx={{fontWeight: '500', mb: {xs: 2, sm: 0}}}>Homepage</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Paper
                component={'div'}
                elevation={0}
                sx={{
                    borderRadius: 0,
                    borderTop: '2px solid #c23052',
                    p: 2,
                    my: 3
                }}
            >
                <Grid container columnSpacing={3} justifyContent={'space-between'}>
                    <Grid item>
                        <Typography variant="h5" component="h3" sx={{fontWeight: '500'}}>I miei ordini</Typography>
                    </Grid>
                    <Grid item>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}
                        >
                            <Button variant={'contained'} color={'primary'} onClick={() => {
                                navigate('/' + routes.orderInfo)
                            }}>
                                Crea ordine
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <AgGrid children={loading ? [] : dataOrders} redirect={"/" + routes.orderUserDetails}/>
                    </Grid>
                </Grid>
            </Paper>

            <Paper
                component={'div'}
                elevation={0}
                sx={{
                    borderRadius: 0,
                    borderTop: '2px solid #c23052',
                    p: 2,
                    my: 5
                }}
            >
                <Grid container columnSpacing={3} justifyContent={'space-between'}>
                    <Grid item>
                        <Typography variant="h5" component="h3" sx={{fontWeight: '500'}}>I miei buoni
                            acquisto</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <AgGrid children={loading ? [] : dataCouponsUser}/>
                    </Grid>
                </Grid>
            </Paper>

            <Grid container columnSpacing={3} justifyContent={'space-between'} sx={{my: 5}}>
                <Grid item>
                    <Typography variant="h5" component="h3" sx={{fontWeight: '500'}}>Le mie funzioni</Typography>
                </Grid>
                <Grid item xs={12}>
                    <AgGrid children={loading ? [] : dataFunctionsUser}/>
                </Grid>
            </Grid>

            {noBudgetModal ?
                <Dialog
                    open={noBudgetModal}
                    onClose={() => handleClose(setNoBudgetModal)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth='lg'
                >
                    <DialogTitle id="alert-dialog-title">
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <ReportProblemOutlinedIcon sx={{mr: 1}}/> Attenzione
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Typography>Non sono presenti funzioni associate alla tua utenza.</Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => handleClose(setNoBudgetModal)} variant={'contained'} color={'primary'}>
                            Ok, ho capito
                        </Button>
                    </DialogActions>
                </Dialog>
                :
                ''}
        </>
    );
}