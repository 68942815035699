import React, {useEffect, useState, useRef} from "react";
import {
    Button,
    ButtonGroup,
    ClickAwayListener,
    Grid,
    Grow, MenuItem,
    MenuList,
    Paper,
    Popper,
    Stack,
    Typography
} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import ApiService from "../services/ApiService";
import BasicDialog from "../components/BasicDialog";
import SnackBarAlert from "../components/SnackBarAlert";
import {label, routes, warningText} from "../utils/ReusableText";
import {handleClose} from "../utils/ReusableFunction";
import Box from "@mui/material/Box";
import MoreVertIcon from "@mui/icons-material/MoreVert";

export default function LocationDetails() {
    const navigate = useNavigate();
    const path = useLocation();
    const id = path.pathname.split('/')[2];
    const [location, setLocation] = useState(undefined);
    const [openDialog, setOpenDialog] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [errorAlert, setErrorAlert] = useState(false);
    const [openButtonsGroup, setOpenButtonsGroup] = useState(false);
    const anchorRef = useRef(null);

    useEffect(() => {
        if (id) {
            ApiService.getLocationById(id).then(response => {
                setLocation(response);
            })
        }
    }, [id]);

    const confirmOpen = () => {
        setOpenDialog(true);
    };

    function deleteLocation() {
        ApiService.deleteLocationById(id).then(response => {
            navigate('/' + routes.locations);
        }).catch((e) => {
            if (e.body?.code === 2) {
                setOpenDialog(false);
                setOpenSnackBar(true);
            } else {
                setErrorAlert(true);
            }
        });
    }

    function archiveLocation() {
        const body = {
            id: location.id,
            name: location.name,
            street: location.street,
            zip: location.zip,
            city: location.city,
            enabled: !location.enabled
        }

        ApiService.updateLocation(body).then(response => {
            setLocation(response);
            navigate('/' + routes.locationDetails + '/' + id)
        }).catch((e) => {
            setErrorAlert(true);
        });
    }

    //dropdown button
    const handleToggle = () => {
        setOpenButtonsGroup((prevOpen) => !prevOpen);
    };
    const handleCloseButtons = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpenButtonsGroup(false);
    };

    return (<>
        <Grid container sx={{mb: 4}}>
            <Grid item xs={12}>
                <Button variant="flip"
                        onClick={() => {
                            navigate('/' + routes.locations)
                        }}>
                    Indietro
                </Button>
            </Grid>
        </Grid>

        <Grid container sx={{marginTop: 2}}>
            <Grid item xs={12} md={6}>
                <Typography variant="h5" component="h2" sx={{fontWeight: '500'}}>Dettaglio Sede</Typography>
            </Grid>

            <Grid item xs={12} md={6} justifyContent={'flex-end'}>
                <Box component={'div'} sx={{'display': 'flex', 'justifyContent':'flex-end'}}>
                    <ButtonGroup variant="outlined" ref={anchorRef} aria-label="split button" sx={{mt:{xs:2, md:0}}}>

                        <Button variant="flip" color="primary" onClick={() => navigate(('/edit-location/' + id))}
                                fullWidth disabled={!location?.enabled} sx={{px: {xs:2, md:5}}}>
                            Modifica
                        </Button>
                        <Button
                            size="small"
                            aria-controls={openButtonsGroup ? 'split-button-menu' : undefined}
                            aria-expanded={openButtonsGroup ? 'true' : undefined}
                            aria-label="select merge strategy"
                            aria-haspopup="menu"
                            onClick={handleToggle}
                            variant="flip"
                        >
                            <MoreVertIcon />
                        </Button>
                    </ButtonGroup>
                    <Popper
                        sx={{
                            zIndex: 1,
                        }}
                        open={openButtonsGroup}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                        placement={"bottom-end"}
                    >
                        {({ TransitionProps }) => (
                            <Grow
                                {...TransitionProps}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleCloseButtons}>
                                        <MenuList id="split-button-menu" autoFocusItem sx={{'minWidth': '100px'}}>
                                            <MenuItem onClick={() => archiveLocation()} sx={{'fontSize': '0.875rem', 'minHeight': '38px'}}>
                                                {location?.enabled ? label.archive : label.restore}
                                            </MenuItem>
                                            <MenuItem onClick={() => confirmOpen()} sx={{'fontSize': '0.875rem', 'minHeight': '38px'}}
                                                    disabled={!location?.enabled}>
                                                Elimina
                                            </MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </Box>
            </Grid>
        </Grid>

        <Grid container sx={{mt: 3}} columnSpacing={3}>
            <Grid item xs={12} md={6} lg={4}>
                <Paper
                    component={'div'}
                    elevation={0}
                    sx={{
                        borderRadius: 0,
                        borderTop: '2px solid #c23052',
                        p: 2,
                        mb: 4
                    }}
                >
                    <Stack spacing={1.25}>
                        <Typography><Typography component={'span'} sx={{color: 'rgba(0,0,0,.7)'}}>Nome
                            sede: </Typography>{location?.name}</Typography>
                        <Typography><Typography component={'span'}
                                                sx={{color: 'rgba(0,0,0,.7)'}}>Indirizzo: </Typography>{location?.street}
                        </Typography>
                        <Typography><Typography component={'span'}
                                                sx={{color: 'rgba(0,0,0,.7)'}}>CAP: </Typography>{location?.zip}
                        </Typography>
                        <Typography><Typography component={'span'}
                                                sx={{color: 'rgba(0,0,0,.7)'}}>Città: </Typography>{location?.city}
                        </Typography>
                    </Stack>
                </Paper>
            </Grid>
        </Grid>

        <BasicDialog isOpen={openDialog} handleClose={() => handleClose(setOpenDialog)} deleteFunction={deleteLocation}
                     title={warningText.titleAttention}
                     body={warningText.confirmDelete} closeLabel={warningText.noButton}
                     successLabel={warningText.deleteButton}/>

        <SnackBarAlert isOpen={openSnackBar} handleClose={() => handleClose(setOpenSnackBar)} type={'warning'}
                       title={warningText.titleAttention}
                       duration={10000}
                       body={warningText.errorDelete}/>

        <BasicDialog isOpen={errorAlert} handleClose={() => handleClose(setErrorAlert)} title={warningText.titleError}
                     body={warningText.somethingWentWrong} closeLabel={warningText.okButton} successLabel={undefined}/>

    </>);
}