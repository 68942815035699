import {AgGridReact} from "ag-grid-react";
import 'ag-grid-enterprise/styles/ag-grid.css';
import 'ag-grid-enterprise/styles/ag-theme-material.min.css';
import '../assets/css/agGrid/custom-table-agGrid.css';
import '../configuration/agGridItalianTranslation';
import {agGridItalianTranslation} from "../configuration/agGridItalianTranslation";
import {Grid} from "@mui/material";

export default function AgGrid({children, redirect}) {
    const onGridReady = params => {
        const allColumnIds = [];
        params.columnApi.getColumns().forEach((column) => {
            allColumnIds.push(column.getId());
        });
        params.columnApi.autoSizeColumns(allColumnIds, false);
    };

    const handleRowClick = (event) => {
        const clickedElement = event.event.target;

        if (clickedElement.tagName === "BUTTON") {
            return;
        }

        const clickedRow = event.api.getDisplayedRowAtIndex(event.rowIndex);
        if (clickedRow?.data) {
            if (redirect) {
                const rowId = clickedRow.data.id;
                window.location.href = `${redirect}/${rowId}`;
            }
        }
    };

    return (
        <Grid container sx={{my: 3}}>
            <Grid item xs={12}>
                <div style={{width: '100%', height: '100%'}} className="ag-theme-material ag-custom">
                    <div style={{width: '100%', height: '100%'}}>
                        <AgGridReact
                            onGridReady={onGridReady}
                            columnDefs={children.columnDefs}
                            defaultColDef={children.defaultColDef}
                            rowData={children.rowData}
                            pagination={true}
                            paginationPageSize={25}
                            singleClickEdit={true}
                            domLayout={'autoHeight'}
                            localeText={agGridItalianTranslation}
                            animateRows={true}
                            onRowClicked={handleRowClick}
                        >
                        </AgGridReact>
                    </div>
                </div>
            </Grid>
        </Grid>

    );
}

