import React, {useEffect, useState} from 'react';
import {Button, Checkbox, FormControlLabel, Grid, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import ApiService from "../services/ApiService";
import AgGrid from "./AgGrid";
import {routes} from "../utils/ReusableText";
import {dataProducts} from "../utils/agGridDefs";

export default function Products() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [enabled, setEnabled] = useState(false);

    useEffect(() => {
        setLoading(true);
        getProducts();
        // eslint-disable-next-line
    }, [enabled]);

    function getProducts() {
        ApiService.getProducts(enabled).then(response => {
            setLoading(false);

            dataProducts.rowData = response;
        });
    }

    return (<>

        <Grid container sx={{marginTop: 2}} justifyContent={'space-between'}>
            <Grid item xs='auto'>
                <Typography variant="h5" component="h2" sx={{fontWeight: '500'}}>Prodotti e forniture</Typography>
            </Grid>
            <Grid item xs='auto'>
                <Button variant="contained" color="primary"
                        fullWidth
                        onClick={() => {
                            navigate('/' + routes.editProduct)
                        }}>
                    Crea prodotto
                </Button>
            </Grid>
        </Grid>

        <Grid container>
            <Grid item xs="auto">
                <FormControlLabel control={<Checkbox checked={enabled} onChange={() => {
                    setEnabled(!enabled);
                }}/>} label="Archiviati"/>
            </Grid>
        </Grid>

        <AgGrid children={loading ? [] : dataProducts} redirect={"/" + routes.productDetails}/> {/**/}
    </>);
}