export const routes = {
    loginError: 'login-error',
    homeManager: 'home-manager',
    homeUser: 'home-user',
    homeSecretary: 'home-secretary',
    orderInfo: 'order-info',
    orderProducts: 'order-products',
    orderCart: 'order-cart',
    orderReturn: 'order-return',
    orderDetails: 'order-details',
    orderUserDetails: 'order-user-details',
    periods: 'periods',
    editPeriod: 'edit-period',
    periodDetails: 'period-details',
    locations: 'locations',
    editLocation: 'edit-location',
    locationDetails: 'location-details',
    functions: 'functions',
    editFunction: 'edit-function',
    functionDetails: 'function-details',
    catalogs: 'catalogs',
    editCatalog: 'edit-catalog',
    catalogDetails: 'catalog-details',
    categories: 'categories',
    editCategory: 'edit-category',
    categoryDetails: 'category-details',
    products: 'products',
    editProduct: 'edit-product',
    productDetails: 'product-details',
    editStock: 'edit-stock',
    users: 'users',
    editUser: 'edit-user',
    userDetails: 'user-details',
    logs: 'logs',
    coupons: 'coupons',
    editCoupon: 'edit-coupon',
    couponDetails: 'coupon-details',
    managerManual: 'manager-manuals',
    userManual: 'manuals',
    spendLimitReport: 'spend-limit-report',
    itemsOrderedByUserReport: 'items-ordered-by-user-report',
    itemsOrderedReport: 'items-ordered-report',
    stockProductsReport: 'stock-products-report',
    budgetStatusLocationReport: 'budget-status-location-report',
    couponsTotalSpendReport: 'coupons-total-spend-report',
    seasonalityReport: 'seasonality-report',
    createFunctionUser: 'create-function-user',
    createCouponUser: 'create-coupon-user'
}

export const roles = {
    manager: 'MANAGER',
    user: 'USER',
    secretary: 'SECRETARY'
}

export const storage = {
    navPath: 'navPath',
    user: 'user',
    userEvent: 'userLogin',
    selectedStatusTab: 'selectedStatusTab',
    selectedCatalog: 'selectedCatalog',
    selectedPeriod: 'selectedPeriod'
}

export const italianStatus = {
    inProgress: 'In stesura',
    submitted: 'Inoltrato',
    approved: 'Approvato',
    processed: 'Evaso',
    aborted: 'Cancellato'
}

export const originalStatus = {
    inProgress: 'IN_PROGRESS',
    submitted: 'SUBMITTED',
    approved: 'APPROVED',
    processed: 'PROCESSED',
    aborted: 'ABORTED'
}

export const stockStatus = {
    supply: 'SUPPLY',
    removal: 'REMOVAL'
}

export const italianEnabled = {
    true: 'Si',
    false: 'No'
}

export const warningText = {
    somethingWentWrong: 'Qualcosa è andato storto, riprova più tardi.',
    titleError: 'Errore!',
    titleAttention: 'Attenzione!',
    okButton: 'Ok',
    deleteButton: 'Sì, elimina',
    submitButton: 'Sì, inoltra',
    confirmDelete: 'Sicuro che vuoi eliminare l’elemento?',
    errorDelete: 'Per poter eliminare questo elemento bisogna rimuovere tutte le relazioni associate ad esso.',
    noButton: 'No',
    exitButton: 'Esci',
    titleSuccess: 'Fatto!',
    confirmBack: 'Sei sicuro di voler annullare? L\'azione eliminerà l\'ordine corrente',
    submitConfirm: 'Sei sicuro di voler inoltrare l\'ordine? Non sarà più possibile effettuare modifiche'
}

export const roundCircleStock = {
    orange: 'Disponibilità limitata',
    green: 'Disponibile',
    red: 'Non disponibile'
}

export const pdfType = {
    user: 'manuale_docente.pdf',
    guardian: 'manuale_custode.pdf',
    manager: 'manuale_manager.pdf'
}

export const label = {
    archive: 'Archivia',
    restore: 'Ripristina',
    cancel: 'Cancella',
    reactivate: 'Riattiva',
    stockSupply: 'Entrata Merci',
    stockFix: 'Correzione dello stock',
    noLimitBudget: 'Illimitato',
    chfWithSpace: ' CHF',
    noBudgetsForCurrentUser: 'Non sono presenti funzioni con periodi validi per l’utente selezionato.',
    noBudgets: 'Non sono presenti funzioni con periodi validi.'
}

