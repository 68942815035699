import {Box, Button, Grid, Stack, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import ApiService from "../services/ApiService";
import {NumericFormat} from 'react-number-format';
import BasicDialog from "../components/BasicDialog";
import {routes, warningText} from "../utils/ReusableText";
import {handleClose} from "../utils/ReusableFunction";

export default function EditPeriod() {
    const navigate = useNavigate();
    const path = useLocation();
    const id = path.pathname.split('/')[2];
    const [name, setName] = useState(undefined);
    const [dateStart, setDateStart] = useState(undefined);
    const [dateEnd, setDateEnd] = useState(undefined);
    const [budgetPrimarySchool, setBudgetPrimarySchool] = useState(0);
    const [budgetElementarySchoolFirstCycle, setBudgetElementarySchoolFirstCycle] = useState(0);
    const [budgetElementarySchoolSecondCycle, setBudgetElementarySchoolSecondCycle] = useState(0);
    const [enabled, setEnabled] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const [errorDateStart, setErrorDateStart] = useState(false);
    const [errorDateEnd, setErrorDateEnd] = useState(false);
    const [errorAlert, setErrorAlert] = useState(false);
    const [alreadyClicked, setAlreadyClicked] = useState(false);

    useEffect(() => {
        if (id) {
            setIsEdit(true);
            ApiService.getPeriodById(id).then(response => {
                setName(response.name);
                setDateStart(response.start);
                setDateEnd(response.end);
                setBudgetPrimarySchool(response.primarySchoolStudentBudget / 100);
                setBudgetElementarySchoolFirstCycle(response.elementarySchoolFirstCycleStudentBudget / 100);
                setBudgetElementarySchoolSecondCycle(response.elementarySchoolSecondCycleStudentBudget / 100);
                setEnabled(response.enabled);
            })
        } else {
            setIsEdit(false);
        }
    }, [id])

    function savePeriod() {
        setAlreadyClicked(true);

        if (id) {
            const body = {
                id: Number(id),
                name: name,
                start: dateStart,
                end: dateEnd,
                primarySchoolStudentBudget: (Number(budgetPrimarySchool) * 100),
                elementarySchoolFirstCycleStudentBudget: (Number(budgetElementarySchoolFirstCycle) * 100),
                elementarySchoolSecondCycleStudentBudget: (Number(budgetElementarySchoolSecondCycle) * 100),
                enabled: enabled
            }
            ApiService.updatePeriod(body).then(response => {
                navigate('/' + routes.periods)
            }).catch((e) => {
                setAlreadyClicked(false);
                setErrorAlert(true);
            });

        } else {
            const body = {
                name: name,
                start: dateStart,
                end: dateEnd,
                primarySchoolStudentBudget: (Number(budgetPrimarySchool) * 100),
                elementarySchoolFirstCycleStudentBudget: (Number(budgetElementarySchoolFirstCycle) * 100),
                elementarySchoolSecondCycleStudentBudget: (Number(budgetElementarySchoolSecondCycle) * 100)
            }
            ApiService.createPeriod(body).then(response => {
                navigate('/' + routes.periods)
            }).catch((e) => {
                setAlreadyClicked(false);
                setErrorAlert(true);
            });
        }
    }

    function checkMandatoryPeriodFields() {
        return !(name && (dateStart && !errorDateStart) && (dateEnd && !errorDateEnd));
    }

    function checkDateStart(value) {
        const endDate = new Date(dateEnd);
        const startDate = new Date(value);

        setDateStart(value);

        if (startDate >= endDate) {
            setErrorDateStart(true);
        } else {
            if (errorDateEnd) {
                setErrorDateEnd(false);
            } else {
                setErrorDateStart(false);
            }
        }

    }

    function checkDateEnd(value) {
        const startDate = new Date(dateStart);
        const endDate = new Date(value);

        setDateEnd(value);

        if (startDate > endDate) {
            setErrorDateEnd(true);
        } else {
            if (errorDateStart) {
                setErrorDateStart(false);
            } else {
                setErrorDateEnd(false);
            }
        }
    }

    return (<>
        <Grid container sx={{marginTop: 2}}>
            <Grid item xs={12}>
                <Typography variant="h5" component="h2"
                            sx={{fontWeight: '500'}}>{isEdit ? 'Modifica Periodo' : 'Nuova Periodo'}</Typography>
            </Grid>
        </Grid>
        <Grid container sx={{mt: 4}}>
            <Grid item xs={12} md={4}>
                <Box component={'form'}>
                    <Stack spacing={1.5}>
                        <TextField id="name" label="Nome periodo" value={name} defaultValue={name} variant="standard"
                                   onChange={e => setName(e.target.value)}
                                   InputLabelProps={isEdit ? {shrink: true} : ''} required fullWidth/>

                        {errorDateStart ?
                            <TextField type="date"
                                       format="dd.MM.yyyy"
                                       error
                                       required
                                       fullWidth
                                       id="start"
                                       label="Data d'inizio"
                                       defaultValue={dateStart}
                                       helperText="La data d'inizio non può essere superiore alla data fine"
                                       variant="standard"
                                       onChange={(e) => checkDateStart(e.target.value)}
                                       InputLabelProps={{shrink: true}}
                            />
                            :
                            <TextField id="start" label="Data d'inizio" InputLabelProps={{shrink: true}} type="date"
                                       value={dateStart} variant="standard"
                                       format="dd.MM.yyyy"
                                       onChange={(e) => checkDateStart(e.target.value)} required fullWidth/>}
                        {errorDateEnd ?
                            <TextField type="date"
                                       format="dd.MM.yyyy"
                                       error
                                       required
                                       fullWidth
                                       id="end"
                                       label="Data di fine"
                                       defaultValue={dateEnd}
                                       helperText="La data di fine non può essere superiore alla data d'inizio"
                                       variant="standard"
                                       onChange={(e) => checkDateEnd(e.target.value)}
                                       InputLabelProps={{shrink: true}}
                            />
                            :
                            <TextField id="end" label="Data di fine" InputLabelProps={{shrink: true}} type="date"
                                       value={dateEnd}
                                       variant="standard"
                                       inputFormat="dd.MM.yyyy"
                                       onChange={(e) => checkDateEnd(e.target.value)} required fullWidth/>}
                        <NumericFormat decimalSeparator="."
                                       fixedDecimalScale={true}
                                       allowNegative={false}
                                       decimalScale={2}
                                       customInput={TextField} id="budgetPrimarySchool"
                                       label="Budget pro capite SI (CHF)"
                                       defaultValue={budgetPrimarySchool}
                                       value={budgetPrimarySchool}
                                       variant="standard" required
                                       onChange={e => setBudgetPrimarySchool(e.target.value)}
                                       fullWidth/>
                        <NumericFormat decimalSeparator="."
                                       fixedDecimalScale={true}
                                       allowNegative={false}
                                       decimalScale={2}
                                       customInput={TextField} id="elementarySchoolFirstCycleStudentBudget"
                                       label="Budget pro capite SE 1° ciclo (CHF)"
                                       defaultValue={budgetElementarySchoolFirstCycle}
                                       value={budgetElementarySchoolFirstCycle}
                                       variant="standard" required
                                       onChange={e => setBudgetElementarySchoolFirstCycle(e.target.value)}
                                       fullWidth/>
                        <NumericFormat decimalSeparator="."
                                       fixedDecimalScale={true}
                                       allowNegative={false}
                                       decimalScale={2}
                                       customInput={TextField} id="budgetElementarySchoolSecondCycle"
                                       label="Budget pro capite SE 2° ciclo (CHF)"
                                       defaultValue={budgetElementarySchoolSecondCycle}
                                       value={budgetElementarySchoolSecondCycle}
                                       variant="standard" required
                                       onChange={e => setBudgetElementarySchoolSecondCycle(e.target.value)}
                                       fullWidth/>
                    </Stack>
                </Box>
                <Grid container sx={{mt: 3}} justifyContent={'space-between'}>
                    <Grid item>
                        <Button variant="flip" onClick={() => {
                            navigate('/' + routes.periods)
                        }}>
                            Cancella
                        </Button>

                    </Grid>
                    <Grid item>
                        {isEdit ?
                            <Button variant="contained" color="primary" onClick={() => {
                                savePeriod()
                            }} disabled={checkMandatoryPeriodFields() || alreadyClicked}>Salva</Button>
                            :
                            <Button variant="contained" color="primary"
                                    disabled={checkMandatoryPeriodFields() || alreadyClicked}
                                    onClick={() => {
                                        savePeriod()
                                    }}>Crea</Button>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

        <BasicDialog isOpen={errorAlert} handleClose={() => handleClose(setErrorAlert)} title={warningText.titleError}
                     body={warningText.somethingWentWrong} closeLabel={warningText.okButton} successLabel={undefined}/>
    </>);
}