import React, {useState} from "react";
import {Box, Button, Card, CardContent, TextField, Tooltip, Typography} from "@mui/material";
import CircleIcon from '@mui/icons-material/Circle';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ApiService from "../services/ApiService";
import BasicDialog from "../components/BasicDialog";
import {label, roundCircleStock, warningText} from "../utils/ReusableText";
import {handleClose} from "../utils/ReusableFunction";

export default function CardCart({item, orderInitial, changeOrderInitial, alertDeleteItemFromOrder}) {
    const [order, setOrder] = useState(orderInitial);
    const [quantity, setQuantity] = useState(item.quantity);
    const [errorAlert, setErrorAlert] = useState(false);

    function deleteItem(itemId) {
        ApiService.deleteOrderItemByIds(order.id, itemId).then(response => {
            setOrder(response);
            changeOrderInitial(response);
            alertDeleteItemFromOrder(true);
        }).catch((e) => setErrorAlert(true));
    }

    function handleChange(itemId, event) {
        const value = event ? Number(event) : undefined;

        if (!value || value === 0) {
            setQuantity(1);
        } else {
            setQuantity(value);
            const body = {
                id: itemId,
                quantity: value,
                orderId: order.id
            };
            ApiService.updateOrderItem(body).then(response => {
                setOrder(response);
                changeOrderInitial(response);
            }).catch((e) => setErrorAlert(true));
        }
    }

    function getDescriptionRoundCircle() {
        let circleColor = 'orange';
        let setTitle = roundCircleStock.orange;

        if (item.product.stock > item.product.minimumStockQuantity) {
            setTitle = roundCircleStock.green;
            circleColor = 'green';
        } else if ((item.product.stock <= 0 && item.product.minimumStockQuantity >= 0)) {
            setTitle = roundCircleStock.red;
            circleColor = 'red';
        } else {
        }

        return <Tooltip title={setTitle} placement="top">
            {circleColor === 'green' ?
                <CircleIcon sx={{
                    color: (theme) => theme.palette.success.main,
                    width: '16px',
                    height: '16px'
                }}/>
                :
                <CircleIcon sx={{
                    color: (theme) => circleColor === 'red' ? theme.palette.error.main : theme.palette.warning.main,
                    width: '16px',
                    height: '16px'
                }}/>}
        </Tooltip>;
    }

    function totalFormatted() {
        return (item.price * item.quantity / 100).toFixed(2) + label.chfWithSpace;
    }

    return (
        <>
            <Card key={item.id}
                  variant="outlined"
                  sx={{
                      borderRadius: 0,
                      border: 'none',
                      borderBottom: '1px solid rgba(0, 0, 0, 0.46)',
                  }}
            >
                <CardContent
                    sx={{
                        p: 1.5,
                        pb: '10px!important'
                    }}
                >
                    <Box
                        sx={{
                            display: 'grid',
                            width: '100%',
                            columnGap: 1,
                            rowGap: 1,
                            gridTemplateColumns: {
                                xs: '1fr 1fr 1fr 1fr 1fr',
                                md: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr'
                            },
                            gridTemplateRows: {
                                xs: 'auto',
                                md: 'auto'
                            },
                            gridTemplateAreas: {
                                xs: `
                            'code code code availability availability'
                            'title title title title title'
                            'price input total total delete'
                            `,
                                md: `
                            'code title title title title availability price unit input total total delete' 
                            `,
                            }
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                gridArea: 'code',
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <Typography color="textSecondary" sx={{fontSize: '0.875rem'}}> <Typography
                                component={'span'}
                                sx={{display: {md: 'none'}}}>Cod.</Typography> {item.product.code}</Typography>
                        </Box>

                        <Box
                            sx={{
                                width: '100%',
                                gridArea: 'availability',
                                display: 'flex',
                                justifyContent: {
                                    xs: 'flex-end',
                                    md: 'flex-start'
                                },
                                alignItems: 'center'
                            }}
                        >
                            {getDescriptionRoundCircle()}
                        </Box>

                        <Box
                            sx={{
                                width: '100%',
                                gridArea: 'title',
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <Typography sx={{fontSize: {md: '0.875rem'}}}>
                                {item.product.name}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                width: '100%',
                                gridArea: 'price',
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <Typography sx={{fontSize: '0.875rem'}}>{item.priceFormatted} CHF</Typography>
                        </Box>
                        <Box
                            sx={{
                                width: '100%',
                                gridArea: 'input',
                            }}
                        >
                            <TextField
                                type="number"
                                value={quantity}
                                id="quantity"
                                onChange={(event) => handleChange(item.id, event.target.value)}
                                variant={'standard'}
                                min="1"
                                sx={{
                                    width: '60px'
                                }}
                                InputProps={{
                                    inputProps: {min: 1}
                                }}
                            >
                            </TextField>
                        </Box>
                        <Box
                            sx={{
                                width: '100%',
                                gridArea: 'unit',
                                display: {
                                    xs: 'none',
                                    md: 'inline-flex'
                                },
                                alignItems: 'center'
                            }}
                        >
                            <Typography sx={{fontSize: '0.875rem'}}>{item.product.unitDescription}</Typography>
                        </Box>

                        <Box
                            sx={{
                                width: '100%',
                                gridArea: 'total',
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center'
                            }}
                        >
                            <Typography sx={{fontSize: '0.875rem'}}><Typography component={'span'}
                                                                                sx={{display: {md: 'none'}}}>Tot.:</Typography>{totalFormatted()}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                width: '100%',
                                gridArea: 'delete',
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center'
                            }}
                        >
                            <Button color={'error'}
                                    sx={{
                                        minWidth: 'initial',
                                        p: 0
                                    }}
                                    onClick={() => deleteItem(item.id)}
                            >
                                <DeleteOutlineIcon/>
                            </Button>
                        </Box>
                    </Box>
                </CardContent>
            </Card>

            <BasicDialog isOpen={errorAlert} handleClose={() => handleClose(setErrorAlert)}
                         title={warningText.titleError}
                         body={warningText.somethingWentWrong} closeLabel={warningText.okButton}
                         successLabel={undefined}/>
        </>
    )
}